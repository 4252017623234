import memberTypes from 'operations/memberTypes';

const PITCH_TYPES_SET = new Set<string>([
  memberTypes.PITCH,
  memberTypes.RESTRICTED_PITCH,
  memberTypes.ARCHIVED_PITCH,
  memberTypes.ARCHIVED_RESTRICTED_PITCH,
]);

export const isPitch = (mType: string) => PITCH_TYPES_SET.has(mType);

const STORY_TYPES_SET = new Set<string>([
  memberTypes.STORY,
  memberTypes.RESTRICTED_STORY,
  memberTypes.ARCHIVED_STORY,
  memberTypes.ARCHIVED_RESTRICTED_STORY,
]);

export const isStory = (mType: string) => STORY_TYPES_SET.has(mType);

export const isStoryOrPitch = (mType: string) => isStory(mType) || isPitch(mType);

const INSTANCE_TYPES_SET = new Set<string>([memberTypes.INSTANCE, memberTypes.ARCHIVED_INSTANCE]);

export const isInstance = (mType: string) => INSTANCE_TYPES_SET.has(mType);

export const isRundown = (mType: string) => mType === memberTypes.RUNDOWN;

export const isRundownTemplate = (mType: string) => mType === memberTypes.RUNDOWN_TEMPLATE;

export const isDraft = (mType: string) => mType === memberTypes.DRAFT;
