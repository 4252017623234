import { useMutation } from '@apollo/client';

import LOCK_MEMBER from 'operations/mutations/lockMember';
import { LockMemberInput, MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

type LockMemberReturnType = {
  lockMember: MemberType;
};

type LockMemberInputType = {
  input: LockMemberInput;
};

const useLockMember = () => {
  const logger = useLogger('use lock member');
  const [lockMemberMutation] = useMutation<LockMemberReturnType, LockMemberInputType>(LOCK_MEMBER);

  // eslint-disable-next-line consistent-return
  const lock = async (mId: string, userId: string) => {
    const input = {
      mId,
      userId,
    };

    try {
      const result = await lockMemberMutation({
        variables: { input },
      });

      return result;
    } catch (e) {
      logger.log(e);
    }
  };

  return [lock] as const;
};

export default useLockMember;
