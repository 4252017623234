import InstanceComponent from 'features/instance/Instance';
import { HStack } from 'layouts/box/Box';
import { Instance } from 'types';

import type { PreviewProps } from '../utils/childMap';

const WIDTH = '800px';

export default function InstanceDrawer({ member, onClose }: Readonly<PreviewProps>) {
  if (!member) {
    return <div>No preview found</div>;
  }

  return (
    <HStack alignItems="stretch" height="100%" width={WIDTH} maxWidth={WIDTH}>
      <InstanceComponent
        instance={member as Instance}
        autoUpdate
        isSearchItem
        onClose={onClose}
        onOpen={onClose}
      />
    </HStack>
  );
}
