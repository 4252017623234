import { SearchIcons } from 'components/command/command-constants';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { ParsedMemberType } from 'types/members';

export const findPlatformIcon = (member: ParsedMemberType) => {
  if (member.mType && member.mType !== MemberTypeEnum.Instance) {
    return SearchIcons[member.mType];
  }

  const { platform = '', platformKind = '' } = member.mProperties ?? {};
  const platformIcon = (platform as string) ?? (platformKind as string);

  return platformIcon ? PlatformIcons[platformIcon] : PlatformIcons.defaultIcon;
};
