import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useContextMenu } from 'react-contexify';

import { ReactComponent as Down } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as Right } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { ReactComponent as Ellipsis } from 'assets/icons/systemicons/more_vertical.svg';
import { IconButton } from 'components/buttons';
import Dialog, { DialogDataModel } from 'components/dialogs/DialogBuilder';
import HeaderInput from 'components/input/HeaderInput';
import Text from 'components/text/Text';
import ContextMenu from 'features/contextMenu';
import { noRundownCategoryId, useKanbanMolecule } from 'features/kanban/store/kanban';
import { KanbanLaneProps } from 'features/kanban/types/kanban';
import useCreateDraft from 'features/preview/components/draft/api/useCreateDraft';
import preventDefaultEvent from 'utils/preventDefaultEvent';

import { Arrow, ColorButton, ColorButtonWrapper, Header, LeftSideItems, Wrapper } from './styled';

interface AriaProps extends KanbanLaneProps {
  'aria-controls'?: string;
  'aria-expanded'?: boolean;
  'data-state'?: string;
  disabled?: boolean;
  type?: 'button';
  isHorizontal?: boolean;
}

type Props = AriaProps;
type Ref = HTMLButtonElement;

interface MenuItemType {
  label?: string;
  id?: string;
  separator?: boolean;
}

const rundownItems: MenuItemType[] = [
  { id: 'rename', label: 'Rename group' },
  { id: 'remove', label: 'Remove group (rundowns will move to Uncategorized)' },
];

const kanbanItems: MenuItemType[] = [
  { label: 'Create Draft' },
  { separator: true, id: 'separator' },
  { label: 'Rename' },
  { label: 'Remove' },
];

const LaneHeader = forwardRef<Ref, Props>(
  ({ count, label, mId, mRefId, color = '#ffffff', isHorizontal, ...rest }, ref) => {
    const [isEditing, setIsEditing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [colorPickerValue, setColorPickerValue] = useState(color);
    const [groupTitle, setGroupTitle] = useState(label);

    const { show } = useContextMenu({ id: mRefId });
    const { createDraft } = useCreateDraft();

    const {
      useAddMember,
      useDeleteKanbanLane,
      useUpdateKanbanLaneTitle,
      useUpdateKanbanLaneColor,
      useKanbanMembers,
    } = useKanbanMolecule();
    const addMember = useAddMember();
    const updateLaneTitle = useUpdateKanbanLaneTitle();
    const updateLaneColor = useUpdateKanbanLaneColor();
    const deleteLane = useDeleteKanbanLane();
    const [, setKanbanMembers] = useKanbanMembers();

    useEffect(() => {
      if (label !== groupTitle) setGroupTitle(label);
      if (color !== colorPickerValue) setColorPickerValue(color);
    }, [label, color]);

    const handleTitleUpdate = useCallback(
      (value: string) => {
        if (value !== label) updateLaneTitle({ mRefId: mRefId, mTitle: value });
        setGroupTitle(value);
        setIsEditing(false);
      },
      [label, mId, mRefId, updateLaneTitle],
    );

    const handleColorUpdate = useCallback(() => {
      if (color !== colorPickerValue) updateLaneColor({ mRefId: mRefId, color: colorPickerValue });
    }, [label, mId, mRefId, updateLaneColor, colorPickerValue, color]);

    const openCreateDialog = useCallback(() => {
      setModalOpen(true);
    }, [setModalOpen]);

    const closeCreateDialog = useCallback(() => {
      setModalOpen(false);
    }, [setModalOpen]);

    const createDraftItem = useCallback(
      async (data: DialogDataModel | undefined) => {
        if (!data?.draftTitle) return;

        const draftTitle = data.draftTitle as string;
        const draft = await createDraft({
          mTitle: draftTitle ?? '',
        });

        setKanbanMembers((prevState) => {
          const newMembers = prevState ?? {};
          newMembers[draft.mId as string] = draft;
          return newMembers;
        });
        addMember({ laneId: mRefId, memberId: draft.mId as string });
        closeCreateDialog();
      },
      [createDraft, closeCreateDialog, mId, mRefId],
    );

    const handleStopEditing = useCallback(() => {
      setIsEditing(false);
    }, [setIsEditing]);

    const handleDeleteLane = useCallback(() => {
      deleteLane({ mRefId: mRefId });
    }, [deleteLane, mRefId]);

    const handleMenuItemClicked = useCallback(
      ({ id }: { id: string }) => {
        switch (id?.toLowerCase()) {
          case 'create draft':
            openCreateDialog();
            break;
          case 'rename':
            setIsEditing(true);
            break;
          case 'remove':
            handleDeleteLane();
            break;
          default:
            break;
        }
      },
      [setIsEditing, handleDeleteLane, openCreateDialog],
    );

    const handleContextMenu = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        show({
          event,
          props: {
            key: 'value',
          },
        });
      },
      [show],
    );

    const handleShowEllipsisMenu = useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        preventDefaultEvent(event);
        handleContextMenu(event);
      },
      [handleContextMenu],
    );

    const handleDoubleClickTitle = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventDefaultEvent(event);
        setIsEditing(true);
      },
      [setIsEditing],
    );

    const showEllipsis = mRefId !== noRundownCategoryId;
    const dropdownItems = mId === 'rundowngroups' ? rundownItems : kanbanItems;

    return (
      <Wrapper>
        <Header tabIndex={0} {...rest} ref={ref}>
          <LeftSideItems onDoubleClick={handleDoubleClickTitle}>
            {!isHorizontal && <Arrow>{rest['aria-expanded'] ? <Down /> : <Right />}</Arrow>}
            <ColorButtonWrapper className="color-wrapper" color={color} role="button" tabIndex={0}>
              {showEllipsis && (
                <ColorButton
                  type="color"
                  onBlur={handleColorUpdate}
                  value={color}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setColorPickerValue(e.target.value)
                  }
                />
              )}
            </ColorButtonWrapper>
            <HeaderInput
              value={groupTitle}
              onUpdate={handleTitleUpdate}
              onBlur={handleStopEditing}
              isEditing={isEditing && showEllipsis}
            />
          </LeftSideItems>
          <Text variant="caption" color="mediumEmphasis">
            {count}
          </Text>
          {showEllipsis && (
            <IconButton
              onClick={handleShowEllipsisMenu}
              variant="discreet"
              usage="text"
              noBorder
              iconHeight={16}
              iconWidth={4}
              height={24}
              width={24}
              round
              title="More options"
            >
              <Ellipsis />
            </IconButton>
          )}
        </Header>
        <ContextMenu id={mRefId} menuItems={dropdownItems} onClick={handleMenuItemClicked} />

        <Dialog open={modalOpen} onClose={closeCreateDialog}>
          <Dialog.Header>Create new Draft</Dialog.Header>
          <Dialog.Body>
            <Dialog.InputField
              placeholder="Title..."
              initialValue="Title"
              propertyName="draftTitle"
            />
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.CancelButton />
            <Dialog.ConfirmButton label="Create" onConfirm={createDraftItem} />
          </Dialog.Footer>
        </Dialog>
      </Wrapper>
    );
  },
);

LaneHeader.displayName = 'LaneHeader';

export default LaneHeader;
