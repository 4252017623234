import { atom, useAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

export const EditorScope = createScope(undefined);

const editorMolecule = molecule((_, getScope) => {
  getScope(EditorScope);

  const showDeleteAllDialogAtom = atom<boolean>(false);

  return {
    useShowDeleteAllDialog: () => useAtom(showDeleteAllDialogAtom),
  };
});

export const useEditorMolecule = () => useMolecule(editorMolecule);
