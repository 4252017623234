import { useCallback, useRef } from 'react';
import { BaseRange, BaseSelection, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { v4 } from 'uuid';

import { useCreateBlock } from 'api/mdfBlocks/useCreateMdfBlock';
import { elementTypes } from 'components/editor/constants';
import useToast from 'components/toast/useToast';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { useOrderFormDialog } from 'store';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

const useInsertBlock = (search: string, resourceDetails: ResourceDetails | undefined) => {
  const editor = useSlate();
  const selectionRef = useRef<BaseSelection | null>(null);
  const [, setOrderForm] = useOrderFormDialog();
  const { createBlock } = useCreateBlock();
  const { errorToast } = useToast();

  const resetEditorSelection = useCallback(() => {
    if (selectionRef.current) {
      ReactEditor.focus(editor as ReactEditor);
      Transforms.select(editor, selectionRef.current);
      selectionRef.current = null;
    }
  }, [editor]);

  const selectWithSearchText = useCallback(
    (targetNode: BaseRange) => {
      const { anchor, focus } = targetNode;
      Transforms.select(editor, {
        anchor: { ...anchor, offset: focus.offset - search.length },
        focus,
      });
    },
    [editor, search],
  );

  const insertOrderBlock = useCallback(
    (command: EditorCommandConfigType, targetNode: BaseRange) => {
      selectionRef.current = editor.selection;
      ReactEditor.deselect(editor as ReactEditor);
      ReactEditor.blur(editor as ReactEditor);
      if (resourceDetails) {
        const { resourceId, resourceType, orderFormMap } = resourceDetails;
        setOrderForm({
          open: true,
          mFormId: command.mTertId,
          mdfId: command.mSecId,
          resourceId,
          resourceType,
          onConfirm: (order) => {
            resetEditorSelection();
            const orderBlock = {
              type: elementTypes.ORDER_BLOCK,
              data: {
                mId: order.mId,
                mResourceId: order.mResourceId,
                mFormId: order.mFormId,
                mStatus: order.mStatus,
                mCreatedAt: order.mCreatedAt,
                taskType: orderFormMap?.[command.mTertId]?.mDescription,
              },
              children: [{ text: '' }],
            };
            selectWithSearchText(targetNode);
            Transforms.insertNodes(editor, orderBlock);
          },
          onCancel: () => {
            resetEditorSelection();
          },
        });
      }
    },
    [resourceDetails, editor, resetEditorSelection, selectWithSearchText],
  );

  const insertMdfBlock = useCallback(
    (command: EditorCommandConfigType, targetNode: BaseRange) => {
      selectWithSearchText(targetNode);
      if (resourceDetails) {
        const { resourceId } = resourceDetails;
        createBlock({
          mId: resourceId,
          mRefId: v4(),
          mdfId: command.mSecId,
          mSecId: command.mRefId,
        })
          .then((block) => {
            const mdfBlock = {
              type: elementTypes.MDF_BLOCK,
              data: {
                mId: block?.mRefId,
                mResourceId: block?.mId,
                mFormId: command.mRefId,
                mdfId: block?.mdfId,
                mCreatedAt: block?.mCreatedAt,
              },
              children: [{ text: '' }],
            };
            Transforms.insertNodes(editor, mdfBlock);
          })
          .catch(errorToast);
      }
    },
    [selectWithSearchText, createBlock],
  );

  return { insertMdfBlock, insertOrderBlock };
};

export default useInsertBlock;
