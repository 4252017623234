import { MouseEvent, useCallback, useMemo } from 'react';
import { useContextMenu } from 'react-contexify';
import { useTheme } from '@emotion/react';

import { Block } from 'api/mdfBlocks/types';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import LWSelect from 'features/orderForm/components/LWSelect';
import OrderButton from 'features/orderForm/components/OrderButton';
import { Box } from 'layouts/box/Box';
import useUpdateOrder from 'screens/space/api/useUpdateOrder';
import { useAllMembers, useAllMembersKeyed, useDinaTheme } from 'store';
import { Order } from 'types/forms/forms';
import { Alternative } from 'types/graphqlTypes';

import EditMdfBlock from './EditMdfBlock';
import EditOrderBlock from './EditOrderBlock';
import { ToggleItem, ToggleItemProps } from './ToggleList';

import {
  ContentWrapper,
  LabelWrapper,
  OwnerIcon,
  StatusWrapper,
  StyledText,
  TaskIcon,
} from './styled';

export const TASK_MENU_ID = 'taskItemMenu';
export const PLANNING_MENU_ID = 'planningItemMenu';

export const scrollItemIntoView = (id: string) => {
  const rootElement = document.getElementById(`toggle-${id}`);
  if (rootElement) {
    rootElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

interface MemberAvatarProps {
  mOwner?: string;
  mAssignee?: string;
}

export function MemberAvatar({ mOwner, mAssignee }: Readonly<MemberAvatarProps>) {
  const [members] = useAllMembers();

  const owner = useMemo(() => members.find((m) => m.mId === mOwner), [members, mOwner]);
  const assignee = useMemo(() => members.find((m) => m.mId === mAssignee), [members, mAssignee]);

  return (
    <Box container gap="2px">
      {owner && (
        <Tooltip title={`Created by ${owner.mTitle}`}>
          <Box position="relative" overflow="unset">
            <Avatar
              className="avatar"
              variant={owner.mType as AvatarVariant}
              imageKey={owner.mAvatarKey}
              size={20}
              title={owner.mTitle}
            />
            <OwnerIcon className="skipOverride" />
          </Box>
        </Tooltip>
      )}
      {assignee && (
        <Tooltip title={`Assigned to ${assignee.mTitle}`}>
          <Box>
            <Avatar
              className="avatar"
              variant={assignee.mType as AvatarVariant}
              imageKey={assignee.mAvatarKey}
              size={20}
              title={assignee.mTitle}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

export type BlockWithLabel = Block & { commandLabel: string; color?: string };
interface PlanningItemProps extends Pick<ToggleItemProps, 'id' | 'open' | 'setOpen'> {
  block?: BlockWithLabel | null;
  selected: boolean;
  resourceId: string;
  onSelect: (event: MouseEvent<HTMLDivElement>) => void;
  showContextMenu?: boolean;
}

export function PlanningItem({
  block,
  resourceId,
  selected,
  onSelect,
  showContextMenu = true,
  ...rest
}: Readonly<PlanningItemProps>) {
  const [theme] = useDinaTheme();
  const { show } = useContextMenu({ id: PLANNING_MENU_ID });
  const [membersKeyed] = useAllMembersKeyed();

  const onContextMenu = useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      if (!showContextMenu) return;
      ev.preventDefault();
      show({
        event: ev,
        props: {
          block,
        },
      });
    },
    [show],
  );

  const updatedBy = useMemo(() => {
    if (block) {
      return membersKeyed[block.mUpdatedById];
    }
    return null;
  }, [block?.mUpdatedById, membersKeyed]);

  return (
    <ToggleItem
      onSelect={onSelect}
      selected={selected}
      color={block?.color}
      onContextMenu={onContextMenu}
      trigger={
        <LabelWrapper container width="100%" justifyContent="space-between">
          <Box container flexDirection="column" alignItems="start">
            {block ? (
              <>
                <Text variant="listItemLabel" color="highEmphasis">
                  {block.commandLabel}
                </Text>
                {block.mTitle && (
                  <StyledText variant="caption" color="mediumEmphasis" truncate>
                    {block.mTitle}
                  </StyledText>
                )}
              </>
            ) : (
              <Text variant="listItemLabel">This planning item appears to be deleted</Text>
            )}
          </Box>
          {block && (
            <Box container gap="6px" margin="0 2px 0 0">
              {updatedBy && (
                <Avatar
                  tooltipContent={`Last updated by ${updatedBy.mTitle}`}
                  className="avatar"
                  variant="user"
                  imageKey={updatedBy.mAvatarKey}
                  size={16}
                  title={updatedBy.mTitle}
                />
              )}
              <OrderButton
                dark={theme === 'dark'}
                resourceId={block.mRefId}
                resourceTitle={block.mTitle}
                resourceType={block.mType}
                formTypes={[block.mType]}
              />
            </Box>
          )}
        </LabelWrapper>
      }
      content={
        block ? (
          <EditMdfBlock blockId={block.mRefId} mdfId={block.mdfId} resourceId={resourceId} />
        ) : null
      }
      {...rest}
    />
  );
}

export type OrderWithLabel = Order & {
  formLabel: string;
  statusLabel: string;
  statusOptions: Alternative[];
};
interface TaskItemProps extends Pick<ToggleItemProps, 'id' | 'open' | 'setOpen'> {
  order?: OrderWithLabel | null;
  selected: boolean;
  onSelect: (event: MouseEvent<HTMLDivElement>) => void;
  showContextMenu?: boolean;
}

export function TaskItem({
  order,
  selected,
  onSelect,
  showContextMenu = true,
  ...rest
}: Readonly<TaskItemProps>) {
  const theme = useTheme();
  const { updateOrder } = useUpdateOrder();
  const [activeTheme] = useDinaTheme();
  const { show } = useContextMenu({ id: TASK_MENU_ID });

  const onContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    if (!showContextMenu) return;
    event.preventDefault();
    event.stopPropagation();
    show({
      event,
      props: {
        order: order,
      },
    });
  }, []);

  const updateOrderProperty = useCallback(
    (key: string, value: string) => {
      if (!order) return;

      updateOrder({
        mId: order.mId,
        mResourceId: order.mResourceId,
        [key]: value,
      }).then(
        () => {},
        () => {},
      );
    },
    [order],
  );

  return (
    <ToggleItem
      color={theme.palette.dina.buttonBackgroundHighEmphasis}
      onSelect={onSelect}
      selected={selected}
      onContextMenu={onContextMenu}
      trigger={
        <LabelWrapper container width="100%" justifyContent="space-between">
          <Box container alignItems="center" gap="4px">
            <TaskIcon className="skipOverride" />
            {order ? (
              <Text variant="listItemLabel" color="highEmphasis">
                {order.formLabel}
              </Text>
            ) : (
              <Text variant="listItemLabel" color="mediumEmphasis">
                This task appears to be deleted
              </Text>
            )}
          </Box>
          {order ? (
            <Box container gap="6px" margin="0 2px 0 0">
              <MemberAvatar mOwner={order?.mOwner} mAssignee={order.mAssignee} />
              <StatusWrapper height="28px" padding="0 8px" container>
                <LWSelect
                  value={order.mStatus}
                  options={order.statusOptions}
                  setValue={(value: string) => updateOrderProperty('mStatus', value)}
                />
              </StatusWrapper>
              <OrderButton
                dark={activeTheme === 'dark'}
                resourceId={order.mId}
                resourceTitle={order.formLabel}
                resourceType="order"
                formTypes={['order']}
              />
            </Box>
          ) : null}
        </LabelWrapper>
      }
      content={order ? <EditOrderBlock orderId={order.mId} /> : null}
      {...rest}
    />
  );
}

interface FallbackProps {
  label: string;
}
export function Fallback({ label }: Readonly<FallbackProps>) {
  return (
    <ContentWrapper>
      <Text variant="listItemLabelMedium">{label}</Text>
    </ContentWrapper>
  );
}
