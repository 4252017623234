import Dialog from 'components/dialog';
import initialValues from 'components/editor/constants/initialValues';
import variants from 'components/editor/constants/types/editorVariants';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import useTextStorage from 'hooks/useTextStorage';
import { Box } from 'layouts/box/Box';
import { Note } from 'types';

import NotePrintDoc from './docs/NotePrintDoc';

import { ErrorWrapper, LoadingWrapper, PrintViewWrapper } from './styled';

interface Props {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  note: Partial<Note>;
}

const NotePrint = ({ isDialogOpen, onCloseDialog, note }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const { data, loading, error } = useTextStorage(note?.mContentKey!, !note?.mContentKey);

  const initialValue = initialValues(variants.NOTES);

  const View = () => {
    if (loading)
      return (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      );

    if (error)
      return (
        <ErrorWrapper>
          <Box>Encountered following error while printing</Box>
          <Box>
            <pre>{JSON.stringify(error)}</pre>
          </Box>
        </ErrorWrapper>
      );

    return (
      <PrintViewWrapper>
        <PdfViewer>
          <NotePrintDoc note={note} content={data ?? initialValue} />
        </PdfViewer>
      </PrintViewWrapper>
    );
  };

  return (
    <Dialog container={undefined} open={isDialogOpen} onClose={onCloseDialog}>
      <View />
    </Dialog>
  );
};

export default NotePrint;
