import { useContext, useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { CellMap } from 'components/mdfEditor/fields/fields';
import { hasPermission } from 'components/mdfEditor/MdfEditor';
import UserContext from 'contexts/UserContext';
import { FieldMap, getFieldMap } from 'features/gridDeck/utils';
import { getFieldKey } from 'features/orderForm/utils';
import { PreviewType } from 'store/preview';
import { MTypeToMdfId, SupportedMetadataTypes } from 'types';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { FieldTypeEnum, Mdf, MdfField } from 'types/graphqlTypes';
import { ParsedMemberType } from 'types/members';

export type MetadataCell = CellContext<ParsedMemberType, unknown> & {
  errorValue: string;
  updateFieldValues: (val: NewFieldValue[]) => void;
  metadata: Metadata;
  mdf?: Mdf;
  setPreview: (val: PreviewType) => void;
};

interface GridWidgetMdfColumnProps {
  items: ParsedMemberType[];
  fieldMap: FieldMap;
  mdfMap: Record<SupportedMetadataTypes, Mdf | undefined>;
  groups: string[];
}

const getMaxWidth = (fieldModel: MdfField) => {
  switch (fieldModel.type) {
    case FieldTypeEnum.checkbox:
      return 100;
    case FieldTypeEnum.choice:
      return 160;
    case FieldTypeEnum.text:
      return 300;
    default:
      return undefined;
  }
};

export const getMdfColumnDefinitions = ({
  items,
  mdfMap,
  fieldMap,
  groups,
}: GridWidgetMdfColumnProps): ColumnDef<ParsedMemberType>[] => {
  const columns: ColumnDef<ParsedMemberType>[] = [];
  const mTypes: SupportedMetadataTypes[] = [];
  const uniqueKeys = new Set<string>();

  for (const member of items) {
    if (MTypeToMdfId[member.mType as SupportedMetadataTypes]) {
      mTypes.push(member.mType as SupportedMetadataTypes);
    }
  }

  for (const mType of mTypes) {
    const mdf = mdfMap[mType];
    if (mdf) {
      for (const field of mdf.fields) {
        uniqueKeys.add(getFieldKey(field.fieldId, mdf.id));
      }
    }
  }

  const it = uniqueKeys.entries();
  for (const entry of it) {
    const key = entry[0];
    const fieldModel = fieldMap[key];
    if (!fieldModel) continue;

    columns.push({
      accessorKey: `metadata.${fieldModel.fieldId}`,
      header: fieldModel.settings.label,
      maxSize: getMaxWidth(fieldModel),
      cell: (context) => {
        const { updateFieldValues, row, errorValue, metadata, mdf, setPreview } =
          context as MetadataCell;
        const Cell = CellMap[fieldModel.type];
        if (Cell && mdf) {
          // -- Do not render cell if the field does not belong to the form
          if (fieldModel.formId !== row.original.mdfId) return null;

          return (
            <Cell
              onClick={() => {
                if (fieldModel.type === FieldTypeEnum.subtype) {
                  setPreview(row.original);
                }
              }}
              key={fieldModel.fieldId}
              mdf={mdf}
              disableEdit={!hasPermission(mdf.permissions.write[fieldModel.fieldId], groups)}
              fieldModel={fieldModel}
              value={metadata[fieldModel.fieldId]}
              errorValue={errorValue}
              fieldSettings={fieldModel.settings}
              setValue={(newValue) => {
                if (newValue !== row.original.metadata[fieldModel.fieldId]) {
                  updateFieldValues([{ fieldId: fieldModel.fieldId, value: newValue }]);
                }
              }}
            />
          );
        }
        return <span>{mdf ? 'Missing field' : 'Missing schema'}</span>;
      },
    });
  }

  return columns;
};

export const useGetMdfColumns = (items: ParsedMemberType[]) => {
  const { groups } = useContext(UserContext);
  const { mdfsByMType } = useGetMdfs({ all: true });
  const fieldMap = useMemo(() => {
    return getFieldMap(mdfsByMType);
  }, [mdfsByMType]);

  const mdfDefinitions = useMemo(
    () => getMdfColumnDefinitions({ items, fieldMap, mdfMap: mdfsByMType, groups }),
    [items?.length, fieldMap, mdfsByMType],
  );

  return mdfDefinitions;
};
