import gql from 'graphql-tag';

export default gql`
  fragment currentInstance on MemberType {
    mId
    mRefId
    mTitle
    mThumbnailKey
    mStoryId
    mCreatedAt
    mUpdatedAt
    mUpdatedById
    mPublishingAt
    mContentKey
    mDefaultContentKey
    locked
    mTemplateId
    isTemplateInstance
    mState
    mType
    mProperties {
      __typename
      ... on PlatformType {
        platform
        platformKind
        account {
          accountUrl
          accountLogo
          accountTitle
          accountId
          accountRefId
          orderType
        }
        provider {
          id
          requestId
          state
          eventTime
          startTime
          stopTime
          message
        }
      }
    }
    items {
      itemId
      title
      templateType
      templateVariant
      iconUrl
      state
      eventTime
      startTime
      stopTime
      subItems {
        itemId
        title
        templateType
        templateVariant
        iconUrl
      }
    }
    mMetaData {
      key
      value
      manual
      autoValue
    }
    mAssignedMembers {
      mId
      mType
    }
    mRelatedMembers
    assets {
      mId
      assetRefId
      itemId
      itemType
      state
      eventTime
      startTime
      stopTime
    }
  }
`;
