import { memo, useCallback, useEffect, useState } from 'react';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import { Input } from 'components/input/Base';
import Tooltip from 'components/tooltip';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import { EmptyValueWrapper } from 'features/orderForm/styled';
import { FieldTypeEnum } from 'types/graphqlTypes';

import { CellProps } from '../fields';

import { getValue } from './TextField';

function TextCell({
  fieldModel,
  value,
  setValue,
  errorValue,
  fieldSettings,
  disableEdit,
  mdf,
}: Readonly<CellProps>) {
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const [liveValue, setLiveValue] = useState<string | null>(null);
  const { type } = fieldModel;
  const { hint, multiline } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);

  const showFieldEditor = useCallback(() => {
    if (!disableEdit) {
      showEditFieldDialog({
        startValue: value,
        fieldId: fieldModel.fieldId,
        headerText: `Edit ${fieldSettings?.label ?? fieldModel.fieldId}`,
        viewType: 'default',
        mdf,
        onConfirm: (v) => {
          setLiveValue(v as string | null);
          setValue(v);
        },
      });
    }
  }, [showEditFieldDialog, disableEdit, mdf, fieldModel, fieldSettings, value]);

  useEffect(() => {
    if (typeof value === 'string' || typeof value === 'number' || value === null) {
      if (value !== liveValue) setLiveValue(getValue(value, type) as string | null);
    }
  }, [value]);

  const doBlur = useCallback(() => {
    if (typeof liveValue === 'number') {
      setValue(liveValue);
    } else if (typeof liveValue === 'string') {
      setValue(liveValue.length > 0 ? liveValue : null);
    } else if (liveValue === null) {
      setValue(null);
    }
  }, [setValue, liveValue]);

  return multiline ? (
    <Tooltip title={disableEdit ? 'You are not allowed to edit this field' : hint}>
      <EmptyValueWrapper onClick={showFieldEditor}>{liveValue}</EmptyValueWrapper>
    </Tooltip>
  ) : (
    <Input
      disabled={disableEdit}
      title={hint}
      value={liveValue ?? ''}
      onChange={(e) => setLiveValue(e.target.value)}
      type={type === FieldTypeEnum.number ? 'number' : 'text'}
      onBlur={doBlur}
      required={Boolean(errorValue)}
    />
  );
}

export default memo(TextCell);
