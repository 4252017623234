import styled from '@emotion/styled/macro';

const DashedWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  height: 200px;
  border: 1px dashed ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

export default function EmptyState() {
  return (
    <DashedWrapper>Drag stories, pitches, rundowns or instances here to get started</DashedWrapper>
  );
}
