import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

interface GridContainerProps {
  isOver: boolean;
}

export const Dropzone = styled('div')<GridContainerProps>`
  width: 100%;
  min-width: 300px;
  height: 100%;
  .rdg {
    ${({ theme }) => css`
      --rdg-header-background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
      --rdg-row-hover-background-color: none;
      --rdg-color: ${theme.palette.dina.mediumEmphasis};
      --rdg-background-color: ${theme.palette.dina.surfaceCard};
      --rdg-border-color: ${theme.palette.dina.borderResting};
    `}
    div[role='columnheader'] {
      ${({ theme }) => theme.typography.dina.overline};
      display: flex;
      flex-direction: column;
      div,
      span {
        margin: auto;
      }
    }
    block-size: 100%;
  }
  .rdg-row.isDragging .rdg-cell {
    opacity: 0.3;
    background-opacity: 0.3;
  }

  .rdg-row.disableReorder .rdg-cell {
    color: orange;
    opacity: 0.8 !important;
    background-opacity: 0.8 !important;
  }

  .rdg-row.isOver .rdg-cell {
    box-shadow: ${({ theme }) => `inset 0px 2px 0 ${theme.palette.dina.onFocus}`};
  }

  .rdg-row.isOver.down .rdg-cell {
    box-shadow: ${({ theme }) => `inset 0px -2px 0 ${theme.palette.dina.onFocus} !important`};
  }

  .rdg-row:nth-of-type(odd) .rdg-cell {
    background: rgba(237, 237, 255, 0.05);
    &.disabled {
      background: rgba(0, 0, 0, 0.2) !important;
      &:hover {
        background: rgb(237 237 255 / 10%);
      }
    }
    &:hover {
      background: rgb(237 237 255 / 10%);
    }
  }
  .rdg-row:nth-of-type(even) .rdg-cell:hover {
    background: rgb(237 237 255 / 10%);
  }
  .rdg-cell.disable {
    cursor: not-allowed;
  }
  .rdg-cell.error {
    background: rgba(255, 0, 0, 0.2);
    cursor: not-allowed;
  }

  overflow: auto;
  border: 1px solid ${({ isOver }) => (isOver ? 'orange' : 'transparent')};
`;
