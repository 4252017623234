import styled from '@emotion/styled';

import AddMemberDropdown from 'components/addMember';

export const StyledDropdown = styled(AddMemberDropdown)`
  .MuiAutocomplete-root {
    height: 32px !important;
  }
`;

export const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  min-width: 200px;
  flex-direction: column;
  gap: 6px;
  .MuiFormControl-root {
    width: 100%;
  }
`;
