import { useMemo } from 'react';
import { Dictionary, keyBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { ResourceType } from 'types/forms/forms';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

export type ResourceDetails = {
  resourceId: string;
  resourceType: ResourceType;
  resourceTitle: string;
  orderFormMap?: Dictionary<OrderFormMemberType>;
};
interface ResourceProperties {
  /* unique Id of the resource */
  mId: string;
  /* resouce type: can ce 'story' or 'instance' or 'space' or 'rundown' */
  mType: ResourceType;
  /* Title of the resource */
  mTitle: string;
}

const useResourceDetails = ({ mId, mType, mTitle }: ResourceProperties): ResourceDetails => {
  const { orderForms } = useGetOrderForms();

  const orderFormMap = useMemo(() => {
    return keyBy(orderForms, (orderForm) => orderForm.mRefId);
  }, [orderForms]);

  return {
    resourceId: mId,
    resourceType: mType,
    resourceTitle: mTitle,
    orderFormMap,
  };
};

export default useResourceDetails;
