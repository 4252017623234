import { memo } from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import { elementTypes } from 'components/editor/constants';
import { EditorVariant } from 'components/editor/types';
import { getSelectedElement } from 'components/editor/utils';
import { CreateStringUnionType } from 'types';
import { PlatformStructure } from 'types/graphqlTypes';

import variants from '../../constants/types/editorVariants';
import { getConditionalBlocks } from '../../utils/getConditionalToolbarBlocks';

import CmsToolbar from './components/cmsToolbar';
import FacebookToolbar from './components/facebookToolbar';
import GeneralToolbar from './components/generalToolbar';
import InstagramToolbar from './components/instagramToolbar';
import LinearToolbar from './components/linearToolbar';
import LinkedInToolbar from './components/linkedInToolbar';
import MessageToolbar from './components/messageToolbar';
import NotesToolbar from './components/notesToolbar';
import TwitterToolbar from './components/twitterToolbar';

import {
  CMSBlockContainer,
  CMSToolbarWrapper,
  Container,
  GeneralToolbarWrapper,
  ToolbarWrapper,
} from './styled';

const {
  GENERAL,
  LINEAR,
  TWITTER,
  CMS,
  YOUTUBE,
  FACEBOOK,
  MESSAGE,
  NOTES,
  INSTAGRAM,
  LINKEDIN,
  DAILYNOTE,
} = variants;

const preventDefault: React.MouseEventHandler<HTMLDivElement> = (event) => event.preventDefault();

const variantConditions = [GENERAL, TWITTER, CMS, YOUTUBE, FACEBOOK, INSTAGRAM];

interface ToolbarVariantProps {
  variant: EditorVariant;
  isAllowed: boolean;
  platformKind?: string;
  showDoneButton: boolean;
  extraBlocksByPlatform: unknown;
}

const ToolbarVariant = ({
  variant,
  isAllowed,
  platformKind,
  showDoneButton,
  extraBlocksByPlatform,
}: ToolbarVariantProps) => {
  switch (variant) {
    case CMS:
      return <CmsToolbar />;
    case NOTES:
      return <NotesToolbar />;
    case LINEAR:
      return <LinearToolbar platformKind={platformKind} />;
    case MESSAGE:
      return <MessageToolbar showDoneButton={showDoneButton} />;
    case TWITTER:
      return <TwitterToolbar extraBlocks={extraBlocksByPlatform} />;
    case FACEBOOK:
      return <FacebookToolbar extraBlocks={extraBlocksByPlatform} />;
    case INSTAGRAM:
      return <InstagramToolbar extraBlocks={extraBlocksByPlatform} />;
    case LINKEDIN:
      return <LinkedInToolbar extraBlocks={extraBlocksByPlatform} />;
    case GENERAL:
    case DAILYNOTE:
      return <GeneralToolbar isAllowed={isAllowed} />;
    default: {
      return (
        !isAllowed &&
        variantConditions.includes(variant as CreateStringUnionType<typeof variantConditions>) && (
          <GeneralToolbar />
        )
      );
    }
  }
};

const isVoidSelected = (editor: Editor) => {
  const selectedElement = getSelectedElement(editor);
  if (selectedElement?.type === elementTypes.SECTION_DIVIDER) {
    const selectedChildElement = getSelectedElement(editor, { depth: 2 });
    return !!selectedChildElement && editor.isVoid(selectedChildElement);
  }
  return !!selectedElement && editor.isVoid(selectedElement);
};

export interface ToolbarProps {
  variant?: EditorVariant;
  readOnly: boolean;
  isAllowed: boolean;
  platformStructure?: PlatformStructure;
  isCmsBlock: boolean;
  toolbarPosition: 'top' | 'bottom';
  platformKind?: string;
  showDoneButton: boolean;
  showSidepanelButton: boolean;
}

const Toolbar = ({
  variant = GENERAL,
  readOnly,
  isAllowed,
  platformStructure,
  isCmsBlock,
  toolbarPosition,
  platformKind,
  showDoneButton,
}: Readonly<ToolbarProps>) => {
  const editor = useSlate();
  const extraBlocksByPlatform = getConditionalBlocks(variant, platformStructure);

  const autoHeightVariants = [NOTES, LINEAR, CMS, DAILYNOTE];

  /* Disable general toolbar when void element is selected if editor variant is 
  GENERAL, NOTES or CMS with block enabled. */
  const disableGeneralToolbar =
    isVoidSelected(editor) && ((isCmsBlock && variant === CMS) || variant === GENERAL);

  if (variant === YOUTUBE) return null;
  if (variant === CMS && isCmsBlock) {
    return (
      <CMSBlockContainer $readOnly={readOnly} onMouseDown={preventDefault}>
        <GeneralToolbarWrapper $readOnly={readOnly} $disableGeneralToolbar={disableGeneralToolbar}>
          <GeneralToolbar isAllowed={isAllowed} hideRightButtons />
        </GeneralToolbarWrapper>
        <CMSToolbarWrapper $readOnly={readOnly}>
          <CmsToolbar platformStructure={platformStructure} />
        </CMSToolbarWrapper>
      </CMSBlockContainer>
    );
  }

  return (
    <ToolbarWrapper>
      <Container
        onMouseDown={preventDefault}
        $readOnly={readOnly}
        $autoHeight={autoHeightVariants.includes(
          variant as CreateStringUnionType<typeof autoHeightVariants>,
        )}
        $disableGeneralToolbar={disableGeneralToolbar}
        $toolbarPosition={toolbarPosition}
      >
        <ToolbarVariant
          variant={variant}
          platformKind={platformKind}
          isAllowed={isAllowed}
          showDoneButton={showDoneButton}
          extraBlocksByPlatform={extraBlocksByPlatform}
        />
      </Container>
    </ToolbarWrapper>
  );
};

export default memo(Toolbar);
