import { useCallback } from 'react';

import { useUpdateBoardColumns } from 'api/gridAndKanban/useUpdateBoardColumns';
import useToast from 'components/toast/useToast';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetInstanceDetails from 'hooks/useGetInstanceDetails';
import { isContentType } from 'store/tabs';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { ParsedMemberType } from 'types/members';

/** Hook that provides actions for a grid row. */
export default function useRowActions(boardId: string, groupId?: string) {
  const { navigateTo } = useDinaNavigate();
  const { getInstanceURLParams } = useGetInstanceDetails();
  const { errorToast } = useToast();
  const { removeMember } = useUpdateBoardColumns(boardId);

  /** Open the member in a new tab. */
  const onOpen = useCallback((member: ParsedMemberType) => {
    if (!member.mId) return;

    if (member.mType === MemberTypeEnum.Instance) {
      getInstanceURLParams(member.mId)
        .then((details) => {
          if (details) {
            if (details.path === 'story') {
              navigateTo(details.path, details.id, {
                tab: 'instances',
                entityId: member.mId,
              });
            } else {
              navigateTo(details.path as 'rundown' | 'rundowntemplate', details.id);
            }
          }
        })
        .catch(errorToast);
    } else if (isContentType(member.mType)) {
      navigateTo(member.mType, member.mId);
    }
  }, []);

  /** Remove the member from the grid. */
  const onRemove = useCallback(
    (member: ParsedMemberType) => {
      if (!member.mId || !groupId) return;
      void removeMember(member.mId, groupId);
    },
    [groupId],
  );

  return { onOpen, onRemove };
}
