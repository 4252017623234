import { useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/text/Text';
import useDidMount from 'hooks/useDidMount';

import actionTypes from './utils/actionTypes';
import reducer from './utils/reducer';
import MetadataField from './metadataField';

import { Header, MetadataFieldsWrapper, MetadataWrapper } from './styled';

const Compare = (func) => (array) => func(array);

const MetaDataView = ({
  onBackClick,
  onUpdateMeta,
  fields,
  parameterFields,
  metadata,
  usage,
  disableEdit,
  ...rest
}) => {
  const didMount = useDidMount();
  const [state, dispatch] = useReducer(reducer, metadata);
  const deepCompare = Compare(JSON.stringify);

  useEffect(() => {
    if (didMount && deepCompare(metadata) !== deepCompare(state)) {
      dispatch({ type: actionTypes.UPDATE_STATE, payload: metadata });
    }
  }, [deepCompare(metadata)]);

  const handleUpdateMeta = (payload) => {
    dispatch({ type: actionTypes.UPDATE_META, payload });
    onUpdateMeta(payload);
  };

  const MemoizedField = useMemo(
    () => (
      <MetadataFieldsWrapper>
        {fields.map((field) => (
          <MetadataField
            {...field}
            key={field.id}
            onUpdateValue={handleUpdateMeta}
            metadata={state}
            fields={fields}
            parameterFields={parameterFields}
            usage={usage}
            disableEdit={disableEdit}
          />
        ))}
      </MetadataFieldsWrapper>
    ),
    [deepCompare(state), disableEdit],
  );

  return (
    <MetadataWrapper {...rest}>
      <Header>
        <Text variant="h7">Metadata</Text>
      </Header>
      {MemoizedField}
    </MetadataWrapper>
  );
};

MetaDataView.propTypes = {
  /** Fields to be mapped on metadata on instance view */
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  /** current Metadata value for that instance */
  metadata: PropTypes.arrayOf(PropTypes.shape({})),
  /** Callback when any metadata is updated */
  onUpdateMeta: PropTypes.func,
  /** usage: compact or standard */
  usage: PropTypes.string,
  onBackClick: PropTypes.func,
  parameterFields: PropTypes.shape({}),
  disableEdit: PropTypes.bool,
};

MetaDataView.defaultProps = {
  onBackClick: undefined,
  fields: [],
  metadata: [],
  onUpdateMeta: () => {},
  usage: 'standard',
};

export default MetaDataView;
