import { memo, useId, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import useGetMember from 'api/useGetMember';
import { CommandToolbarProps } from 'components/command/command-types';
import QuickSearch from 'components/command/QuickSearch';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { ChoiceField } from 'components/mdfEditor/fields/choice/ChoiceField';
import {
  getKeysFromMiniMember,
  toMiniMember,
} from 'components/mdfEditor/fields/relation/relation-utils';
import { RelationItem } from 'components/mdfEditor/fields/relation/RelationItem';
import { FieldHeader } from 'components/mdfEditor/styled';
import { FieldValue, MiniMember } from 'types/forms/forms';
import {
  FieldTypeEnum,
  GetOrderEnum,
  LayoutSettings,
  MdfField,
  MemberType,
  MemberTypeEnum,
  SearchItemTypeEnum,
} from 'types/graphqlTypes';
import { FilterValueType } from 'types/widget';

import { FilterComponentType, FilterProps } from './types';

const Wrapper = styled.div`
  width: 100%;
`;

const viewSettings: LayoutSettings = {
  visible: true,
  fieldId: 'view',
  hint: '',
  label: 'Select view type',
};

const layoutSettings: LayoutSettings = {
  visible: true,
  fieldId: 'type',
  hint: '',
  label: 'Select source',
};

const layoutSettingsType: LayoutSettings = {
  visible: true,
  fieldId: 'form',
  hint: '',
  label: 'Select order type',
};

const fieldModel: MdfField = {
  fieldId: 'type',
  type: FieldTypeEnum.choice,
  defaultValue: { value: GetOrderEnum.Assignee },
  alternatives: [
    {
      id: GetOrderEnum.Assignee,
      label: 'By assignee',
      value: GetOrderEnum.Assignee,
    },
    {
      id: GetOrderEnum.Owner,
      label: 'By task owner',
      value: GetOrderEnum.Owner,
    },
    {
      id: GetOrderEnum.Form,
      label: 'By order type',
      value: GetOrderEnum.Form,
    },
    {
      id: GetOrderEnum.Resource,
      label: 'By item - story, instance, task, planning item, rundown',
      value: GetOrderEnum.Resource,
    },
    {
      id: GetOrderEnum.Space,
      label: 'Show all orders for a space',
      value: GetOrderEnum.Space,
    },
  ],
};

const viewModel: MdfField = {
  fieldId: 'view',
  type: FieldTypeEnum.choice,
  defaultValue: { value: 'grid' },
  alternatives: [
    {
      id: 'grid',
      label: 'Grid',
      value: 'grid',
    },
    {
      id: 'list',
      label: 'List',
      value: 'list',
    },
  ],
};

const generateMiniMember = (val: MemberType, type: GetOrderEnum): MiniMember => {
  if (type === GetOrderEnum.Space) {
    if (val.mType === MemberTypeEnum.Team)
      return { id: `${val.mId}-team`, type: MemberTypeEnum.Team };
    if (val.mType === MemberTypeEnum.Department)
      return { id: `${val.mId}-department`, type: MemberTypeEnum.Department };
    return { id: val.mId!, connectedId: val.mRefId!, type: val.mType! };
  }
  return toMiniMember(val);
};

const getDataFromFilters = (
  val: FilterValueType,
): {
  resourceId: string;
  resourceType: GetOrderEnum;
  resourceMemberType?: MemberTypeEnum;
  viewType: string;
  resourceConnectedId?: string;
} => {
  const { resourceId, resourceType, viewType, resourceConnectedId, resourceMemberType } = val;
  return {
    resourceId: (resourceId as string) ?? '',
    resourceType: (resourceType as GetOrderEnum) ?? GetOrderEnum.Space,
    resourceConnectedId: (resourceConnectedId ?? resourceId) as string | undefined,
    resourceMemberType: resourceMemberType as MemberTypeEnum | undefined,
    viewType: viewType as FieldTypeEnum,
  };
};

function OrderFilters({ filters = {}, updateFilters }: Readonly<FilterProps>) {
  const uniqueId = useId();
  const { openItem } = useOpenMember();
  const [searchString, setSearchString] = useState('');
  const { resourceId, resourceType, viewType, resourceConnectedId, resourceMemberType } =
    getDataFromFilters(filters);
  const { orderForms } = useGetOrderForms();

  const dataKeys = resourceMemberType
    ? getKeysFromMiniMember({
        id: resourceId,
        connectedId: resourceConnectedId,
        type: resourceMemberType,
      })
    : null;

  const { data } = useGetMember({
    mId: dataKeys?.mId ?? '',
    mRefId: dataKeys?.mRefId ?? '',
    skip: !dataKeys || resourceType === GetOrderEnum.Form,
  });

  const showQuickSearch = resourceType !== GetOrderEnum.Form;
  const showFormChoice = resourceType === GetOrderEnum.Form;

  const fieldModelTypes: MdfField = {
    fieldId: 'form',
    type: FieldTypeEnum.choice,
    defaultValue: { value: null },
    alternatives: orderForms.map((o) => {
      return {
        id: o.mRefId,
        value: o.mRefId,
        label: o.mDescription,
      };
    }),
  };

  const onSelect = (val: MemberType) => {
    const miniMember = generateMiniMember(val, resourceType);
    updateFilters({
      ...filters,
      resourceId: miniMember.id,
      resourceConnectedId: miniMember.connectedId ?? miniMember.id,
      resourceMemberType: miniMember.type,
    });
  };

  const onTypeChoice = (val: FieldValue) => {
    updateFilters({
      ...filters,
      resourceType: val as FieldTypeEnum,
    });
  };

  const onViewChoice = (val: FieldValue) => {
    updateFilters({
      ...filters,
      viewType: val as FieldTypeEnum,
    });
  };

  const onResourceChange = (val: string) => {
    updateFilters({
      ...filters,
      resourceId: val,
    });
  };

  const quickSearchFilters = useMemo(() => {
    const toolbarState: CommandToolbarProps = {
      sortBy: 'updatedAt',
      order: 'desc',
      mTypes: [],
      rangeBy: null,
      statusFilter: [],
      mdfId: null,
      defaultMdfId: null,
      createdByIds: [],
      assignedIds: [],
      isFiltering: false,
    };
    switch (resourceType) {
      case GetOrderEnum.Owner:
      case GetOrderEnum.Assignee: {
        toolbarState.mTypes = [
          SearchItemTypeEnum.user,
          SearchItemTypeEnum.department,
          SearchItemTypeEnum.team,
        ];
        break;
      }
      case GetOrderEnum.Space: {
        toolbarState.mTypes = [SearchItemTypeEnum.space];
        break;
      }
      case GetOrderEnum.Resource: {
        // All mTypes that support attaching tasks to
        toolbarState.mTypes = [
          SearchItemTypeEnum.story,
          SearchItemTypeEnum.res_story,
          SearchItemTypeEnum.pitch,
          SearchItemTypeEnum.res_pitch,
          SearchItemTypeEnum.instance,
          SearchItemTypeEnum.rundown,
          SearchItemTypeEnum.order,
          SearchItemTypeEnum.block,
        ];
        break;
      }
    }
    return toolbarState;
  }, [resourceType]);

  return (
    <Wrapper>
      <ChoiceField
        editorId={uniqueId}
        fieldModel={viewModel}
        fieldSettings={null}
        defaultFieldSettings={viewSettings}
        value={viewType as FieldValue}
        setValue={onViewChoice}
        errorMessage={undefined}
        style={{}}
        view="default"
      />
      <ChoiceField
        editorId={uniqueId}
        fieldModel={fieldModel}
        fieldSettings={null}
        defaultFieldSettings={layoutSettings}
        value={resourceType as FieldValue}
        setValue={onTypeChoice}
        errorMessage={undefined}
        style={{}}
        view="default"
      />
      {showQuickSearch && (
        <>
          <FieldHeader>Filter</FieldHeader>
          <QuickSearch
            allowClear
            doEmptySearches
            placeholderText={
              resourceType === GetOrderEnum.Space
                ? 'Find a space'
                : 'Find a story, instance, rundown..'
            }
            searchString={searchString}
            setSearchString={setSearchString}
            onKeyDown={() => {}}
            onSelect={onSelect}
            titleErrorTooltip={''}
            toolbarState={quickSearchFilters}
          />
          {data && (
            <RelationItem
              key={`${data.mId}:${data.mRefId}`}
              member={data}
              onRemoveClick={() => {}}
              onOpenClick={() => openItem(data)}
            />
          )}
        </>
      )}
      {showFormChoice && (
        <ChoiceField
          editorId={uniqueId}
          fieldModel={fieldModelTypes}
          fieldSettings={null}
          defaultFieldSettings={layoutSettingsType}
          value={resourceId as FieldValue}
          setValue={(val) => onResourceChange(val as string)}
          errorMessage={undefined}
          view="default"
          style={{}}
        />
      )}
    </Wrapper>
  );
}

export const FilterComponent: FilterComponentType = memo(OrderFilters) as FilterComponentType;
