/* eslint-disable max-len */
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { useCreateMdf } from 'api/mdf/useCreateMdf';
import { useDeleteMdf } from 'api/mdf/useDeleteMdf';
import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useCreateOptionList } from 'api/optionLists/useCreateOptionList';
import { useDeleteOptionList } from 'api/optionLists/useDeleteOptionList';
import { useGetOptionLists } from 'api/optionLists/useGetOptionLists';
import { useUpdateOptionList } from 'api/optionLists/useUpdateOptionList';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as ChoiceList } from 'assets/icons/systemicons/dropdown.svg';
import { ReactComponent as EditIcon } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { EditMdf } from 'components/editMdfDialog/EditMdf';
import { useEditStringDialog } from 'components/editStringDialog/EditStringDialog';
import LoadingIndicator from 'components/loadingIndicator';
import SplitBar from 'components/split';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip/Tooltip';
import { useGetMdfIcon } from 'features/mdf/useGetMdfIcon';
import { Mdf, OptionList } from 'types/graphqlTypes';

import { useChangedMdfs } from '../../atomsTs';
import CreateOptionListDialog from '../optionList/CreateOptionListDialog';
import { OptionListComponent } from '../optionList/OptionList';

import CreateSchemaDialog, { SchemaType } from './CreateSchemaDialog';

import {
  Actions,
  Form,
  FormsWrapper,
  Header,
  SchemaTitle,
  SideBarWrapper,
  Wrapper,
} from './styled';

interface MdfListItemProps {
  mdf: Mdf;
  isDefault: boolean;
  selectedForm: Mdf | null;
  changedMdfs: Record<string, Mdf>;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  setSelectedSchemaId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedListId: React.Dispatch<React.SetStateAction<string | null>>;
  openEditLabelDialog: (updatedMdf: Mdf | null, originalMdf: Mdf) => void;
  doDelete: () => void;
  getMdfIcon?: (mdf: Mdf) => React.FC<React.SVGProps<SVGSVGElement>> | undefined;
}

interface OptionListItemProps {
  list: OptionList;
  selectedList: OptionList | null;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onDoEditLabel: () => void;
  setSelectedSchemaId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedListId: React.Dispatch<React.SetStateAction<string | null>>;
  doDelete: () => void;
}

function MdfListItem({
  changedMdfs,
  mdf,
  setSelectedSchemaId,
  setSelectedListId,
  selectedForm,
  isDefault,
  openEditLabelDialog,
  doDelete,
  getMdfIcon,
}: Readonly<MdfListItemProps>) {
  const updatedMdf = changedMdfs[mdf.id];
  const Icon = getMdfIcon ? getMdfIcon(mdf) : null;
  return (
    <Tooltip
      title={updatedMdf && `${updatedMdf?.label ?? mdf.label} has unsaved changes`}
      key={mdf.id}
    >
      <Form
        onClick={() => {
          setSelectedSchemaId(mdf.id);
          setSelectedListId(null);
        }}
        $selected={mdf.id === selectedForm?.id}
      >
        {Icon && <Icon style={{ width: '18px', marginRight: '2px' }} />}
        <SchemaTitle>
          {updatedMdf ? (
            <Text variant="listItemLabelItalic" color="statusWarning">
              {`* ${updatedMdf?.label ?? mdf.label}`}
            </Text>
          ) : (
            <Text
              variant="listItemLabel"
              color={mdf.id === selectedForm?.id ? 'whiteHighEmphasis' : 'highEmphasis'}
            >
              {mdf.label}
            </Text>
          )}
        </SchemaTitle>
        {!isDefault && (
          <Actions>
            {!mdf.isSubtype && (
              <IconButton
                title="Edit schema name"
                usage="text"
                size={24}
                iconSize={18}
                onClick={() => openEditLabelDialog(changedMdfs[mdf.id] ?? null, mdf)}
                className="editIcon"
              >
                <EditIcon />
              </IconButton>
            )}

            <IconButton
              title="Delete schema"
              usage="text"
              size={24}
              onClick={doDelete}
              className="deleteIcon"
            >
              <DeleteIcon />
            </IconButton>
          </Actions>
        )}
      </Form>
    </Tooltip>
  );
}

function OptionListItem({
  list,
  setSelectedSchemaId,
  setSelectedListId,
  selectedList,
  doDelete,
  onDoEditLabel,
}: Readonly<OptionListItemProps>) {
  return (
    <Form
      onClick={() => {
        setSelectedSchemaId(null);
        setSelectedListId(list.id);
      }}
      $selected={list.id === selectedList?.id}
    >
      <ChoiceList style={{ width: '18px', marginRight: '2px' }} />
      <SchemaTitle>
        <Text
          variant="listItemLabel"
          color={list.id === selectedList?.id ? 'whiteHighEmphasis' : 'highEmphasis'}
        >
          {list.label}
        </Text>
      </SchemaTitle>
      <Actions>
        <IconButton
          title="Edit label"
          usage="text"
          size={24}
          iconSize={18}
          onClick={onDoEditLabel}
          className="editIcon"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          title="Delete list"
          usage="text"
          size={24}
          onClick={doDelete}
          className="deleteIcon"
        >
          <DeleteIcon />
        </IconButton>
      </Actions>
    </Form>
  );
}

export function MdfSchemas() {
  const { errorToast } = useToast();
  const { mdfsSeparated, loading, error } = useGetMdfs({ all: true });
  const { optionLists } = useGetOptionLists();
  const { createMdf } = useCreateMdf();
  const { deleteMdf } = useDeleteMdf();
  const { createOptionList } = useCreateOptionList();
  const { deleteOptionList } = useDeleteOptionList();
  const { updateOptionList } = useUpdateOptionList();
  const [, showEditStringDialog] = useEditStringDialog();
  const [schemaType, setSchemaType] = useState<SchemaType>('custom');
  const [mdfToDelete, setMdfToDelete] = useState<Mdf | null>(null);
  const [listToDelete, setListToDelete] = useState<OptionList | null>(null);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createListModalOpen, setCreateListModalOpen] = useState(false);
  const [selectOnCreate, setSelectOnCreate] = useState<string | null>(null);
  const [selectListOnCreate, setSelectListOnCreate] = useState<string | null>(null);
  const [creatingMdf, setCreatingMdf] = useState(false);
  const [creatingList, setCreatingList] = useState(false);
  const [selectedSchemaId, setSelectedSchemaId] = useState<string | null>(null);
  const [selectedListId, setSelectedListId] = useState<string | null>(null);
  const [changedMdfs, setChangedMdfs] = useChangedMdfs();
  const [settingsHeight, setSettingsHeight] = useState(300);
  const getMdfIcon = useGetMdfIcon();
  const mdfs = useMemo(() => {
    return [
      ...mdfsSeparated.defaults,
      ...mdfsSeparated.instances,
      ...mdfsSeparated.custom,
      ...mdfsSeparated.subTypes,
    ];
  }, [mdfsSeparated]);

  const selectedForm = useMemo(() => {
    if (!selectedSchemaId) return null;
    return changedMdfs[selectedSchemaId] ?? mdfs.find((mdf) => mdf.id === selectedSchemaId) ?? null;
  }, [mdfsSeparated, changedMdfs, selectedSchemaId]);

  const selectedList = useMemo(() => {
    return optionLists.find((l) => l.id === selectedListId) ?? null;
  }, [selectedListId, optionLists]);

  useLayoutEffect(() => {
    if (mdfs?.length > 0 && !selectedForm) {
      setSelectedSchemaId(mdfs[0].id);
    }
  }, [mdfs]);

  const createNewMdf = useCallback(
    async (formTitle: string) => {
      const newMdf = await createMdf({ label: formTitle });
      if (newMdf) {
        setSelectedSchemaId(newMdf.id);
      }
    },
    [setSelectedSchemaId, createMdf],
  );

  const doDelete = useCallback(async () => {
    if (mdfToDelete) {
      await deleteMdf(mdfToDelete.id);
      if (mdfToDelete.id === selectedForm?.id) {
        setSelectedSchemaId(mdfs.length ? mdfs[0].id : null);
      }
      setMdfToDelete(null);
    } else if (listToDelete) {
      await deleteOptionList(listToDelete.id);
      if (listToDelete.id === selectedListId) {
        setSelectedListId(optionLists.length ? optionLists[0].id : null);
      }
      setListToDelete(null);
    }
  }, [mdfToDelete, listToDelete]);

  const onMdfChange = useCallback(
    (mdf: Mdf | null, originalMdf: Mdf | null) => {
      if (mdf === null || originalMdf === null) return;
      if (!changedMdfs[mdf.id] && !isEqual(mdf, originalMdf)) {
        setChangedMdfs({
          ...changedMdfs,
          [mdf.id]: mdf,
        });
      } else if (changedMdfs[mdf.id]) {
        if (isEqual(mdf, originalMdf)) {
          const copy = { ...changedMdfs };
          delete changedMdfs[mdf.id];
          setChangedMdfs(copy);
        } else {
          setChangedMdfs((prevState) => {
            return {
              ...prevState,
              [mdf.id]: mdf,
            };
          });
        }
      }
      setSelectedSchemaId(mdf.id);
    },
    [changedMdfs, setSelectedSchemaId, setChangedMdfs],
  );

  const openEditLabelDialog = useCallback(
    (updatedMdf: Mdf | null, originalMdf: Mdf) => {
      showEditStringDialog({
        headerText: 'Edit form label',
        required: true,
        startValue: updatedMdf?.label ?? originalMdf.label,
        onConfirm: (val) => {
          const copy = { ...(updatedMdf ?? originalMdf) };
          copy.label = val;
          onMdfChange(copy, originalMdf);
        },
      });
    },
    [showEditStringDialog, createNewMdf, changedMdfs],
  );

  const resize = useCallback(() => {
    const wrapper = document.getElementById('settings-wrapper');
    // We are reducing the available height manually, the static numbers represent
    // header, tabs, sub header and footer which do not change in height.
    // Ideally the entire settings dialog layout is properly refactored to use
    // flex correctly at a later point.
    const subHeight = (wrapper?.getBoundingClientRect().height ?? 400) - 57 - 48 - 30 - 44;
    setSettingsHeight(subHeight);
  }, [setSettingsHeight]);

  const onConfirm = useCallback(
    (createParams: { label: string; schemaType: SchemaType; instanceId?: string }) => {
      const { label, schemaType: newType, instanceId } = createParams;
      setCreatingMdf(true);
      createMdf({ label, isSubtype: newType === 'subtype', id: instanceId })
        .then((val) => {
          if (val) setSelectOnCreate(val.id);
        })
        .catch(errorToast)
        .finally(() => {
          setCreatingMdf(false);
          setCreateModalOpen(false);
        });
    },
    [setCreatingMdf, setSelectOnCreate],
  );

  const doEditLabelOptionList = useCallback(
    (list: OptionList) => {
      showEditStringDialog({
        headerText: 'Edit list label',
        required: true,
        startValue: list.label,
        onConfirm: (val) => {
          const copy = { ...list };
          copy.label = val;
          updateOptionList(copy).catch(errorToast);
        },
      });
    },
    [updateOptionList, showEditStringDialog],
  );

  const doOpenOptionList = useCallback(
    (id: string) => {
      setSelectedSchemaId(null);
      setSelectedListId(id);
    },
    [setSelectedSchemaId, setSelectedListId],
  );

  const onConfirmList = useCallback(
    (createParams: { label: string; id: string }) => {
      setCreatingList(true);
      createOptionList({ ...createParams, optionListType: 'choice' })
        .then((val) => {
          if (val) setSelectOnCreate(val.id);
        })
        .catch(errorToast)
        .finally(() => {
          setCreatingList(false);
          setCreateListModalOpen(false);
        });
    },
    [setCreatingList, setCreateListModalOpen, setSelectOnCreate],
  );

  useEffect(() => {
    if (selectOnCreate) {
      const createdMdf = mdfs.find((mdf) => mdf.id === selectOnCreate);
      if (createdMdf) {
        setSelectedSchemaId(createdMdf.id);
        setSelectOnCreate(null);
      }
    }
  }, [selectOnCreate, mdfs, setSelectOnCreate, setSelectedSchemaId]);

  useEffect(() => {
    if (selectListOnCreate) {
      const createdList = optionLists.find((list) => list.id === selectListOnCreate);
      if (createdList) {
        setSelectedListId(createdList.id);
        setSelectListOnCreate(null);
      }
    }
  }, [selectListOnCreate, optionLists, setSelectListOnCreate, setSelectedSchemaId]);

  useEffect(() => {
    const resizeListener = () => resize();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [resize]);

  useLayoutEffect(() => {
    resize();
  }, [mdfsSeparated]);

  const SideBar = useMemo(() => {
    return (
      <FormsWrapper>
        <Header>
          <Text variant="overline" color="highEmphasis">
            System defaults
          </Text>
        </Header>
        {loading || (error && <LoadingIndicator className={undefined} height={32} width={32} />)}
        <SideBarWrapper height={settingsHeight}>
          {mdfsSeparated.defaults.map((mdf) => (
            <MdfListItem
              key={mdf.id}
              mdf={mdf}
              isDefault={true}
              changedMdfs={changedMdfs}
              selectedForm={selectedForm}
              setSelectedListId={setSelectedListId}
              setSelectedSchemaId={setSelectedSchemaId}
              openEditLabelDialog={openEditLabelDialog}
              doDelete={() => setMdfToDelete(mdf)}
              getMdfIcon={getMdfIcon}
            />
          ))}
          <Header inline>
            <Text variant="overline" color="highEmphasis">
              Instance schemas
            </Text>
            <IconButton
              title="Create schema"
              size={24}
              iconSize={20}
              usage="text"
              disabled={!!(loading || error)}
              onClick={() => {
                setCreateModalOpen(true);
                setSchemaType('instance');
              }}
            >
              <Add />
            </IconButton>
          </Header>
          <div>
            {mdfsSeparated.instances.map((mdf) => (
              <MdfListItem
                key={mdf.id}
                mdf={mdf}
                isDefault={false}
                changedMdfs={changedMdfs}
                selectedForm={selectedForm}
                setSelectedSchemaId={setSelectedSchemaId}
                setSelectedListId={setSelectedListId}
                openEditLabelDialog={openEditLabelDialog}
                doDelete={() => setMdfToDelete(mdf)}
                getMdfIcon={getMdfIcon}
              />
            ))}
          </div>
          <Header inline>
            <Text variant="overline" color="highEmphasis">
              Custom schemas
            </Text>
            <IconButton
              title="Create schema"
              size={24}
              iconSize={20}
              usage="text"
              disabled={!!(loading || error)}
              onClick={() => {
                setCreateModalOpen(true);
                setSchemaType('custom');
              }}
            >
              <Add />
            </IconButton>
          </Header>
          <div>
            {mdfsSeparated.custom.map((mdf) => (
              <MdfListItem
                key={mdf.id}
                mdf={mdf}
                isDefault={false}
                changedMdfs={changedMdfs}
                selectedForm={selectedForm}
                setSelectedListId={setSelectedListId}
                setSelectedSchemaId={setSelectedSchemaId}
                openEditLabelDialog={openEditLabelDialog}
                doDelete={() => setMdfToDelete(mdf)}
                getMdfIcon={getMdfIcon}
              />
            ))}
          </div>
          <Header inline>
            <Text variant="overline" color="highEmphasis">
              Sub types
            </Text>
            <IconButton
              title="Create schema"
              size={24}
              iconSize={20}
              usage="text"
              disabled={!!(loading || error)}
              onClick={() => {
                setCreateModalOpen(true);
                setSchemaType('subtype');
              }}
            >
              <Add />
            </IconButton>
          </Header>
          <div>
            {mdfsSeparated.subTypes.map((mdf) => (
              <MdfListItem
                key={mdf.id}
                mdf={mdf}
                isDefault={false}
                changedMdfs={changedMdfs}
                selectedForm={selectedForm}
                setSelectedListId={setSelectedListId}
                setSelectedSchemaId={setSelectedSchemaId}
                openEditLabelDialog={openEditLabelDialog}
                doDelete={() => setMdfToDelete(mdf)}
                getMdfIcon={getMdfIcon}
              />
            ))}
          </div>
          <Header inline>
            <Text variant="overline" color="highEmphasis">
              Option lists
            </Text>
            <IconButton
              title="Create new list"
              size={24}
              iconSize={20}
              usage="text"
              disabled={!!(loading || error)}
              onClick={() => {
                setCreateListModalOpen(true);
              }}
            >
              <Add />
            </IconButton>
          </Header>
          <div>
            {optionLists.map((list) => (
              <OptionListItem
                key={list.id}
                list={list}
                selectedList={selectedList}
                setSelectedListId={setSelectedListId}
                setSelectedSchemaId={setSelectedSchemaId}
                doDelete={() => setListToDelete(list)}
                onDoEditLabel={() => doEditLabelOptionList(list)}
              />
            ))}
          </div>
        </SideBarWrapper>
      </FormsWrapper>
    );
  }, [
    loading,
    error,
    settingsHeight,
    mdfsSeparated,
    changedMdfs,
    selectedForm,
    selectedList,
    optionLists,
    setListToDelete,
    setSelectedListId,
    setSelectedSchemaId,
    setCreateModalOpen,
    openEditLabelDialog,
    doDelete,
  ]);

  return (
    <Wrapper>
      <SplitBar
        split={undefined}
        style={{
          height: '100%',
        }}
        primary="first"
        pane1Style={{
          minWidth: '180px',
          maxWidth: '300px',
        }}
        pane2Style={{
          minWidth: '200px',
          height: '100%',
        }}
      >
        {SideBar}
        {selectedForm && (
          <EditMdf mdf={selectedForm} onChange={onMdfChange} doOpenOptionList={doOpenOptionList} />
        )}
        {selectedList && <OptionListComponent list={selectedList} />}
      </SplitBar>
      <CreateSchemaDialog
        existingInstanceMdfs={mdfsSeparated.instances}
        schemaType={schemaType}
        setSchemaType={setSchemaType}
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        creatingMdf={creatingMdf}
        onCreate={onConfirm}
      />
      <CreateOptionListDialog
        existingOptionLists={optionLists}
        open={createListModalOpen}
        setOpen={setCreateListModalOpen}
        creating={creatingList}
        onCreate={onConfirmList}
      />
      <DeleteDialog
        open={Boolean(mdfToDelete) || Boolean(listToDelete)}
        onClose={() => {
          setMdfToDelete(null);
          setListToDelete(null);
        }}
        onClick={doDelete}
        title={`Delete ${mdfToDelete ? 'schema' : 'list'}? `}
        message={`Are you sure you want to delete ${mdfToDelete?.label ?? listToDelete?.label}?
        This may have unintended side effects places this is in use, and cannot be undone.`}
      />
    </Wrapper>
  );
}
