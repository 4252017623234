import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';

import { useUpdateBoardColumns } from 'api/gridAndKanban/useUpdateBoardColumns';

interface DndWrapperProps {
  children: React.ReactNode;
  memberIds: string[];
  tableId: string;
  itemGroupId?: string;
}

// Added to prevent accidental dnd, and for action button access
const activationConstraint = {
  distance: 30,
};

const modifiers = [restrictToVerticalAxis];

/** Wrapper containing the reordering functionality. */
export default function ReorderWrapper({
  children,
  memberIds,
  tableId,
  itemGroupId,
}: Readonly<DndWrapperProps>) {
  const { reorderMembers } = useUpdateBoardColumns(tableId);

  const updateOrder = async (sourceId: string, targetId: string) => {
    if (!itemGroupId) return;
    const sourceIndex = memberIds.indexOf(sourceId);
    const targetIndex = memberIds.indexOf(targetId);
    const mOrder = arrayMove(memberIds, sourceIndex, targetIndex);
    await reorderMembers(mOrder, itemGroupId);
  };

  function onDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      void updateOrder(active.id as string, over.id as string);
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={modifiers}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      {children}
    </DndContext>
  );
}
