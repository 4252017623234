import React from 'react';

import { Wrapper, Icon, Heading, Body } from './styled';

function EmptyState({ message }) {
  return (
    <Wrapper>
      <Icon />
      <Heading>Nothing to show here</Heading>
      <Body>{message}</Body>
    </Wrapper>
  );
}

export default EmptyState;
