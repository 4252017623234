import { ColumnDef } from '@tanstack/react-table';

import { ParsedMemberType } from 'types/members';

/**
 * Order the columns so that the title column is first,
 * followed by MDF columns, and then default columns.
 * **/
export const orderColumns = (
  defaultDefinitions: ColumnDef<ParsedMemberType>[],
  mdfDefinitions: ColumnDef<ParsedMemberType>[],
) => {
  const defaultColumns = [...defaultDefinitions];
  const titleColumn = defaultColumns.shift();
  return [...(titleColumn ? [titleColumn] : []), ...mdfDefinitions, ...defaultColumns];
};
