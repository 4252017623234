import { gql } from 'graphql-tag';

export const BATCH_GET_MEMBERS = gql`
  query BatchGetGridMembers($input: BatchGetMembersInput) {
    batchGetMembers(input: $input) {
      mId
      mRefId
      mPlannedDuration
      metadata
      mType
      mAvatarKey
      mTitle
      mSecId
      mDescription
      mdfId
      mUpdatedAt
      mCreatedAt
      mCreatedById
      mUpdatedById
      mPublishingAt
      mState
      mProperties {
        __typename
        ... on PlatformType {
          id
          platform
          platformKind
        }
      }
      mAssignedMembers {
        mId
      }
    }
  }
`;

const commonKanbanBoardReturnFields = `
  mId
  mRefId
  mOrder
  metadata
`;

export const CREATE_GRID = gql`
  mutation CreateGridBoard($input: CreateMemberInput) {
    createMember(input: $input) {
      ${commonKanbanBoardReturnFields}
    }
  }
`;

export const GET_GRID = gql`
  query GetGridBoard($input: GetMemberInput) {
    getMembersOf(input: $input) {
      ${commonKanbanBoardReturnFields}
    }
  }
`;

export const UPDATE_BOARD = gql`
  mutation UpdateGridBoard($input: UpdateMemberInput) {
    updateWidget(input: $input) {
      ${commonKanbanBoardReturnFields}
    }
  }
`;

export const SUBSCRIBE_TO_GRID = gql`
  subscription notifyMemberUpdate($mIdSubscribed: String!) {
    notifyMemberUpdateSubscription(mIdSubscribed: $mIdSubscribed) {
      ${commonKanbanBoardReturnFields}
      mType
      mTitle
      mDescription
      mUpdatedAt
      mPublishingAt
      mUpdatedById
      mdfId
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
