import { useCallback } from 'react';
import { useContextMenu } from 'react-contexify';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { ParsedMemberType } from 'types';

/** Enriches the table with additional functionality. */
export default function useGetTableMeta({ widgetId }: Readonly<{ widgetId: string }>) {
  const { show } = useContextMenu({ id: `gridItemMenu-${widgetId}` });

  const onContextMenu = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, member: ParsedMemberType) => {
      show({
        event,
        props: {
          member,
        },
      });
    },
    [show],
  );

  const { mdfs } = useGetMdfs({ all: true });

  return { meta: { onContextMenu, mdfs } };
}
