/* eslint-disable sort-imports */
// eslint-disable-next-line sort-imports
import { isEmpty } from 'lodash';

import { isDateRange } from 'api/search/useSearch';
import { getFieldKey, getFieldMap } from 'features/orderForm/utils';
import { AssignedMember } from 'types';
import { FieldValue, Metadata } from 'types/forms/forms';
import {
  DateRangeQL,
  FieldTypeEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  MemberType,
  MMetaDataField,
  RangeBy,
} from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { CommandToolbarProps, CsvKeyValue, isAccountType } from './command-types';

export const getMetadataValue = (metadata: MMetaDataField[] | null | undefined, key: string) => {
  if (!metadata) return '';
  return metadata.find((kv) => kv.key === key)?.value ?? '';
};

/**
 * CMDK will put all content in the data attribute
 * If there are quotes or newlines in there, dina crashes.
 */
export const safeForHTML = (html: string) => {
  return html?.replace(/['"]+/g, '');
};

export const getAccountTitle = (item: MemberType) => {
  if (isAccountType(item.mProperties?.account)) {
    return item.mProperties?.account.accountTitle;
  }
  return '';
};

export const getDateRange = (
  val: RangeBy | null,
): { dateRange: DateRangeQL; key: keyof RangeBy; label: string } | null => {
  if (!val) return null;
  if (val.createdAt) return { dateRange: val.createdAt, key: 'createdAt', label: 'Created' };
  if (val.scheduledAt) return { dateRange: val.scheduledAt, key: 'scheduledAt', label: 'Sched.' };
  if (val.updatedAt) return { dateRange: val.updatedAt, key: 'updatedAt', label: 'Upd.' };
  return null;
};

export const isFiltering = (state: CommandToolbarProps): boolean => {
  if (state.sortBy !== 'best') return true;
  if (state.rangeBy) return true;
  if (state.statusFilter.length > 0) return true;
  if (state.assignedIds.length > 0 || state.createdByIds.length > 0) return true;
  if (state.mTypes.length > 0) {
    return true;
  }
  return false;
};

const getPrettyValue = (
  value: FieldValue,
  allMembersKeyed: Record<string, AssignedMember>,
  field:
    | (MdfField & {
        formId: string;
        settings: LayoutSettings;
      })
    | undefined,
  fieldId: string,
) => {
  switch (field?.type) {
    case FieldTypeEnum.date: {
      if (isDateRange(value)) {
        return `${isoToLocaleShort(value.startDate)} - ${isoToLocaleShort(value.endDate)}`;
      }
      break;
    }
    case FieldTypeEnum.multiplechoice: {
      if (Array.isArray(value)) {
        return value.join(', ');
      }
      break;
    }
    case FieldTypeEnum.treechoice: {
      if (Array.isArray(value)) {
        return value.join(' ▸ ');
      }
      break;
    }
    case FieldTypeEnum.relation:
      return 'relation';
    case FieldTypeEnum.user: {
      if (typeof value === 'string') {
        const member = allMembersKeyed[value];
        if (member) {
          return member;
        }
      }
    }
  }
  return `${fieldId}: ${value as string}`;
};

export const getChipLabel = (
  key: string,
  value: FieldValue,
  allMembersKeyed: Record<string, AssignedMember>,
  field:
    | (MdfField & {
        formId: string;
        settings: LayoutSettings;
      })
    | undefined,
) => {
  const fieldId = `${field?.settings.label ?? key}`;
  if (field?.type === FieldTypeEnum.relation) {
    return fieldId;
  }
  return getPrettyValue(value, allMembersKeyed, field, fieldId);
};

export const generateCSVFromMembers = (items: MemberType[], mdfs: Mdf[]): CsvKeyValue[] => {
  const fieldMap = getFieldMap(mdfs, 'default');
  const data: CsvKeyValue[] = [];

  items.forEach((item) => {
    const metadata: Metadata = JSON.parse(item?.metadata ?? '{}') as Metadata;
    const mdfId = item?.mdfId;

    const dataItem: CsvKeyValue = {
      Id: item.mId ?? '',
      Title: item.mTitle ?? '',
      Description: item.mDescription ?? '',
      Created: item.mCreatedAt ?? '',
      Updated: item.mUpdatedAt ?? '',
      'Created by': item.mCreatedById ?? '',
      'Assigned to': item.mAssignedMembers?.map((a) => a.mId).join(', ') ?? '',
      Status: item.mState ?? '',
    };
    if (!isEmpty(metadata) && mdfId) {
      Object.entries(metadata).forEach(([key, value]) => {
        const field = fieldMap[`${getFieldKey(key, mdfId)}`];
        const label = field?.settings.label ?? key;
        dataItem[label] = value?.toString();
      });
    }

    data.push(dataItem);
  });

  return data;
};
