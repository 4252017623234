import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import isNull from 'lodash/isNull';

import initialValues from 'components/editor/constants/initialValues';
import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import variants from 'components/editor/constants/types/editorVariants';
import { UpdateInput } from 'components/editor/types';
import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import { useNotesMolecule } from 'features/notes/store';
import LOCK_MEMBER from 'operations/mutations/lockMember';
import UNLOCK_MEMBER from 'operations/mutations/unlockNote';
import { Asset, EditorValue, Note } from 'types';
import { LockMemberInput, MemberType, UnlockMemberInput } from 'types/graphqlTypes';
import { getAssetData, getFileAssetData } from 'utils/assetData';
import { uploadToS3 } from 'utils/s3Utils';

import useCreateAsset, { AssetInput } from '../../../hooks/useCreateAsset';
import useCustomMemo from '../../../hooks/useCustomMemo';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import useGetUser from '../../../hooks/useGetUser';
import useTextStorage from '../../../hooks/useTextStorage';
import useUpdateNote from '../api/useUpdateNote';

interface LockMemberInputType {
  input: LockMemberInput;
}
interface LockMemberResponse {
  lockMember: MemberType;
}
interface UnlockMemberInputType {
  input: UnlockMemberInput;
}
interface UnlockMemberResponse {
  unlockMember: MemberType;
}

const useNote = ({ entity, canUpdate }: { canUpdate: boolean; entity?: Note }) => {
  const { mId, mRefId, mContentKey, locked } = entity ?? {};

  /** editor state */
  const [content, setContent] = useState<EditorValue | null>(initialValues(variants.NOTES));
  const [shouldResetSelection, setShouldResetSelection] = useState(false);
  const [writeLock, setWriteLock] = useState(false);
  const [readLock, setReadLock] = useState(false);
  const [lockedByUser, setLockedByUser] = useState<string>('Someone');
  const [isSavingContent, setIsSavingContent] = useState(false);
  const [locking, setLocking] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const entityRef = useRef<Note | null>();
  const editorValueRef = useRef<EditorValue | null>(null);
  const writeLockRef = useRef(writeLock);
  const initialContentRef = useRef<EditorValue | null>(null);

  /** external store data */
  const { mId: currentUserId } = useContext(UserContext);
  const { restoreVersionFnRef } = useNotesMolecule();

  /** mutations */
  const [createAssetMutation] = useCreateAsset();
  const [unlockNote] = useMutation<UnlockMemberResponse, UnlockMemberInputType>(UNLOCK_MEMBER);
  const [lockNote] = useMutation<LockMemberResponse, LockMemberInputType>(LOCK_MEMBER);
  const { updateNote } = useUpdateNote();

  /** utils */
  const { toast } = useToast();
  const { getUserTitle } = useGetUser();

  const { data: textContent, loading, refetch } = useTextStorage(mContentKey!, !mContentKey);
  const memoizedTextContent = useCustomMemo(() => textContent, [textContent]);

  const createAsset = useCallback(
    async (assetInput: AssetInput) => {
      if (!entity?.mStoryId) return;
      const assetData = getAssetData(entity?.mStoryId, assetInput);
      const result = await createAssetMutation(entity?.mStoryId, assetData, true, undefined);

      return result;
    },
    [createAssetMutation, entity?.mStoryId],
  );

  const onAssetInsert = useCallback(
    async (file: File) => {
      if (!entity?.mStoryId) return;

      const assetData = getFileAssetData(entity.mStoryId, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
        src: '',
      };

      try {
        const result = await createAssetMutation(entity.mStoryId, assetData, false, undefined);
        const { createAssets: assets } = result.data as { createAssets: Asset[] };
        if (assets?.[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {
        toast({
          title: 'Error',
          description: `There was an error inserting the asset. ${JSON.stringify(e)}`,
          type: 'error',
        });
      }
      return sourceData;
    },
    [createAssetMutation, entity?.mStoryId, toast],
  );

  const onResetEditorValue = useCallback((newValue: EditorValue) => {
    if (newValue) {
      setContent({ ...newValue });
      editorValueRef.current = newValue;
      setShouldResetSelection(true);
    } else if (isNull(newValue)) {
      setContent(null);
      editorValueRef.current = null;
      setShouldResetSelection(true);
    }
  }, []);

  const getPlaceholderConfigs = useCallback(
    () => ({
      template: {},
      s3Content: null,
      variables: {},
    }),
    [],
  );

  const setInitialValue = useCallback(
    (initialContent?: EditorValue) => {
      const initialValue = initialContent ? { ...initialContent } : initialValues(variants.NOTES);

      onResetEditorValue(initialValue);
      initialContentRef.current = initialValue;
    },
    [onResetEditorValue],
  );

  const onUpdateLock = useCallback(
    (lockedId?: string | null) => {
      if (lockedId) {
        setWriteLock(lockedId === currentUserId);
        setReadLock(lockedId !== currentUserId);
        if (lockedId !== currentUserId) {
          const newLockedByUser = getUserTitle(lockedId) ?? 'Someone';
          setLockedByUser(newLockedByUser);
        }
      } else {
        window.requestAnimationFrame(() => {
          setWriteLock(false);
          setReadLock(false);
          setLockedByUser('');
        });
      }
    },
    [currentUserId, getUserTitle],
  );

  const onForceUnlock = useCallback(async () => {
    try {
      setLocking(true);
      if (!mId || !mRefId) return;
      onUpdateLock(null);
      const input: UnlockMemberInput = {
        mId,
        mRefId,
      };
      const result = await unlockNote({ variables: { input } });
      onUpdateLock(result?.data?.unlockMember.locked);
      setLocking(false);
    } catch (e) {
      onUpdateLock(null);
      setLocking(false);
    }
  }, [mId, mRefId, onUpdateLock, unlockNote]);

  const onLock = useCallback(async () => {
    try {
      if (!mId || !mRefId) return;
      setLocking(true);
      onUpdateLock(currentUserId);
      const input: LockMemberInput = {
        mId,
        mRefId,
        userId: currentUserId,
      };
      const result = await lockNote({ variables: { input } });
      onUpdateLock(result?.data?.lockMember.locked);
      setLocking(false);
      return result?.data?.lockMember.locked;
    } catch (error) {
      onUpdateLock(null);
      setLocking(false);
      return null;
    }
  }, [currentUserId, lockNote, mId, mRefId, onUpdateLock]);

  const onFocusEditor = useCallback(() => {
    if (canUpdate && !writeLock && !readLock && !locking && !loading) {
      onLock().then(
        () => {
          refetch();
        },
        () => {},
      );
    }
  }, [canUpdate, loading, locking, onLock, readLock, refetch, writeLock]);

  const saveContent = useCallback(
    async (newContent: EditorValue) => {
      const file = new window.File([JSON.stringify(newContent ?? {})], 'content.data', {
        type: 'text/plain',
      });

      await uploadToS3(mContentKey, file);
    },
    [mContentKey],
  );

  const onSavePress = useCallback(
    async (shouldReleaseLock = true) => {
      if (!entity?.mId || !entity?.mRefId || !mId || !mRefId) return;
      if (!loading && writeLockRef.current) {
        setIsSavingContent(true);

        if (editorValueRef.current) {
          await saveContent(editorValueRef.current);

          await updateNote(entity, {}, editorValueRef.current);
        }

        if (shouldReleaseLock) {
          onUpdateLock(null);
          const input: UnlockMemberInput = {
            mId,
            mRefId,
          };
          const unlockRes = await unlockNote({ variables: { input } });

          onResetEditorValue(editorValueRef.current as EditorValue);
          initialContentRef.current = editorValueRef.current;
          onUpdateLock(unlockRes.data?.unlockMember.locked);
        }

        setIsSavingContent(false);
      }
    },
    [
      entity,
      loading,
      mId,
      mRefId,
      onResetEditorValue,
      onUpdateLock,
      saveContent,
      unlockNote,
      updateNote,
    ],
  );

  const onDebouncedSave = () => onSavePress(false);

  const [debouncedSave, cancelDebouncedCallback] = useDebouncedCallback(onDebouncedSave, 15000);

  const onCancelPress = useCallback(async () => {
    if (!mId || !mRefId) return;

    setIsCancelled(true);
    cancelDebouncedCallback();
    onUpdateLock(null);
    const initialValue = isNull(initialContentRef.current)
      ? initialValues(variants.NOTES)
      : initialContentRef.current;
    onResetEditorValue(initialValue);

    if (editorValueRef.current) await saveContent(initialValue);

    const input: UnlockMemberInput = {
      mId,
      mRefId,
    };
    const unlockRes = await unlockNote({ variables: { input } });

    onUpdateLock(unlockRes.data?.unlockMember.locked);
    setIsCancelled(false);
  }, [
    cancelDebouncedCallback,
    mId,
    mRefId,
    onResetEditorValue,
    onUpdateLock,
    saveContent,
    unlockNote,
  ]);

  const onChangeContent = useCallback(
    (newContent: EditorValue) => {
      if (writeLockRef.current) {
        editorValueRef.current = newContent;
        debouncedSave()?.then(
          () => {},
          () => {},
        );
      }
    },
    [debouncedSave],
  );

  const onEditorUpdate = ({ type, payload }: UpdateInput) => {
    if (type === ActionTypesEnum.CHANGE) onChangeContent(payload);
    if (type === ActionTypesEnum.CREATE_ASSET) {
      const { asset } = payload;
      return createAsset(asset as AssetInput);
    }
    if (type === ActionTypesEnum.ASSET_INSERT) {
      const { file } = payload;
      return onAssetInsert(file);
    }
    return null;
  };

  const onRestoreVersion = useCallback(
    async (newContent: EditorValue) => {
      if (!entity?.mId || !entity?.mRefId || !newContent) return;

      const lockedId = locked ?? (await onLock());
      if (lockedId === currentUserId) {
        onUpdateLock(lockedId);
        onResetEditorValue(newContent);

        await updateNote(entity, {}, newContent);
        await saveContent(newContent);

        if (!locked) {
          /** unlock if it was previously unlocked */
          const input: UnlockMemberInput = {
            mId: entity.mId,
            mRefId: entity.mRefId,
          };
          const unlockRes = await unlockNote({ variables: { input } });
          onUpdateLock(unlockRes.data?.unlockMember.locked);
        }
      }
    },
    [
      currentUserId,
      entity,
      locked,
      onLock,
      onResetEditorValue,
      onUpdateLock,
      saveContent,
      unlockNote,
      updateNote,
    ],
  );

  const beforeunloadFn = useCallback((e?: BeforeUnloadEvent) => {
    e?.preventDefault();

    if (
      entityRef.current?.mId &&
      entityRef.current?.mRefId &&
      writeLockRef.current &&
      entityRef.current?.locked === currentUserId &&
      editorValueRef.current
    ) {
      setIsSavingContent(true);
      cancelDebouncedCallback();

      saveContent(editorValueRef.current).then(
        () => {},
        () => {},
      );

      updateNote(entityRef.current, {}, editorValueRef.current).then(
        () => {},
        () => {},
      );

      const input: UnlockMemberInput = {
        mId: entityRef.current.mId,
        mRefId: entityRef.current.mRefId,
      };
      unlockNote({ variables: { input } }).then(
        () => {},
        () => {},
      );

      setIsSavingContent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!mContentKey) {
      /** needed to override existing content for non existent entity */
      setInitialValue();
    } else if (memoizedTextContent && entityRef.current?.mContentKey === mContentKey) {
      /** update with new content */
      setInitialValue(memoizedTextContent);
    } else {
      setInitialValue();
    }
  }, [memoizedTextContent, mContentKey, setInitialValue]);

  useEffect(() => {
    if (entityRef.current?.locked && !entity?.locked) {
      // refetch the content on lock state change
      refetch();
    }
    entityRef.current = entity;
    onUpdateLock(entity?.locked);
  }, [entity, onUpdateLock, refetch]);

  useEffect(() => {
    restoreVersionFnRef.current = onRestoreVersion;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRestoreVersion]);

  useEffect(() => {
    writeLockRef.current = writeLock;
  }, [writeLock]);

  return {
    locked,
    loading,
    content,
    writeLock,
    readLock,
    lockedByUser,
    locking,
    isCancelled,
    isSavingContent,
    shouldResetSelection,
    onEditorUpdate,
    onSavePress,
    onCancelPress,
    onFocusEditor,
    beforeunloadFn,
    refetchContent: refetch,
    onForceUnlock,
    onRestoreVersion,
    getPlaceholderConfigs,
  };
};

export default useNote;
