import React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { HStack } from 'layouts/box/Box';

import { Label, Root } from './styled';

function Checked() {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="skipOverride"
    >
      <path
        // eslint-disable-next-line max-len
        d="M4.1288 7.89042L1.57344 5.31374C1.28289 5.02076 0.809236 5.02076 0.518686 5.31374C0.231529 5.60329 0.231529 6.07021 0.518685 6.35976L4.1288 10L12.4813 1.5778C12.7685 1.28825 12.7685 0.821329 12.4813 0.531776C12.1908 0.238802 11.7171 0.238802 11.4266 0.531776L4.1288 7.89042Z"
        fill="white"
      />
    </svg>
  );
}

function Indeterminate() {
  return (
    <svg width="12" height="2" viewBox="0 0 12 2" fill="white" xmlns="http://www.w3.org/2000/svg">
      <rect width="12" height="2" rx="1" fill="white" />
    </svg>
  );
}

interface CheckboxProps extends CheckboxPrimitive.CheckboxProps {
  indeterminate?: boolean;
}

const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ checked, indeterminate, ...rest }, ref) => {
    return (
      <Root checked={indeterminate ? 'indeterminate' : checked} {...rest} ref={ref}>
        <CheckboxPrimitive.Indicator asChild>
          {indeterminate ? <Indeterminate /> : <Checked />}
        </CheckboxPrimitive.Indicator>
      </Root>
    );
  },
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

interface CheckboxWithLabelProps extends CheckboxProps {
  label: string;
}
const CheckboxWithLabel = React.forwardRef<HTMLButtonElement, CheckboxWithLabelProps>(
  ({ label, ...rest }, ref) => {
    return (
      <HStack gap="4px">
        <Checkbox {...rest} ref={ref} id={label} />
        <Label htmlFor={label}>{label}</Label>
      </HStack>
    );
  },
);
CheckboxWithLabel.displayName = 'CheckboxWithLabel';

export { Checkbox, CheckboxWithLabel };
