import { memo } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { TableBody as Body } from 'components/dataTable/Table';

import { TableInstance } from '../types';

import DraggableRow from './DraggableRow';
import EmptyState from './EmptyState';
import RowCells from './RowCells';

interface TableBodyProps extends TableInstance {
  items: UniqueIdentifier[];
}

const TableBody = ({ tableInstance, items }: Readonly<TableBodyProps>) => {
  const tableMeta = tableInstance.options.meta;

  return (
    <Body>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {tableInstance.getRowModel().rows?.length ? (
          tableInstance.getRowModel().rows.map((row) => (
            <DraggableRow
              key={row.id}
              row={row}
              data-state={row.getIsSelected() && 'selected'}
              onContextMenu={tableMeta?.onContextMenu}
            >
              <RowCells tableInstance={tableInstance} row={row} />
            </DraggableRow>
          ))
        ) : (
          <EmptyState />
        )}
      </SortableContext>
    </Body>
  );
};

/** Memoized wrapper that is used during column resizing */
const MemoizedTableBody = memo(
  TableBody,
  (prev, next) => prev.tableInstance.options.data === next.tableInstance.options.data,
) as typeof TableBody;

export default function TableBodyExport({ tableInstance, items }: Readonly<TableBodyProps>) {
  return tableInstance.getState().columnSizingInfo.isResizingColumn ? (
    <MemoizedTableBody tableInstance={tableInstance} items={items} />
  ) : (
    <TableBody tableInstance={tableInstance} items={items} />
  );
}
