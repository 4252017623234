import { useMemo, useState } from 'react';

import { SearchParameters } from 'api/search/useSearch';
import { CommandToolbarProps } from 'components/command/command-types';
import QuickSearch from 'components/command/QuickSearch';
import { toolbarFilterDefaults } from 'components/command/toolbar/CommandToolbar';
import { MemberType } from 'types/graphqlTypes';

interface MemberSearchFieldProps {
  onSelect: (val: MemberType) => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  storedSearchParams?: SearchParameters;
  placeholderText?: string;
}

export default function MemberSearchField({
  storedSearchParams,
  placeholderText,
  autoFocus,
  onSelect,
  onBlur,
}: Readonly<MemberSearchFieldProps>) {
  const [searchString, setSearchString] = useState('');

  const toolbarState: CommandToolbarProps = useMemo(() => {
    const state = storedSearchParams?.toolbarState
      ? { ...storedSearchParams?.toolbarState }
      : {
          ...toolbarFilterDefaults,
        };
    return state;
  }, [storedSearchParams]);

  return (
    <QuickSearch
      doEmptySearches
      autoFocus={autoFocus}
      placeholderText={placeholderText}
      toolbarState={toolbarState}
      searchString={searchString}
      setSearchString={setSearchString}
      onKeyDown={() => {}}
      onBlur={onBlur}
      searchParams={storedSearchParams}
      titleErrorTooltip=""
      onSelect={onSelect}
    />
  );
}
