import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TableHead } from 'components/dataTable/Table';

export const StyledTableHead = styled(TableHead)<{ headerId?: string }>`
  ${({ headerId }) =>
    headerId &&
    css`
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      width: calc(var(--header-${headerId}-size) * 1px);
    `}
`;

export const SortHandle = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  gap: 4px;
`;
