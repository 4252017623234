import { memo } from 'react';

import Grid from 'features/gridWidget/GridWidget';
import WidgetWrapper from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';

import { WidgetProps } from './types';

type DataTableWidgetProps = Omit<WidgetProps, 'filters'>;

function DataTableWidget({ mId, ...wrapperProps }: Readonly<DataTableWidgetProps>) {
  return (
    <WidgetWrapper mId={mId} type={WIDGETS.TABLE} {...wrapperProps}>
      <Grid widgetId={mId} />
    </WidgetWrapper>
  );
}

export default memo(DataTableWidget);
