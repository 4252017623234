import { useCallback, useEffect, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { isPitch, isRundown, isRundownTemplate, isStory } from 'features/storyHub/utils';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import useDinaNavigate from './useDinaNavigate';
import useOpenAssetInPilotEdge from './useOpenAssetInPilotEdge';
import useOpenMimirItem from './useOpenMimirItem';

const GET_ASSET = gql`
  query GetAsset($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mType
      mAssetId
      mMetaData {
        key
        value
      }
      mosObj
    }
  }
`;

interface GetAsset {
  getMember: MemberType;
}
const GET_PARENT = gql`
  query GetParent($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mType
    }
  }
`;

interface GetParent extends GetAsset {}

const getProvider = (item: MemberType) =>
  item.mMetaData?.find((metaData) => metaData?.key === 'provider')?.value;

const useGetParentType = (mId?: string) => {
  const { data } = useQuery<GetParent>(GET_PARENT, {
    variables: {
      input: {
        mId,
        mRefId: mId,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !mId,
  });

  const mType = useMemo(() => data?.getMember?.mType, [data?.getMember?.mType]);

  return { mType };
};

const useGetDestination = (mId?: string, mRefId?: string) => {
  const { data } = useQuery<GetAsset>(GET_ASSET, {
    variables: {
      input: {
        mId,
        mRefId,
        mType: MemberTypeEnum.Asset,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !mId,
  });

  const provider = useMemo(
    () => data?.getMember?.mMetaData?.find((metaData) => metaData.key === 'provider')?.value,
    [data?.getMember?.mMetaData],
  );
  const mAssetId = useMemo(() => data?.getMember?.mAssetId, [data?.getMember?.mAssetId]);

  const mosObj = useMemo(() => data?.getMember?.mosObj, [data?.getMember?.mosObj]);

  return { provider, mAssetId, mosObj };
};

const useNavigateToDinaAsset = () => {
  const [id, setId] = useState<string | undefined>(undefined);

  const { navigateTo } = useDinaNavigate();
  const { mType } = useGetParentType(id);

  useEffect(() => {
    if (!mType) return;
    if (isStory(mType)) {
      navigateTo('story', id ?? '', {
        tab: 'assets',
      });
    }
    if (isPitch(mType)) {
      navigateTo('pitch', id ?? '', {
        tab: 'assets',
      });
    }
    if (isRundown(mType)) {
      navigateTo('rundown', id ?? '');
    }
    if (isRundownTemplate(mType)) {
      navigateTo('rundowntemplate', id ?? '');
    }
    setId(undefined);
  }, [id, mType, navigateTo]);

  const navigateToDina = (mId: string) => {
    setId(mId);
  };
  return navigateToDina;
};

const useNavigateToAsset = () => {
  const [asset, setAsset] = useState<MemberType | null>(null);

  const navigateToDina = useNavigateToDinaAsset();
  const { provider, mAssetId, mosObj } = useGetDestination(asset?.mId, asset?.mRefId);
  const openMimirItem = useOpenMimirItem();
  const openAssetInPilotEdge: (c: string) => void = useOpenAssetInPilotEdge();

  const openAssetByProvider = useCallback(
    (provider_: string, mAssetId_: string, mId: string, mosObj_?: string) => {
      switch (provider_.toLowerCase()) {
        case 'dina': {
          navigateToDina(mId);
          break;
        }
        case 'mimir': {
          openMimirItem(mAssetId_);
          break;
        }
        case 'ampp': {
          openMimirItem(mAssetId_, 'FLX');
          break;
        }
        case 'pilot':
        case 'xpression':
        case 'xpression.mos': {
          if (mosObj_) openAssetInPilotEdge(mosObj_);
          break;
        }
      }
    },
    [navigateToDina, openAssetInPilotEdge, openMimirItem],
  );

  useEffect(() => {
    if (provider) {
      openAssetByProvider(provider, mAssetId ?? asset?.mRefId ?? '', asset?.mId ?? '', mosObj);
      setAsset(null);
    }
  }, [asset, mAssetId, mosObj, openAssetByProvider, provider]);

  const openAsset = (item: MemberType) => {
    const providerFromItem = getProvider(item);
    if (providerFromItem) {
      openAssetByProvider(
        providerFromItem,
        item?.mAssetId ?? item.mRefId!,
        item.mId!,
        item?.mosObj,
      );
    } else {
      setAsset(item);
    }
  };
  return openAsset;
};

export default useNavigateToAsset;
