import { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import returnStates from 'screens/planning/components/status/utils/statusBoards/returnStates';
import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCheckPublishingPermission from 'hooks/useCheckPublishingPermission';
import variants from 'utils/instance/variants';
import configCtx from 'contexts/configContext';
import kanbanDefaultState from 'utils/constants/kanbanDefaultState';
import kanbanStateForPublishing from 'utils/constants/kanbanStateForPublishing';
import Tooltip from 'components/tooltip';
import Icon from './components/icon';
import useStyles from './status-selector-styles';

const renderSelectIcon = () => null;

const getViewName = (variant) => {
  if (variant === variants.LINEAR) return 'status-linear';
  if (variant !== variants.LINEAR) return 'status-SoMe';
  return '';
};

const StatusSelectorView = ({
  variant,
  statusId,
  onChange,
  iconPosition,
  anchorOrigin,
  transformOrigin,
  disableEdit,
  publishingPoint,
  accountIdentifier,
}) => {
  const { kanbanBoardStates = [], kanbanBoardViews = [] } = useContext(configCtx);
  const classes = useStyles({ iconPosition });
  const [restrictedPlatform] = useCheckPublishingPermission();
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const state = kanbanBoardStates.find((s) => s.id === statusId) || kanbanDefaultState;
  const { icon, name: statusName } = state;

  const states = returnStates(getViewName(variant), kanbanBoardStates, kanbanBoardViews);

  const [checkUserRight] = useCheckUserRight();
  const hasPermissionForKanbanState = checkUserRight('state', state?.id);
  const hasPermissionForPlatform = checkUserRight('platform', accountIdentifier);
  const canApprovePublishing = checkUserRight('instance', 'approve') && hasPermissionForPlatform;

  const tooltipTitle = hasPermissionForKanbanState
    ? 'Status'
    : 'Sorry, you don’t have access to make changes to this workflow state.';

  const openSelectMenu = () => {
    setIsOpen(true);
  };

  const closeSelectMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setShowTooltip(false);
  }, [isOpen]);

  const handleChange = (event) => onChange(event.target.value);

  const stopPropagation = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const canPublishToPlatform = () =>
    restrictedPlatform(publishingPoint) ? canApprovePublishing : true;
  const restrictedState = (stateId) => kanbanStateForPublishing(stateId) && !canPublishToPlatform();

  const renderSelectOption = ({ id, name }) => (
    <MenuItem
      value={id}
      key={id}
      disabled={disableEdit || restrictedState(id) || !checkUserRight('state', id)}
      classes={{
        root: classes.menuItem,
        selected: classes.menuItemSelected,
      }}
    >
      {name}
    </MenuItem>
  );

  return (
    <Tooltip title={tooltipTitle} open={showTooltip} onOpenChange={(val) => setShowTooltip(val)}>
      <div role="presentation" className={classes.root} onClick={openSelectMenu}>
        <div className={classes.statusIcon}>
          <Icon status={icon} />
        </div>

        {iconPosition === 'start' && (
          <div className={classes.selectIcon}>
            <ArrowDoubleIcon />
          </div>
        )}
        {disableEdit || !hasPermissionForKanbanState ? (
          <div className={classes.statusName}>{statusName}</div>
        ) : (
          <div
            ref={containerRef}
            role="presentation"
            onKeyDown={stopPropagation}
            onClick={stopPropagation}
            className={classes.selectWrapper}
          >
            <Select
              disableUnderline
              open={isOpen}
              onOpen={openSelectMenu}
              onClose={closeSelectMenu}
              IconComponent={renderSelectIcon}
              value={statusId}
              onChange={handleChange}
              className={classes.inputBase}
              classes={{ root: classes.select }}
              MenuProps={{
                classes: {
                  paper: classes.menu,
                },
                anchorEl: containerRef.current,
                getContentAnchorEl: null,
                anchorOrigin,
                transformOrigin,
              }}
              inputProps={{
                classes: {
                  root: classes.input,
                },
              }}
              variant="standard"
            >
              {states.map(renderSelectOption)}
            </Select>
          </div>
        )}

        {iconPosition === 'end' && (
          <div className={classes.selectIcon}>
            <ArrowDoubleIcon />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

StatusSelectorView.propTypes = {
  /** Variant of the instance */
  variant: PropTypes.string,
  /** Current status id of the instance */
  statusId: PropTypes.string,
  /** Position of the arrow icons */
  iconPosition: PropTypes.oneOf(['start', 'end']),
  /** Anchor origin for Material UI popover */
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Transform origin for Material UI popover */
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Callback to be invoked on item selection */
  onChange: PropTypes.func,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
};

StatusSelectorView.defaultProps = {
  variant: variants.GENERAL,
  statusId: 'todo',
  iconPosition: 'end',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  onChange: (newStatusId) => {},
  disableEdit: false,
};

export default StatusSelectorView;
