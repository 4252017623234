import { useMemo } from 'react';

import { Table as TableBase } from 'components/dataTable/Table';
import { TableInstance } from 'features/dataTable/types';

interface DataTableProps extends TableInstance {
  children: React.ReactNode;
}

export default function Table({ children, tableInstance }: Readonly<DataTableProps>) {
  // -- Calculate all column sizes for the table
  const columnSizeVars = useMemo(() => {
    const headers = tableInstance.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (const element of headers) {
      const header = element;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
  }, [
    tableInstance.getState().columnSizingInfo,
    tableInstance.getState().columnSizing,
    tableInstance.getFlatHeaders(),
  ]);

  return (
    <TableBase style={{ ...columnSizeVars, width: tableInstance.getTotalSize() }}>
      {children}
    </TableBase>
  );
}
