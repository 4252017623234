import styled from '@emotion/styled';

import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from 'lib/dropdown';
import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const Content = styled(DropdownMenuContent)`
  z-index: 1000;
  min-width: 260px;
  overflow: hidden;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  ${dialogBoxShadow};
  animation: 0.2s ease-in-out 0s 1 normal none running animate-in;
  animation: 0.2s ease-in-out 0s 1 normal none running fade-out-0;
  animation: 0.2s ease-in-out 0s 1 normal none running zoom-out-95;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-top-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-right-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-left-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-bottom-2;
`;

export const Label = styled(DropdownMenuLabel)`
  ${({ theme }) => theme.typography.dina.h7};
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

export const Separator = styled(DropdownMenuSeparator)<{ horizontalInset?: boolean }>`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
  margin-left: ${({ horizontalInset }) => (horizontalInset ? '0' : '-12px')};
  margin-right: ${({ horizontalInset }) => (horizontalInset ? '0' : '-12px')};
  margin-bottom: 8px;
`;

export const MenuItem = styled(DropdownMenuItem)`
  height: 32px;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  label {
    display: flex;
    align-items: center;
  }

  :active {
    outline: none;
  }
  :focus {
    outline: none;
  }
`;

export const Checkbox = styled('input')`
  height: 18px;
  width: 18px;
  margin-right: 8px;
`;
