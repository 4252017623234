import { useEffect, useMemo } from 'react';

import Editor from 'components/editor';
import { Update } from 'components/editor/types';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import MetadataComponent from 'components/metadata';
import SplitBar from 'components/split';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import { VStack } from 'layouts/box/Box';
import { useStoryToolbar } from 'screens/storyV2/store/toolbar';
import { useUsers } from 'store';
import { MMetaDataField } from 'types/graphqlTypes';
import clickIfNothingSelected from 'utils/clickIfNothingSelected';
import { getUserLockToken } from 'utils/lock/lockToken';

import useInstanceCore from '../hooks/useInstanceCore';
import useInstanceMdf from '../hooks/useInstanceMdf';
import useInstanceMetadata from '../hooks/useInstanceMetadata';
import useInstancePermissions from '../hooks/useInstancePermissions';
import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import useLoadAndSetContent from '../hooks/useLoadAndSetContent';
import { useInstanceMolecule } from '../store/instance';
import { EditorContentWrapper, MetadataHeader } from '../styled';

import EmbedView from './embedView';

const Body = () => {
  const {
    useInstanceValue,
    useDisableEdit,
    usePlatformVariant,
    useWriteLock,
    useReadLock,
    useView,
    instanceRef,
    editorValueRef,
  } = useInstanceMolecule();
  const { canShowNewDesign, canShowCmsIframe, canUploadMediaBySignedURL } =
    useInstancePermissions();
  const {
    loading,
    handleEditorUpdate,
    editorValue,
    handleLockInstance,
    cancelDebounce,
    saveInstanceWithContent,
    userId,
    onMetadataChanged,
  } = useInstanceCore();
  const {
    showMetadata,
    thumbnail,
    isCMSInstance,
    onCmsEditingClick,
    onSetEditor,
    placeholder,
    textDirection,
    variant,
    isCmsBlock,
    getPlaceholderConfigs,
    showFacebookEmbed,
    showTwitterEmbed,
    showInstagramEmbed,
    showYoutubeEmbed,
    resetSelection,
  } = useInstanceViewUtils();
  const { mdf, metadata, errorMap, updateFieldValues } = useInstanceMdf();
  const { errorToast } = useToast();
  const { hostReadSpeed, fields, pairedMetadata, parameterFields } = useInstanceMetadata();

  const [users] = useUsers();
  const instance = useInstanceValue();
  const [disableEdit] = useDisableEdit();
  const [platformVariant] = usePlatformVariant();
  const [{ editorFontSize }] = useStoryToolbar();
  const [writeLock] = useWriteLock();
  const [readLock] = useReadLock();
  const [view] = useView();

  useLoadAndSetContent();

  useEffect(() => {
    resetSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writeLock]);

  useEffect(() => {
    const unlockBeforeUnload = (e?: BeforeUnloadEvent) => {
      if (e) {
        e.preventDefault();
        delete e.returnValue;
      }

      if (instanceRef.current?.locked !== getUserLockToken(userId)) {
        return;
      }

      cancelDebounce();

      const params = {
        instance: { ...instanceRef.current },
        unlock: true,
        audit: { source: 'instanceBody:unlockBeforeUnload' },
        ...(editorValueRef.current && { content: editorValueRef.current }),
      };
      saveInstanceWithContent(params).then(
        () => {},
        () => {},
      );
    };

    window.addEventListener('beforeunload', (e) => {
      unlockBeforeUnload(e);
    });

    return () => {
      unlockBeforeUnload();
      window.removeEventListener('beforeunload', (e) => {
        unlockBeforeUnload(e);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedEditor = useMemo(
    () => (
      <Editor
        height="100%"
        readOnly={!writeLock}
        update={handleEditorUpdate as Update}
        thumbnail={thumbnail}
        platformStructure={platformVariant?.platformStructure}
        value={editorValue}
        isAllowed={canShowNewDesign || (isCMSInstance && canShowCmsIframe)}
        onCmsEditing={onCmsEditingClick}
        setEditor={onSetEditor}
        placeholder={placeholder}
        withSignedUrl={canUploadMediaBySignedURL}
        direction={textDirection}
        users={users}
        variant={variant}
        hostReadSpeed={hostReadSpeed}
        isPublished={!!instance?.mPublishingAt}
        isCmsBlock={isCmsBlock}
        editorFontSize={editorFontSize}
        getPlaceholderConfigs={getPlaceholderConfigs}
        platformId={platformVariant?.id}
        platformKind={instance?.mProperties?.platformKind}
        renderToolbar={disableEdit ? () => null : undefined}
      />
    ),
    [
      writeLock,
      handleEditorUpdate,
      thumbnail,
      platformVariant?.platformStructure,
      platformVariant?.id,
      editorValue,
      canShowNewDesign,
      isCMSInstance,
      canShowCmsIframe,
      onCmsEditingClick,
      onSetEditor,
      placeholder,
      canUploadMediaBySignedURL,
      textDirection,
      users,
      variant,
      hostReadSpeed,
      instance?.mPublishingAt,
      instance?.mProperties?.platformKind,
      isCmsBlock,
      editorFontSize,
      getPlaceholderConfigs,
      disableEdit,
    ],
  );

  const content =
    view === 'edit' ? (
      renderedEditor
    ) : (
      <EmbedView
        showTwitterEmbed={showTwitterEmbed}
        showYoutubeEmbed={showYoutubeEmbed}
        showFacebookEmbed={showFacebookEmbed}
        showInstagramEmbed={showInstagramEmbed}
        provider={instance?.mProperties?.provider}
        readLock={readLock}
        writeLock={writeLock}
      />
    );

  return (
    <SplitBar
      style={{ flex: '1 1 0' }}
      split="vertical"
      primary="second"
      pane1Style={{
        minWidth: showMetadata ? '40%' : '100%',
        maxWidth: showMetadata ? '70%' : '100%',
        display: 'flex',
        flex: 1,
      }}
      pane2Style={{
        minWidth: showMetadata ? '30%' : '0%',
        maxWidth: showMetadata ? '60%' : '0%',
        display: 'flex',
      }}
      hideResizer={!showMetadata}
    >
      <EditorContentWrapper
        role="presentation"
        onClick={(ev) => clickIfNothingSelected(ev, handleLockInstance)}
        $disabled={loading}
      >
        {content}
      </EditorContentWrapper>
      {mdf ? (
        <VStack width="100%" alignItems="start" justifyContent="start">
          <MetadataHeader>
            <Text variant="h7">Metadata</Text>
          </MetadataHeader>
          <MdfEditor
            style={{ padding: '10px', overflow: 'auto' }}
            fields={mdf.fields}
            defaultLayoutSettings={mdf.views.default}
            layoutSettings={[]}
            metadata={metadata ?? {}}
            permissions={mdf.permissions}
            view="default"
            errorMap={errorMap}
            updateFieldValue={updateFieldValues}
          />
        </VStack>
      ) : (
        <MetadataComponent
          fields={fields}
          metadata={pairedMetadata}
          parameterFields={parameterFields}
          onUpdateMeta={(newMetadata: MMetaDataField[]) => {
            if (instance) {
              onMetadataChanged(newMetadata, instance).catch(errorToast);
            }
          }}
          disableEdit={disableEdit}
          onBackClick={undefined}
          usage={undefined}
        />
      )}
    </SplitBar>
  );
};

export default Body;
