import gql from 'graphql-tag';

export default gql`
  fragment currentInstance on MemberType {
    mId
    mMetaData {
      key
      value
      manual
      autoValue
    }
    mProperties {
      __typename
      ... on PlatformType {
        platform
        platformKind
        account {
          accountUrl
          accountLogo
          accountTitle
          accountId
          accountRefId
          orderType
        }
        provider {
          id
          requestId
          state
          eventTime
          startTime
          stopTime
          message
        }
      }
    }
  }
`;
