import { useMemo } from 'react';
import { flexRender, Row } from '@tanstack/react-table';

import { TableCell } from 'components/dataTable/Table';
import useMetadata from 'hooks/useMetadata';
import { useSetPreview } from 'store/preview';
import { Mdf } from 'types/graphqlTypes';
import { ParsedMemberType } from 'types/members';

import { TableInstance } from '../types';

export interface RowProps extends TableInstance {
  row: Row<ParsedMemberType>;
}

/** Render the row cells */
export default function RowCells({ row, tableInstance }: Readonly<RowProps>) {
  const setPreview = useSetPreview();

  const mdfs = tableInstance.options.meta?.mdfs ?? [];

  const mdf: Mdf | undefined = useMemo(() => {
    return mdfs.find((m) => m.id === row.original.mdfId);
  }, [row.original.mdfId]);

  const { metadata, errorMap, updateFieldValues } = useMetadata(
    mdf,
    row.original.metadata,
    'default',
    row.original,
  );

  return (
    <>
      {row.getVisibleCells().map((cell) => {
        const split = cell.column.id.split('_');
        const val = split.length > 0 ? split[split.length - 1] : undefined;
        return (
          <TableCell
            key={cell.id}
            style={{ width: `calc(var(--col-${cell.column.id}-size) * 1px)` }}
          >
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              errorValue: errorMap[val ?? ''],
              updateFieldValues,
              metadata,
              mdf,
              setPreview,
            })}
          </TableCell>
        );
      })}
    </>
  );
}
