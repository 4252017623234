/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sort-imports */
import React, { useCallback, useEffect, useState } from 'react';
import { ApolloClient } from '@apollo/client';
import { atom, useAtom } from 'jotai';
import { groupBy } from 'lodash';

import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import useDeleteOrder, { OrderDeleteKeys } from 'components/orderFormDialog/api/useDeleteOrder';
import Switch from 'components/switch/Switch';
import useToast from 'components/toast/useToast';
import OrderGrid from 'features/orderForm/components/OrderGrid';
import useApolloSubscription from 'hooks/useApolloSubscription';
import { Flex } from 'layouts/box/Box';
import { UPDATED_ORDERS } from 'operations/subscriptions/notifyOrderUpdate';
import {
  removeOrderFromCache,
  removeOrderFromCacheByType,
  useGetOrdersAndForms,
  writeOrderToCache,
  writeOrderToCacheByType,
} from 'screens/space/api/useGetOrdersAndForms';
import { Order, RawOrder } from 'types/forms/forms';
import { CrudActionEnum, GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import {
  HeaderSection,
  HeaderWrapper,
  OrderGridFullWrapper,
  Switches,
  SwitchLabel,
} from '../styled';

/**
 * resourceId can include:
 * resourceId eg the mId of a resource the orders are tied to
 * spaceId eg the mId of the space orders are tied to (where the forms live)
 * assigneeId who is assigned to the order
 * ownerId who owns the order
 */
interface Props {
  resourceId: string;
  resourceType: GetOrderEnum;
  closeDialog?: () => void;
  headerSlot?: React.ReactElement;
  doSubscribe?: boolean;
  hideCompletedSwitch?: boolean;
}

interface SubscriptionEvent {
  data: {
    notifyOrderUpdateSubscription: RawOrder;
  };
}

type SwitchValues = 'grouped' | 'ungrouped';

const deleteOrderState = atom<OrderDeleteKeys | null>(null);
export const useDeleteOrderAtom = () => useAtom(deleteOrderState);

export const getActiveFromOrder = (order: Pick<Order, 'mActive'>): TaskStatusEnum => {
  return order.mActive ? TaskStatusEnum.active : TaskStatusEnum.inactive;
};

export const getTaskStatus = (
  showCompleted: boolean,
  resourceType: GetOrderEnum,
): TaskStatusEnum => {
  if (resourceType === GetOrderEnum.Resource) return TaskStatusEnum.all;
  return showCompleted ? TaskStatusEnum.inactive : TaskStatusEnum.active;
};

const OrderGridFull: React.FC<Props> = ({
  resourceType,
  resourceId,
  closeDialog,
  headerSlot,
  doSubscribe = true,
  hideCompletedSwitch = false,
}): React.JSX.Element | null => {
  const [showCompleted, setShowCompleted] = useState(false);
  const { orders, mdfs, loading } = useGetOrdersAndForms(
    resourceId,
    resourceType,
    getTaskStatus(showCompleted, resourceType),
  );

  const [selected, setSelected] = useState<SwitchValues>('ungrouped');
  const { errorToast } = useToast();
  const [showFilters, setShowFilters] = useState(false);
  const [groupedOrders, setGroupedOrders] = useState<Record<string, Order[]>>({});
  const [orderToDelete, setOrderToDelete] = useDeleteOrderAtom();

  const { deleteOrder } = useDeleteOrder();

  const [subscribe, unsubscribe] = useApolloSubscription(UPDATED_ORDERS, {
    variables: { mIdSubscribed: resourceId },
    onSubscriptionData: (data: {
      subscriptionData: SubscriptionEvent;
      client: ApolloClient<object>;
    }) => {
      const { subscriptionData, client } = data;
      const order = subscriptionData.data.notifyOrderUpdateSubscription;
      const status = getActiveFromOrder(order);
      switch (order.crudAction) {
        case CrudActionEnum.Remove:
          removeOrderFromCache(client, resourceId, order.mId, status);
          break;
        case CrudActionEnum.Insert:
          writeOrderToCache(client, resourceId, order, status, CrudActionEnum.Insert);
          break;
        case CrudActionEnum.Modify:
          switch (resourceType) {
            case GetOrderEnum.Form:
              if (order.mFormId === resourceId && status === TaskStatusEnum.active) {
                writeOrderToCacheByType(
                  client,
                  resourceId,
                  order,
                  resourceType,
                  TaskStatusEnum.active,
                  CrudActionEnum.Insert,
                );
              } else if (order.mFormId === resourceId && status === TaskStatusEnum.inactive) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
              }
              break;
            case GetOrderEnum.Space:
              if (order.mSpaceId === resourceId && status === TaskStatusEnum.active) {
                writeOrderToCacheByType(
                  client,
                  resourceId,
                  order,
                  resourceType,
                  TaskStatusEnum.active,
                  CrudActionEnum.Insert,
                );
              } else if (order.mSpaceId === resourceId && status === TaskStatusEnum.inactive) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
              }
              break;
            case GetOrderEnum.Owner:
              if (order.mOwner !== resourceId) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.inactive,
                );
              } else if (order.mOwner === resourceId && status === TaskStatusEnum.active) {
                writeOrderToCacheByType(
                  client,
                  resourceId,
                  order,
                  resourceType,
                  TaskStatusEnum.active,
                  CrudActionEnum.Insert,
                );
              } else if (order.mOwner === resourceId && status === TaskStatusEnum.inactive) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
              }
              break;
            case GetOrderEnum.Assignee:
              if (order.mAssignee !== resourceId) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.inactive,
                );
              } else if (order.mAssignee === resourceId && status === TaskStatusEnum.active) {
                writeOrderToCacheByType(
                  client,
                  resourceId,
                  order,
                  resourceType,
                  TaskStatusEnum.active,
                  CrudActionEnum.Insert,
                );
              } else if (order.mAssignee === resourceId && status === TaskStatusEnum.inactive) {
                removeOrderFromCacheByType(
                  client,
                  resourceId,
                  order.mId,
                  resourceType,
                  TaskStatusEnum.active,
                );
              }
              break;
          }
      }
    },
  });

  /**
   * The dynamic nature of updating cache based on resourceId/resourceType requires
   * us to resubscribe to ensure changes to these 2 variables are handled by the subscription
   * call back.
   */
  useEffect(() => {
    if (resourceId) subscribe();

    return () => unsubscribe();
  }, [resourceId, resourceType]);

  useEffect(() => {
    if (doSubscribe && resourceId) subscribe();
    return () => {
      setOrderToDelete(null);
      unsubscribe();
    };
  }, [doSubscribe, resourceId]);

  useEffect(() => {
    if (selected !== 'grouped' || (orders.length === 0 && Object.keys(groupedOrders).length === 0))
      return;
    const groupByFormId: Record<string, Order[]> = groupBy(orders, (order) => order.mFormId);
    setGroupedOrders(groupByFormId);
  }, [orders, selected]);

  const toggleGrouped = useCallback(() => {
    setSelected(selected === 'grouped' ? 'ungrouped' : 'grouped');
  }, [selected]);

  const closeDeleteDialog = useCallback(() => {
    setOrderToDelete(null);
  }, []);

  const handleDeleteOrder = useCallback(
    async (deleteKeys: OrderDeleteKeys | null) => {
      if (deleteKeys) {
        await deleteOrder(deleteKeys).catch(errorToast);
        closeDeleteDialog();
      }
    },
    [deleteOrder, setOrderToDelete, closeDeleteDialog],
  );

  // We force display a single grid of no orders when using grouped by type
  const noOrders = orders.length === 0 && Object.keys(groupedOrders).length === 0;

  return (
    <OrderGridFullWrapper>
      <HeaderSection>
        <HeaderWrapper>{headerSlot}</HeaderWrapper>
        <Switches>
          {!hideCompletedSwitch && (
            <>
              <Switch
                selected={showCompleted}
                onClick={() => setShowCompleted((prev) => !prev)}
                disabled={false}
              />
              <SwitchLabel onClick={() => setShowCompleted((prev) => !prev)}>
                View completed
              </SwitchLabel>
            </>
          )}

          <Switch
            selected={selected === 'grouped'}
            onClick={() => toggleGrouped()}
            disabled={false}
          />
          <SwitchLabel onClick={() => toggleGrouped()}>Group by type</SwitchLabel>
          <Switch
            selected={showFilters}
            onClick={() => setShowFilters(!showFilters)}
            disabled={false}
          />
          <SwitchLabel onClick={() => setShowFilters(!showFilters)}>Show filters</SwitchLabel>
        </Switches>
      </HeaderSection>
      <Flex flex="1" flexDirection="column" gap="8px">
        {((mdfs && selected == 'ungrouped') || noOrders) && (
          <OrderGrid
            loading={loading}
            formId="default_formId"
            showFilters={showFilters}
            orders={orders}
            mdfs={mdfs ?? []}
            closeDialog={closeDialog}
            maxHeight={500}
          />
        )}
        {mdfs &&
          selected == 'grouped' &&
          Object.keys(groupedOrders).map((key) => (
            <OrderGrid
              loading={loading}
              formId={key}
              showFilters={showFilters}
              key={key}
              maxHeight={200}
              orders={groupedOrders[key]}
              mdfs={mdfs}
              closeDialog={closeDialog}
            />
          ))}
      </Flex>
      <DeleteDialog
        open={Boolean(orderToDelete)}
        onClose={closeDeleteDialog}
        onClick={() => handleDeleteOrder(orderToDelete)}
        title="Delete order?"
        message="Are you sure you want to delete this order? This cannot be undone!"
      />
    </OrderGridFullWrapper>
  );
};

export default OrderGridFull;
