import { useUpdateBoardColumns } from 'api/gridAndKanban/useUpdateBoardColumns';
import { MemberContextMenu } from 'components/contextMenu/MemberContextMenu';
import DataTable from 'features/dataTable/DataTable';
import MemberDropzone from 'features/dnd/MemberDropzone';

import useGetColumnDefinitions from './hooks/useGetColumnDefinitions';
import useGetData from './hooks/useGetData';
import useGetTableMeta from './hooks/useGetTableMeta';

/** This component initializes the table with data and columns */
export default function GridWidget({ widgetId }: Readonly<{ widgetId: string }>) {
  const { error, itemGroupId, items, itemIds } = useGetData(widgetId);
  const { columnDefinitions } = useGetColumnDefinitions(items, widgetId, itemGroupId);
  const { meta } = useGetTableMeta({ widgetId });
  const { addMember } = useUpdateBoardColumns(widgetId);

  if (error) return <div>Error</div>;

  return (
    <>
      <MemberDropzone itemGroupId={itemGroupId} itemIds={itemIds} onDrop={addMember}>
        <DataTable
          columnDefinitions={columnDefinitions}
          data={items}
          itemGroupId={itemGroupId}
          itemIds={itemIds}
          meta={meta}
          tableId={widgetId}
        />
      </MemberDropzone>
      <MemberContextMenu menuId={`gridItemMenu-${widgetId}`} domainItems={[]} />
    </>
  );
}
