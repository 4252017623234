import ReorderWrapper from './components/ReorderWrapper';
import Table from './components/Table';
import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import { useDataTable } from './hooks/useDataTable';
import { type DataTableProps } from './types';

export default function DataTable({
  tableId,
  itemGroupId,
  data,
  columnDefinitions,
  itemIds,
  meta,
}: Readonly<DataTableProps>) {
  // -- Create table instance
  const { table } = useDataTable({
    data,
    columns: columnDefinitions,
    meta,
  });

  return (
    <ReorderWrapper memberIds={itemIds} tableId={tableId} itemGroupId={itemGroupId}>
      <Table tableInstance={table}>
        <TableHeader tableInstance={table} />
        <TableBody tableInstance={table} items={itemIds} />
      </Table>
    </ReorderWrapper>
  );
}
