import Dialog from 'components/dialog';
import { initialValues } from 'components/editor/constants';
import variants from 'components/editor/constants/types/editorVariants';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import useTextStorage from 'hooks/useTextStorage';
import { Box } from 'layouts/box/Box';
import { Story } from 'types';

import ContentPrintDoc from './docs/ContentPrintDoc';

import { ErrorWrapper, LoadingWrapper, PrintViewWrapper } from './styled';

interface Props {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  story: Story;
}

const View = ({ story }: { story: Story }) => {
  const { data, loading, error } = useTextStorage(story.mContentKey);

  const initialValue = initialValues(variants.GENERAL);

  if (loading)
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (error)
    return (
      <ErrorWrapper>
        <Box>Encountered following error while printing</Box>
        <Box>
          <pre>{JSON.stringify(error)}</pre>
        </Box>
      </ErrorWrapper>
    );

  return (
    <PrintViewWrapper>
      <PdfViewer>
        <ContentPrintDoc content={data ?? initialValue} story={story} />
      </PdfViewer>
    </PrintViewWrapper>
  );
};

const ContentPrint = ({ isDialogOpen, onCloseDialog, story }: Props) => {
  return (
    <Dialog container={undefined} open={isDialogOpen} onClose={onCloseDialog}>
      <View story={story} />
    </Dialog>
  );
};

export default ContentPrint;
