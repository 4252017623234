import { useCallback, useMemo, useState } from 'react';
import ReactSplitPane from 'react-split-pane';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as LeftArrow } from 'assets/icons/systemicons/disclosurearrow_left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/systemicons/disclosurearrow_right.svg';
import { HStack } from 'layouts/box/Box';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';

export const minSize = 48;

export const CollapseLine = styled('div')`
  ${({ theme }) => css`
    cursor: pointer;
    display: inline-flex;
    width: 16px;
    height: 100%;
    border-right: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
    &:hover {
      background: ${theme.palette.dina.hoveringTooltip};
    }
  `}
`;

export const SplitPane = styled(ReactSplitPane)`
  height: 100%;
  .Resizer {
    box-sizing: border-box;
    background-clip: padding-box;
    z-index: 100;
    cursor: col-resize;
    transition: all 150ms ease-in-out;
    :before {
      content: '';
      width: 2px;
      height: 100%;
      display: block;
      text-align: center;
      background-color: ${({ theme }) => theme.palette.dina.dividerLight};
    }
    :hover,
    :focus {
      ${({ theme }) => css`
        background-color: ${theme.palette.dina.onFocus};
        box-shadow: 0px 0px 0px 1px ${theme.palette.dina.onFocus};
      `}
    }
  }
`;

type PaneProps =
  | {
      pane1: React.ReactElement;
      pane2: React.ReactElement;
    }
  | {
      pane1: React.ReactElement;
      pane2: null;
    }
  | {
      pane1: null;
      pane2: React.ReactElement;
    };

const arrowStyle = { margin: 'auto' };

export function LeftCollapsibleSplitPane({ pane1, pane2 }: PaneProps) {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = useCallback(() => setCollapsed((pre) => !pre), []);

  const pane2WithCollapse = useMemo(() => {
    return (
      <HStack width="100%" height="100%">
        <CollapseLine onClick={toggleCollapse}>
          {collapsed ? <RightArrow style={arrowStyle} /> : <LeftArrow style={arrowStyle} />}
        </CollapseLine>
        {pane2}
      </HStack>
    );
  }, [collapsed, pane2, toggleCollapse]);

  return (
    // @ts-expect-error: TS2322 because the library hasn't fixed it yet https://github.com/tomkp/react-split-pane/issues/826
    <SplitPane
      style={{ position: 'relative' }}
      split="vertical"
      pane1Style={{
        minWidth: collapsed ? '0%' : '340px',
        maxWidth: collapsed ? '0%' : 'calc(100% - 560px)',
      }}
      pane2Style={{
        minWidth: collapsed ? '100%' : '560px',
        maxWidth: collapsed ? '100%' : 'calc(100% - 340px)',
        display: 'flex',
        flex: 1,
      }}
      defaultSize={collapsed ? '100%' : '323px'}
      primary="first"
      allowResize={!!(pane1 && pane2)}
    >
      {pane1}
      {pane2WithCollapse}
    </SplitPane>
  );
}

export function RightCollapsibleSplitPane({
  pane1,
  pane2,
  hidden,
}: PaneProps & { hidden?: boolean }) {
  const { useShowSidePanel } = useStoryPaneMolecule();
  const [showSidepanel, setShowSidepanel] = useShowSidePanel();
  const toggleCollapse = useCallback(() => setShowSidepanel((prev) => !prev), []);

  const pane1WithCollapse = useMemo(() => {
    return (
      <HStack width="100%" height="100%">
        {pane1}
        <CollapseLine onClick={toggleCollapse}>
          {showSidepanel ? <RightArrow style={arrowStyle} /> : <LeftArrow style={arrowStyle} />}
        </CollapseLine>
      </HStack>
    );
  }, [showSidepanel, pane1, toggleCollapse]);

  if (hidden) return pane1;

  return (
    // @ts-expect-error: TS2322 because the library hasn't fixed it yet https://github.com/tomkp/react-split-pane/issues/826
    <SplitPane
      style={{ position: 'relative', height: '100%' }}
      split="vertical"
      pane1Style={{
        minWidth: showSidepanel ? '50%' : '100%',
        maxWidth: showSidepanel ? '70%' : '100%',
        display: 'flex',
        flex: 1,
      }}
      pane2Style={{
        minWidth: showSidepanel ? '30%' : '0%',
        maxWidth: showSidepanel ? '50%' : '0%',
      }}
      primary="second"
      allowResize={!!(pane1 && pane2)}
    >
      {pane1WithCollapse}
      {pane2}
    </SplitPane>
  );
}
