/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable sort-imports */
import React, { useContext } from 'react';
import type { RenderCellProps, RenderHeaderCellProps } from 'react-data-grid';
import { difference, isEqual, keyBy, uniqBy } from 'lodash';

import MemberLabel from 'components/addMember/MemberLabel';
import LWChoiceField from 'components/mdfEditor/fields/choice/LWChoiceField';
import { OpenDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import { DateLabel } from 'components/mdfEditor/fields/date/styled';
import { Link } from 'components/mdfEditor/fields/link/LinkDefault';
import { ChoiceTags } from 'components/mdfEditor/fields/multiplechoice/ChoiceTags';
import { InlineTagWrapper } from 'components/mdfEditor/fields/multiplechoice/styled';
import TreeChoiceDefault from 'components/mdfEditor/fields/treechoice/TreeChoiceDefault';
import { isSingleArray } from 'components/mdfEditor/fields/utils';
import Tooltip from 'components/tooltip';
import { TreeChoice } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { EditFieldValue } from 'features/mdf/mdf-utils';
import { StyledNativeButton } from 'features/reusableStyled';
import { HStack } from 'layouts/box/Box';
import {
  SortDownIcon,
  SortUpIcon,
} from 'screens/story/components/notes/components/searchBar/searchBar-styled';
import {
  getDefaultValue,
  getLayoutSettings,
  MiniMember,
  Order,
  ResourceType,
} from 'types/forms/forms';
import {
  Alternative,
  FieldTypeEnum,
  LayoutSettings,
  Mdf,
  MdfField,
  MemberTypeEnum,
  ViewTypes,
} from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { OrderFormMemberType } from 'types/memberTypes/order_form';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import LWCheckbox from './components/LWCheckbox';
import LWSelect from './components/LWSelect';
import LWTextField from './components/LWTextField';
import OpenPreviewButton from './components/OpenPreviewButton';
import OrderActions, { ActionType } from './components/OrderActions';
import { Filter, FilterContext } from './components/OrderGrid';
import SelectAssignee from './components/SelectAssignee';
import { GroupedField, OrderColumn, OrderRow, SavedFilter } from './types';

import { ConfigIconOff, ConfigIconOn, EmptyValueWrapper } from './styled';

type FieldMap = Record<string, MdfField & { formId: string; settings: LayoutSettings }>;
type MdfMap = Record<string, Mdf>;

export const OrderRowToOrderMap: Record<keyof OrderRow, keyof Order> = {
  __assignee: 'mAssignee',
  __ownerId: 'mOwner',
  __status: 'mStatus',
};

export const defaultFieldsTitleMap: Record<string, string> = {
  __status: 'Status',
  __assignee: 'Assignee',
  __createdAt: 'Created',
  __updatedAt: 'Updated',
  __ownerId: 'Task owner',
};

const keyDelimiter = '##';

export const getFieldKey = (fieldId: string, formId: string) =>
  `${fieldId}${keyDelimiter}${formId}`;
export const getFieldIdFromKey = (composite: string) => composite.split(keyDelimiter)[0];

export const getFieldMap = (mdfs: Mdf[], view: ViewTypes = 'order_grid'): FieldMap => {
  const fieldMap: FieldMap = {};
  for (const mdf of mdfs) {
    for (const field of mdf.fields) {
      const settings = getLayoutSettings(mdf, field, view);
      fieldMap[`${getFieldKey(field.fieldId, mdf.id)}`] = { ...field, formId: mdf.id, settings };
    }
  }
  return fieldMap;
};

export const getFormMap = (forms: Mdf[]): MdfMap => {
  return keyBy(forms, (form) => form.id);
};

function Header<R>(props: RenderHeaderCellProps<R>) {
  return (
    <>
      <div style={{ position: 'relative' }}>
        <div
          onClick={(ev) => props.onSort(ev.ctrlKey || ev.metaKey)}
          onKeyDown={() => {}}
          style={{ margin: 'auto', cursor: 'pointer' }}
        >
          {props.column.name}
        </div>
      </div>
      {props.sortDirection &&
        (props.sortDirection == 'ASC' ? (
          <>
            <SortUpIcon
              onClick={(ev) => props.onSort(ev.ctrlKey || ev.metaKey)}
              style={{ position: 'absolute', right: '3px', top: '4px', cursor: 'pointer' }}
            />
            <span style={{ position: 'absolute', right: '28px', top: '13px' }}>
              {props.priority}
            </span>
          </>
        ) : (
          <>
            <SortDownIcon
              onClick={(ev) => props.onSort(ev.ctrlKey || ev.metaKey)}
              style={{ position: 'absolute', right: '3px', top: '5px', cursor: 'pointer' }}
            />
            <span style={{ position: 'absolute', right: '28px', top: '13px' }}>
              {props.priority}
            </span>
          </>
        ))}
    </>
  );
}

function FilterRenderer<R>({
  tabIndex,
  column,
  children,
  onSort,
  priority,
  sortDirection,
}: RenderHeaderCellProps<R> & {
  children: (args: { tabIndex: number; filters: Filter }) => React.ReactElement;
}) {
  const fContext = useContext(FilterContext);
  return (
    <>
      <Header
        onSort={onSort}
        priority={priority}
        sortDirection={sortDirection}
        column={column}
        tabIndex={-1}
      />
      {fContext?.enabled && (
        <div style={{ margin: 'auto', minWidth: '100px' }}>
          {children({ tabIndex, filters: fContext })}
        </div>
      )}
    </>
  );
}

export const cleanStatusValues = (statusOptions: Alternative[]): Alternative[] => {
  return statusOptions.map((alt) => {
    return {
      ...alt,
      value: alt.value.split('#')[1],
    };
  });
};

export const getAllStatusOptions = (
  orderFormMap: Record<string, OrderFormMemberType>,
): Alternative[] => {
  let options: Alternative[] = [{ id: '__all', label: 'All', value: '__all' }];
  for (const orderForm of Object.values(orderFormMap)) {
    const config = orderForm.configs.find((c) => c.key === 'statuses');
    options = [...options, ...(config?.alternatives ?? [])];
  }
  return uniqBy(cleanStatusValues(options), (opt) => opt.label);
};

const getMaxWidth = (fieldModel: MdfField) => {
  switch (fieldModel.type) {
    case FieldTypeEnum.checkbox:
      return 40;
    case FieldTypeEnum.choice:
      return 160;
    case FieldTypeEnum.text:
      return 300;
    default:
      return undefined;
  }
};

const createOptionFromField = (field: MdfField, mdf: Mdf): Alternative => {
  const key = getFieldKey(field.fieldId, mdf.id);
  const settings =
    mdf.views.order_grid.find((l) => l.fieldId === field.fieldId) ??
    mdf.views.default.find((l) => l.fieldId === field.fieldId);
  return {
    id: key,
    label: settings?.label ?? field.fieldId,
    value: key,
  };
};

const excludeHiddenFields = (field: MdfField, mdf: Mdf) => {
  const specificSettings = mdf.views.order_grid.find((l) => l.fieldId === field.fieldId);
  if (specificSettings) return specificSettings.visible;
  return mdf.views.default.find((l) => l.fieldId === field.fieldId)?.visible ?? true;
};

export const extractGroupedMenuItemsFromOrders = (orders: Order[], mdfMap: MdfMap) => {
  const menuItemsMap: Record<string, GroupedField> = {};

  const mdfs: Mdf[] = [];

  for (const order of orders) {
    if (mdfMap[order.mdfId]) {
      mdfs.push(mdfMap[order.mdfId]);
    }
  }

  for (const mdf of mdfs) {
    if (!menuItemsMap[mdf.id]) {
      menuItemsMap[mdf.id] = {
        id: mdf.id,
        mTitle: mdf.label,
        fields: (mdf.fields ?? [])
          .filter((f) => excludeHiddenFields(f, mdf))
          .map((f) => createOptionFromField(f, mdf)),
      };
    }
  }

  return Object.values(menuItemsMap);
};

/**
 * Given a set of orders, compute the columns that should be shown in the grid.
 * @param prevColumns the previous columns if state is needed from them
 * @param orders the orders to convert
 * @param fieldMap a dictionary of form fields keyed by field id
 * @param formMap a dictionary of forms keyed by form id
 * @param userMap a dictionary of users keyed by user id
 * @param goToResource a function to navigate to the resource
 * @param deleteOrder a function to delete an order
 * @param setFilters setter for setting filter data in the grid
 * @param showSortMenu a function for showing the sort menu
 * @returns
 */
export const extractColumnsFromOrders = (
  orders: Order[],
  fieldMap: FieldMap,
  mdfMap: MdfMap,
  orderFormMap: Record<string, OrderFormMemberType>,
  userMap: Record<string, AssignedMember>,
  goToResource: (id: string, type: ResourceType) => void,
  deleteOrder: (props: RenderCellProps<OrderRow, unknown>) => void,
  setFilters: React.Dispatch<React.SetStateAction<Filter>>,
  showSortMenu: (ev: React.MouseEvent<SVGElement, MouseEvent>) => void,
  showPicker: (state: OpenDatePicker) => void,
  showPreview: (orderId: string, title: string) => void,
  showEditFieldDialog: (state: EditFieldValue) => void,
  openTreeChoiceDialog: (state: TreeChoice) => void,
  orderInLocalStorage: string | null,
): OrderColumn[] => {
  const orderInLocal = JSON.parse(orderInLocalStorage ?? '{}') as SavedFilter;

  const { hidden = [], order: mOrder = [] } = orderInLocal;
  const showAllColumns = orderInLocalStorage === null || hidden.length === 0;

  const mdfs: Mdf[] = [];
  const uniqueKeys = new Set<string>();
  const cols: OrderColumn[] = [];
  const allStatusOptions = getAllStatusOptions(orderFormMap);
  const nTaskOwners: Alternative[] = [{ id: 'all', label: 'All', value: 'all' }];
  const nAssignees: Alternative[] = [{ id: 'all', label: 'All', value: 'all' }];

  for (const order of orders) {
    if (order.mOwner)
      nTaskOwners.push({
        id: order.mId,
        label: userMap[order.mOwner]?.mTitle ?? 'unknown',
        value: order.mOwner,
      });

    if (order.mAssignee) {
      nAssignees.push({
        id: order.mId,
        label: userMap[order.mAssignee]?.mTitle ?? 'unknown',
        value: order.mAssignee,
      });
    }

    if (mdfMap[order.mdfId]) {
      mdfs.push(mdfMap[order.mdfId]);
    }
  }

  for (const mdf of mdfs) {
    for (const field of mdf.fields) {
      uniqueKeys.add(getFieldKey(field.fieldId, mdf.id));
    }
  }

  const taskOwners = uniqBy(nTaskOwners, (option) => option.label);
  const taskAssignees = uniqBy(nAssignees, (option) => option.label);

  const it = uniqueKeys.entries();

  const allFields = [...Object.keys(defaultFieldsTitleMap), ...uniqueKeys];
  const isFilterApplied = !isEqual(difference(allFields, hidden), allFields);

  cols.push({
    key: '__formType',
    name: 'Type',
    visible: true,
    resizable: true,
    width: 160,
    renderHeaderCell(props) {
      return (
        <HStack width="100%">
          <Tooltip title="Configure visible columns">
            {isFilterApplied ? (
              <ConfigIconOn onClick={showSortMenu} />
            ) : (
              <ConfigIconOff onClick={showSortMenu} />
            )}
          </Tooltip>
          <Header {...props} />
        </HStack>
      );
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell({ row }) {
      return (
        <OpenPreviewButton onPreviewClick={() => showPreview(row.id, row.__formType)}>
          <span>{row.__formType}</span>
        </OpenPreviewButton>
      );
    },
  });

  for (const entry of it) {
    const key = entry[0];
    const fieldModel = fieldMap[key];

    if (!fieldModel) continue;

    cols.push({
      key,
      name: fieldModel.settings.label ?? 'unknown',
      sortable: true,
      resizable: true,
      visible: (fieldModel.settings.visible ?? true) && (showAllColumns || !hidden.includes(key)),
      maxWidth: getMaxWidth(fieldModel),
      renderHeaderCell(p) {
        return <Header {...p} />;
      },
      cellClass(row) {
        if (row.__mdfId !== fieldModel.formId) {
          return 'disable';
        }
      },
      renderCell({ row, onRowChange }) {
        if (row.__mdfId !== fieldModel.formId) {
          return null;
        }

        const value = row[key] || fieldModel.defaultValue.value;
        const openTreeChoice = () => {
          openTreeChoiceDialog({
            open: true,
            fieldId: fieldModel.fieldId,
            selected: isSingleArray(value) ? value : [],
            setSelected: (v: string[] | []) => onRowChange({ ...row, [key]: v }),
            treeAlternatives: fieldModel.treeAlternatives ?? [],
          });
        };
        const showFieldEditor = () => {
          showEditFieldDialog({
            startValue: value,
            fieldId: fieldModel.fieldId,
            headerText: `Edit ${fieldModel.settings.label}`,
            viewType: 'order_grid',
            mdf: mdfMap[fieldModel.formId],
            onConfirm: (v) => onRowChange({ ...row, [key]: v }),
          });
        };

        if (fieldModel.type === FieldTypeEnum.checkbox) {
          const curValue = Boolean(row[key]);
          const setValue = (val: boolean) => {
            onRowChange({ ...row, [key]: val });
          };
          return <LWCheckbox selected={curValue} setValue={setValue} />;
        }
        if (fieldModel.type === FieldTypeEnum.choice) {
          const curValue = row[key] as string;
          const setValue = (val: string) => {
            onRowChange({ ...row, [key]: val });
          };
          return <LWChoiceField value={curValue} setValue={setValue} fieldModel={fieldModel} />;
        }
        if (fieldModel.type === FieldTypeEnum.text || fieldModel.type === FieldTypeEnum.number) {
          return (
            <div
              title={`${row[key] as string | number}`}
              onDoubleClick={fieldModel.settings.multiline ? showFieldEditor : () => {}}
              style={{ whiteSpace: 'pre', width: '100%', height: '100%' }}
            >
              {row[key] as string | number}
            </div>
          );
        }
        if (fieldModel.type === FieldTypeEnum.date) {
          return (
            <DateLabel
              onClick={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                showPicker({
                  anchorEl: ev.currentTarget,
                  selectDate: (vals) => {
                    onRowChange({ ...row, [key]: vals.startDate });
                  },
                });
              }}
            >
              {row[key] ? isoToLocaleShort(row[key]) : ' '}
            </DateLabel>
          );
        }
        if (fieldModel.type === FieldTypeEnum.multiplechoice) {
          if (isSingleArray(value)) {
            return <ChoiceTags fieldModel={fieldModel} value={value} onClick={showFieldEditor} />;
          }
          return <EmptyValueWrapper onClick={() => showFieldEditor()}>Set value</EmptyValueWrapper>;
        }
        if (fieldModel.type === FieldTypeEnum.link) {
          return (
            <Link
              url={value as string}
              hint={fieldModel.settings.hint}
              onEditClick={showFieldEditor}
            />
          );
        }
        if (fieldModel.type === FieldTypeEnum.user) {
          return <MemberLabel variant="grid" memberId={row[key] as string} />;
        }
        if (fieldModel.type === FieldTypeEnum.treechoice) {
          const parsed = Array.isArray(row[key]) ? (row[key] as string[]) : [];
          return (
            <InlineTagWrapper onClick={openTreeChoice} onKeyDown={() => {}}>
              <TreeChoiceDefault onClick={() => {}} choice={parsed} />
            </InlineTagWrapper>
          );
        }
        if (fieldModel.type === FieldTypeEnum.subtype) {
          return (
            <Tooltip title="View fields in this group">
              <StyledNativeButton onClick={() => showPreview(row.id, row.__formType)}>
                {row[key] as string}
              </StyledNativeButton>
            </Tooltip>
          );
        }
        if (fieldModel.type === FieldTypeEnum.relation) {
          const val = Array.isArray(row[key]) ? (row[key] as MiniMember[]) : [];
          const text = val.length > 1 ? '(s)' : '';
          return (
            <Tooltip title="View">
              <StyledNativeButton onClick={showFieldEditor}>
                {val.length ? `${val.length} related item${text}` : 'Add relations'}
              </StyledNativeButton>
            </Tooltip>
          );
        }
      },
      renderEditCell({ row, onRowChange }) {
        if (row.__mdfId !== fieldModel.formId) {
          return null;
        }
        if (
          (fieldModel.type === FieldTypeEnum.text || fieldModel.type === FieldTypeEnum.number) &&
          !fieldModel.settings.multiline
        ) {
          const curValue = row[key] as string;
          const setValue = (val: string) => {
            onRowChange({ ...row, [key]: val });
          };
          return <LWTextField value={curValue} setValue={setValue} type={fieldModel.type} />;
        }
        if (fieldModel.type === FieldTypeEnum.user) {
          const selectAssignee = (m: AssignedMember) => {
            if (!m) return;
            onRowChange({ ...row, [key]: m.mId }, true);
          };
          return <SelectAssignee selectCallback={selectAssignee} filter={fieldModel.filter} />;
        }
      },
    });
  }

  cols.push({
    key: '__status',
    name: 'Status',
    resizable: true,
    visible: showAllColumns || !hidden.includes('__status'),
    minWidth: 120,
    width: 120,
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderHeaderCell: (p) => (
      <FilterRenderer<OrderRow> {...p}>
        {({ filters }) => {
          return (
            <LWSelect
              value={filters.status}
              setValue={(val) => setFilters((f) => ({ ...f, status: val }))}
              options={allStatusOptions}
            />
          );
        }}
      </FilterRenderer>
    ),
    renderCell({ row, onRowChange }) {
      const curValue = row.__status;
      const config = orderFormMap[row.__formId]?.configs.find((c) => c.key === 'statuses');
      const statusOptions = cleanStatusValues(config?.alternatives ?? []);
      const setValue = (val: string) => {
        onRowChange({ ...row, __status: val });
      };
      return <LWSelect value={curValue} setValue={setValue} options={statusOptions} />;
    },
  });

  cols.push({
    key: '__assignee',
    name: 'Assignee',
    visible: showAllColumns || !hidden.includes('__assignee'),
    width: 120,
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderHeaderCell: (p) => (
      <FilterRenderer<OrderRow> {...p}>
        {({ filters }) => (
          <LWSelect
            value={filters.assigneeId}
            setValue={(val) => setFilters((f) => ({ ...f, assigneeId: val }))}
            options={taskAssignees}
          />
        )}
      </FilterRenderer>
    ),
    renderCell({ row }) {
      return <MemberLabel variant="grid" memberId={row.__assignee} />;
    },
    renderEditCell({ row, onRowChange }) {
      const selectAssignee = (m: AssignedMember) => {
        if (!m) return;
        setTimeout(() => {
          onRowChange({ ...row, __assignee: m.mId }, true);
        }, 0);
      };
      return <SelectAssignee selectCallback={selectAssignee} />;
    },
    sortable: true,
    resizable: true,
  });

  cols.push({
    key: '__ownerId',
    name: 'Task owner',
    visible: showAllColumns || !hidden.includes('__ownerId'),
    width: 120,
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderHeaderCell: (p) => (
      <FilterRenderer<OrderRow> {...p}>
        {({ filters }) => (
          <LWSelect
            value={filters.ownerId}
            setValue={(val) => setFilters((f) => ({ ...f, ownerId: val }))}
            options={taskOwners}
          />
        )}
      </FilterRenderer>
    ),
    renderCell({ row }) {
      return <MemberLabel variant="grid" memberId={row.__ownerId} />;
    },
    renderEditCell({ row, onRowChange }) {
      const selectAssignee = (m: AssignedMember) => {
        if (!m) return;
        setTimeout(() => {
          onRowChange({ ...row, __ownerId: m.mId }, true);
        }, 0);
      };
      return <SelectAssignee selectCallback={selectAssignee} />;
    },
    sortable: true,
    resizable: true,
  });

  cols.push({
    key: '__createdAt',
    name: 'Created',
    resizable: true,
    visible: showAllColumns || !hidden.includes('__createdAt'),
    minWidth: 120,
    width: 120,
    renderHeaderCell(p) {
      return <Header {...p} />;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell({ row }) {
      return <span>{isoToLocaleShort(row.__createdAt)}</span>;
    },
  });

  cols.push({
    key: '__updatedAt',
    name: 'Updated',
    resizable: true,
    visible: showAllColumns || !hidden.includes('__updatedAt'),
    minWidth: 120,
    width: 120,
    renderHeaderCell(p) {
      return <Header {...p} />;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell({ row }) {
      return <span>{isoToLocaleShort(row.__updatedAt)}</span>;
    },
  });

  cols.push({
    key: '__buttons',
    name: 'Actions',
    sortable: false,
    resizable: false,
    visible: true,
    maxWidth: 96,
    renderHeaderCell(props) {
      return <div>{props.column.name}</div>;
    },
    cellClass(row) {
      if (row.__noMatchingForm) {
        return 'error';
      }
    },
    renderCell(props) {
      const { row } = props;
      const onClick = (val: ActionType) => {
        switch (val) {
          case 'go-to-space': {
            goToResource(row.__spaceId, 'space');
            break;
          }
          case 'go-to-resource': {
            goToResource(row.__resourceId, row.__resourceType);
            break;
          }
          case 'copy': {
            void navigator.clipboard.writeText(row.id);
            break;
          }
          case 'delete': {
            deleteOrder(props);
          }
        }
      };

      return <OrderActions onClick={onClick} resourceType={row.__resourceType} />;
    },
  });

  const orderedArray = [] as OrderColumn[];

  mOrder.forEach((order) => {
    const index = cols.findIndex((col) => col.key === order);
    if (index !== -1) {
      orderedArray.push(cols[index]);
      cols.splice(index, 1);
    }
  });
  cols.splice(1, 0, ...orderedArray);

  return cols;
};

export const extractRowsFromOrders = (
  orders: Order[],
  mdfMap: MdfMap,
  orderFormMap: Record<string, OrderFormMemberType>,
): OrderRow[] => {
  const rows: OrderRow[] = [];
  for (const order of orders) {
    const row: OrderRow = {
      id: order.mId,
      __type: MemberTypeEnum.Order,
      __formId: order.mFormId,
      __mdfId: order.mdfId,
      __spaceId: order.mSpaceId,
      __formType: orderFormMap[order.mFormId]?.mDescription ?? 'Error - form not found',
      __spaceTitle: 'Unknown', // TODO - Figure out.
      __resourceId: order.mResourceId,
      __ownerId: order.mOwner ?? '',
      __assignee: order.mAssignee ?? '',
      __resourceType: order.mResourceType,
      __status: order.mStatus ?? '',
      __noMatchingForm:
        mdfMap[order.mdfId] === undefined || orderFormMap[order.mFormId] === undefined,
      __createdAt: order.mCreatedAt,
      __updatedAt: order.mUpdatedAt,
      __buttons: 'val', // dummy value to make the column show up
    };

    const fieldsForOrder = mdfMap[order.mdfId]?.fields ?? [];
    for (const field of fieldsForOrder) {
      row[getFieldKey(field.fieldId, order.mdfId)] =
        order.metadata[field.fieldId] ?? getDefaultValue(field);
    }
    rows.push(row);
  }

  return rows;
};
