import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { capitalize } from 'lodash';

import { useUpdateOrderForm } from 'api/order_forms/useUpdateOrderForm';
import { Button } from 'components/buttons';
import Checkbox from 'components/checkbox';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { MdfDropdown } from 'components/mdfEditor/MdfDropdown';
import { FieldHeader } from 'components/mdfEditor/styled';
import Scrollbar from 'components/scrollbar/scrollbar';
import useToast from 'components/toast/useToast';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { Alternative, ConfigType } from 'types/graphqlTypes';
import { isObject } from 'types/members';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import { EditStatusAlternativesDialog } from './EditStatusAlternativesDialog';

const Root = styled('div')`
  padding-block: 8px;
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Wrapper = styled('div')`
  margin-top: 8px;
`;

const TopWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled('p')`
  margin: 0;
  ${({ theme }) => theme.typography.dina.overline};
  padding: 4px;
`;

const ListWrapper = styled('div')`
  width: 100%;
  height: 400px;
  margin-left: 8px;
`;

const StyledFormControl = styled(FormControlLabel)`
  margin-left: 0;
  .MuiCheckbox-root {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
  }
`;

interface Props {
  orderForm: OrderFormMemberType;
}

/**
 * Once fully typed, use Pick<T> with full type
 */
export interface Platform {
  mProperties: {
    platformKind?: string;
    platform: string;
  };
}

export const isPlatform = (obj: unknown): obj is Platform => {
  if (!isObject(obj)) return false;
  const probPlatform = obj as Partial<Platform>;
  return typeof probPlatform?.mProperties?.platform === 'string';
};

export const isPlatformArray = (obj: unknown): obj is Platform[] => {
  if (!Array.isArray(obj)) return false;
  return obj.every(isPlatform);
};

const localTypes = ['story', 'rundown', 'instance', 'block', 'order', 'pitch'];

const isInstance = (type: string) => {
  return !localTypes.includes(type);
};

function EditOrderFormProperties({ orderForm }: Readonly<Props>) {
  const { errorToast } = useToast();
  const { platforms } = useGetPlatforms(new Date());
  const [open, setOpen] = useState(false);
  const { updateOrderForm } = useUpdateOrderForm();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [title, setTitle] = useState(orderForm.mTitle);
  const [description, setDescription] = useState(orderForm.mDescription);
  const [formIdValue, setFormIdValue] = useState(orderForm.mSecId ?? null);

  const handleUpdate = useCallback(
    (upd: Partial<OrderFormMemberType>) => {
      updateOrderForm({
        ...orderForm,
        ...upd,
      }).catch(errorToast);
    },
    [orderForm],
  );

  const handleTitleChange = (val: string) => {
    if (val.length > 0) {
      handleUpdate({ mTitle: val });
    } else {
      setTitle(orderForm.mTitle);
    }
  };

  const handleDescriptionChange = (val: string) => {
    if (val.length > 0) {
      handleUpdate({ mDescription: val });
    } else {
      setDescription(orderForm.mDescription);
    }
  };

  const updateTypes = useCallback(
    (types: string[]) => {
      const idx = orderForm.configs.findIndex((c) => c.key === 'types');
      if (idx >= 0) {
        const copy: ConfigType = { ...orderForm.configs[idx], values: types };
        const newConfigs = [...orderForm.configs.filter((c) => c.key !== 'types'), copy];
        handleUpdate({ configs: newConfigs });
      }
      setSelectedTypes(types);
    },
    [orderForm],
  );

  const updateStatuses = useCallback(
    (alts: Alternative[]) => {
      const idx = orderForm.configs.findIndex((c) => c.key === 'statuses');
      if (idx >= 0) {
        const copy: ConfigType = { ...orderForm.configs[idx], alternatives: alts };
        const newConfigs = [...orderForm.configs.filter((c) => c.key !== 'statuses'), copy];
        handleUpdate({ configs: newConfigs });
      }
    },
    [orderForm],
  );

  const updateFormId = (newFormId: string | null) => {
    setFormIdValue(newFormId ?? '');
    handleUpdate({ mSecId: newFormId ?? '' });
  };

  useEffect(() => {
    setTitle(orderForm.mTitle);
    setDescription(orderForm.mDescription);
    setFormIdValue(orderForm.mSecId ?? null);
    setSelectedTypes(orderForm.configs.find((c) => c.key === 'types')?.values ?? []);
  }, [orderForm]);

  const filterableTypes = useMemo(() => {
    const fTypes = [...localTypes];
    for (const platform of platforms) {
      if (platform.mProperties.platformKind === 'audio') {
        fTypes.push('audio');
      } else {
        fTypes.push(platform.mProperties.platform);
      }
    }
    return fTypes;
  }, [platforms]);

  const toggle = useCallback(
    (type: string, isSelected: boolean) => {
      const currentTypes = selectedTypes || [];
      const updatedTypes = isSelected
        ? currentTypes.filter((n) => n !== type)
        : [...currentTypes, type];
      updateTypes(updatedTypes);
    },
    [selectedTypes, updateTypes],
  );

  const hasInstance = useMemo(() => {
    for (const type of selectedTypes) {
      if (!localTypes.includes(type)) return true;
    }
    return false;
  }, [selectedTypes]);

  return (
    <Root>
      <TopWrapper>
        <MdfDropdown
          disableClearable
          value={formIdValue}
          onSelect={updateFormId}
          style={{ marginBottom: '0px' }}
          showEditIcon={true}
        />
        <FieldHeader>Group</FieldHeader>
        <StyledTextField
          value={title}
          variant="filled"
          onChange={(ev) => setTitle(ev.target.value)}
          onBlur={() => handleTitleChange(title)}
        />
        <FieldHeader>Form name</FieldHeader>
        <StyledTextField
          value={description}
          variant="filled"
          onChange={(ev) => setDescription(ev.target.value)}
          onBlur={() => handleDescriptionChange(description)}
        />
      </TopWrapper>
      <Button height={32} onClick={() => setOpen(true)}>
        Edit status options
      </Button>
      <Wrapper>
        <Label>Choose where this form should be available</Label>
        <ListWrapper>
          <Scrollbar
            valueChanged={undefined}
            closeToBottom={undefined}
            top={undefined}
            bottom={undefined}
            dark={undefined}
            showHorizontal={undefined}
          >
            {filterableTypes.map((type: string) => {
              return (
                <div key={type} style={{ marginLeft: isInstance(type) ? '24px' : '0' }}>
                  <StyledFormControl
                    key={type}
                    control={
                      <Checkbox
                        indeterminate={
                          type === 'instance' && !selectedTypes.includes('instance') && hasInstance
                        }
                        onClick={() =>
                          toggle(type, selectedTypes ? selectedTypes.includes(type) : false)
                        }
                        selected={
                          (isInstance(type) && selectedTypes.includes('instance')) ||
                          selectedTypes.includes(type)
                        }
                        disabled={isInstance(type) && selectedTypes.includes('instance')}
                      />
                    }
                    label={capitalize(type)}
                  />
                </div>
              );
            })}
          </Scrollbar>
        </ListWrapper>
      </Wrapper>
      <EditStatusAlternativesDialog
        open={open}
        setOpen={setOpen}
        alternatives={orderForm.configs.find((c) => c.key === 'statuses')?.alternatives ?? []}
        updateAlternatives={(updatedAlts) => updateStatuses(updatedAlts)}
      />
    </Root>
  );
}

export default EditOrderFormProperties;
