import { useContext, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import { Button } from 'components/buttons';
import UserContext from 'contexts/UserContext';
import useCreateMessage from 'hooks/useCreateMessage';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetUser from 'hooks/useGetUser';
import useSelectRundownInstance from 'hooks/useSelectRundownInstance';
import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import GET_MESSAGES_OF_CONVERSATION from 'operations/queries/getMessagesOfConversation';
import { useRightHidden, useRightSelection } from 'store/sidebar';
import { EditorValue } from 'types/editor';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';
import useLogger from 'utils/useLogger';

import { useConversationMolecule } from '../../store/conversation';

import MessageBubble from './MessageBubble';
import MessagesSkeleton from './MessagesSkeleton';
const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

function MessagesContainer() {
  const logger = useLogger('Messages Container');
  const { navigateTo } = useDinaNavigate();

  const { getUser } = useGetUser();
  const { useCurrentConversation, useToggleMessageHub } = useConversationMolecule();
  const currentConversation = useCurrentConversation();
  const toggleMessageHub = useToggleMessageHub();
  const { mId = '', convoType } = currentConversation ?? {};
  const user = useContext(UserContext);
  const lastMessageRef = useRef<string | null | undefined>(null);
  const [updateConvoReadAt] = useUpdateConvoReadAt();
  const [createMessage] = useCreateMessage();

  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const { selectRundownInstance } = useSelectRundownInstance();

  const handleDelete = async (mRefId: string) => {
    await createMessage(mId, '', convoType, mRefId, 'DELETE');
  };

  const handleUpdate = async (mRefId: string, mContent: EditorValue) => {
    await createMessage(mId, JSON.stringify(mContent), convoType, mRefId, 'UPDATE');
  };

  useEffect(() => {
    lastMessageRef.current = null;
    void updateConvoReadAt(mId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mId]);

  const variables = {
    input: { mId },
    limit: 25,
  };

  const { data, loading, error, fetchMore } = useQuery<{
    getMessagesOfConversation: { items: Conversation[]; nextToken: string };
  }>(GET_MESSAGES_OF_CONVERSATION, {
    variables,
    skip: !mId,
    fetchPolicy: 'cache-and-network',
  });

  if (!mId) return <div />;
  if (error) {
    logger.error(error);
    return <div />;
  }

  const messages = data?.getMessagesOfConversation?.items || [];
  const hasMore = Boolean(data?.getMessagesOfConversation?.nextToken);

  const sortedMessages = [...messages].sort((a, b) =>
    a.mCreatedAt && b.mCreatedAt && a.mCreatedAt > b.mCreatedAt ? -1 : 1,
  );

  const handleLoadMore = async () => {
    if (hasMore) {
      const [lastMessage] = sortedMessages.slice(-1);
      lastMessageRef.current = lastMessage.mRefId;
      await fetchMore({
        variables: {
          ...variables,
          nextToken: data?.getMessagesOfConversation.nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          getMessagesOfConversation: {
            items: [
              ...previousResult.getMessagesOfConversation.items,
              ...fetchMoreResult.getMessagesOfConversation.items,
            ],
            nextToken: fetchMoreResult.getMessagesOfConversation.nextToken,
            __typename: 'PaginatedMessageType',
          },
        }),
      });
    }
  };

  const handleOpenStory = (
    mStoryId: string,
    page: string,
    instanceId: string,
    currentConvoType: string,
  ) => {
    if (page === 'rundown') {
      navigateTo(page, mStoryId);
      if (instanceId) {
        selectRundownInstance([instanceId]);
      } else if (currentConvoType === conversationTypes.MENTION) {
        setRightHidden(false);
        setRightSelection('chat');
      }
    } else if (page === 'story') {
      navigateTo(page, mStoryId, {
        tab: instanceId ? 'instances' : 'content',
        entityId: instanceId,
      });
      if (currentConvoType === conversationTypes.MENTION) {
        setRightHidden(false);
        setRightSelection('chat');
      }
    }
    toggleMessageHub();
  };

  if (data?.getMessagesOfConversation) {
    return (
      <>
        {messages.map((message) => (
          <MessageBubble
            key={message.mRefId}
            message={message}
            userId={user.mId}
            showEditButton
            showDeleteButton
            getUser={getUser}
            onDelete={handleDelete}
            openStory={handleOpenStory}
            onUpdateMessage={handleUpdate}
            suggestedUsers={[]}
          />
        ))}
        {hasMore && (
          <ButtonWrapper>
            <Button
              onClick={() => void handleLoadMore()}
              variant="discreet"
              usage="text"
              title="Load more messages"
            >
              Load more
            </Button>
          </ButtonWrapper>
        )}
      </>
    );
  }

  if (loading) return <MessagesSkeleton />;
}

export default MessagesContainer;
