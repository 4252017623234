import { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { type TableMeta } from '@tanstack/react-table';

import { TableRow } from 'components/dataTable/Table';
import { type ParsedMemberType } from 'types';

import { type RowProps } from './RowCells';

interface DraggableRowProps extends Pick<RowProps, 'row'> {
  children: React.ReactNode;
  onContextMenu?: TableMeta<ParsedMemberType>['onContextMenu'];
}

/** Row component for table with manual reorder functionality */
export default function DraggableRow({
  row,
  onContextMenu,
  children,
}: Readonly<DraggableRowProps>) {
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.mId,
  });

  // TODO: Refactor this to use a styled component, and remove the inline styles
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    background: isDragging ? '#0A73EB' : 'revert-layer',
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
    outline: row.getIsSelected() ? '2px solid #0A73EB' : 'none',
    boxShadow: isDragging ? '0 5px 10px 0px rgba(0, 0, 0, 0.3)' : 'none',
  };

  return (
    <TableRow
      onClick={row.getToggleSelectedHandler()}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onContextMenu={(event: React.MouseEvent<Element, MouseEvent>) =>
        onContextMenu?.(event, row.original)
      }
    >
      {children}
    </TableRow>
  );
}
