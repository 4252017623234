import { useState } from 'react';
import {
  type ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  type RowData,
  type RowSelectionState,
  type SortingState,
  TableMeta,
  useReactTable,
  type VisibilityState,
} from '@tanstack/react-table';

import { Mdf } from 'types/graphqlTypes';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    mdfs?: Mdf[];
    onContextMenu?: (event: React.MouseEvent<Element, MouseEvent>, member: TData) => void;
  }
}

interface WithMId {
  /** Row id */
  mId: string;
}

interface UseDataTableProps<TData extends WithMId, TValue> {
  /** Table data array. */
  data: TData[];
  /** Table column definitions. */
  columns: ColumnDef<TData, TValue>[];
  /** Used to pass additional data / functions to the table. */
  meta?: TableMeta<TData>;
}

export function useDataTable<TData extends WithMId, TValue>({
  data,
  columns,
  meta,
}: Readonly<UseDataTableProps<TData, TValue>>) {
  // Table states
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
    },
    meta,
    columnResizeMode: 'onChange',
    enableColumnResizing: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.mId,
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: 90,
    },
  });

  return { table };
}
