import { ChangeEvent, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { InputAdornment } from '@material-ui/core';
import { useAtom } from 'jotai';

import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import useDeleteOrder from 'components/orderFormDialog/api/useDeleteOrder';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip/Tooltip';
import ContextMenu from 'features/contextMenu';
import { SearchField } from 'features/search/styled';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useFuseSearch from 'hooks/useFuseSearch';
import useInputEvents from 'hooks/useInputEvents';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { useGetOrders } from 'screens/space/api/useGetOrdersAndForms';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import { useOrderFormFilter } from 'store';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';
import { atomWithSessionStorage } from 'utils/atoms/atomWithSessionStorage';

import { Fallback, OrderWithLabel, TASK_MENU_ID, TaskItem } from './ComponentUtils';
import { getStatusOptions } from './EditOrderBlock';
import { ToggleList } from './ToggleList';

import { CloseIcon, FilterWrapper, PlusIcon, SearchIcon, SearchWrapper } from './styled';

const titleSearchAtom = atomWithSessionStorage<string>('task:titleSearch', '');

interface ControlledTaskItemProps {
  order: OrderWithLabel;
}

function ControlledTaskItem({ order }: Readonly<ControlledTaskItemProps>) {
  const [open, setOpen] = useState(false);
  const { useSelectedBlockId, useForceOpenId } = useStoryPaneMolecule();
  const [selectedBlockId, setSelectedBlockId] = useSelectedBlockId();
  const [forceOpenId, setForceOpenId] = useForceOpenId();

  useEffect(() => {
    if (forceOpenId === order.mId) {
      setOpen(true);
    }
  }, [forceOpenId]);

  const onSelect = useCallback(
    (orderId: string) => {
      if (selectedBlockId !== orderId) {
        setSelectedBlockId(orderId);
        setForceOpenId(undefined);
      }
    },
    [selectedBlockId],
  );

  return (
    <TaskItem
      id={`toggle-${order.mId}`}
      order={order}
      open={open}
      setOpen={setOpen}
      onSelect={() => onSelect(order.mId)}
      selected={selectedBlockId === order?.mId}
    />
  );
}

type MenuClickType = {
  id: string;
  props: {
    order: OrderWithLabel;
  };
};
interface TaskProps {
  resourceDetails: ResourceDetails;
  queryType?: GetOrderEnum;
}

function Task({ resourceDetails, queryType = GetOrderEnum.Resource }: Readonly<TaskProps>) {
  const { show } = useContextMenu({ id: 'orderForms' });
  const { errorToast } = useToast();
  const [itemToDelete, setItemToDelete] = useState<OrderWithLabel | null>(null);
  const [, setFormFilter] = useOrderFormFilter();
  const { deleteOrder } = useDeleteOrder();
  const { resourceId, orderFormMap, resourceType } = resourceDetails ?? {};
  const { orders } = useGetOrders(resourceId, queryType, TaskStatusEnum.all);
  const [searchText, setSearchText] = useAtom(titleSearchAtom);
  const [localValue, setLocalValue] = useState(searchText);
  const fuseSearch = useFuseSearch();

  const matchFunction = useCallback(
    (list: OrderWithLabel[]) =>
      fuseSearch(list, ['formLabel', 'statusLabel'], localValue?.substring(1) || ''),
    [fuseSearch, localValue],
  );

  const orderWithLabel: OrderWithLabel[] = useMemo(() => {
    return orders.map((order) => {
      const stsOptions = getStatusOptions(order.mFormId, orderFormMap);
      return {
        ...order,
        formLabel: orderFormMap?.[order.mFormId]?.mDescription ?? '',
        statusLabel: stsOptions.find((opt) => opt.value === order.mStatus)?.label ?? order.mStatus,
        statusOptions: stsOptions,
      };
    });
  }, [orders, orderFormMap]);

  const filteredOrders = useMemo(
    () => matchFunction(orderWithLabel),
    [matchFunction, orderWithLabel],
  );

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(
    setSearchText,
    localValue,
    searchText,
  );

  const [debouncedSetText] = useDebouncedCallback(setSearchText, 300);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.preventDefault();
    const inputValue = event.target.value;
    void debouncedSetText(inputValue);
    setLocalValue(inputValue);
  }, []);

  const clearSearch: MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      event.preventDefault();
      void debouncedSetText('');
      setLocalValue('');
    },
    [setLocalValue],
  );

  const openContextMenu: MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      event.preventDefault();
      setFormFilter({ types: [resourceType] });
      show({
        event,
        props: {
          resourceId,
          resourceType,
        },
      });
    },
    [setFormFilter, show],
  );

  const deleteTask = useCallback(async () => {
    if (itemToDelete) {
      deleteOrder({ mId: itemToDelete.mId, mResourceId: itemToDelete.mResourceId })
        .catch(errorToast)
        .finally(() => {
          setItemToDelete(null);
        });
    }
  }, [itemToDelete]);

  const onMenuClick = useCallback(
    (data: MenuClickType) => {
      if (data.id === 'removeTask') setItemToDelete(data.props.order);
    },
    [setItemToDelete],
  );

  return (
    <>
      <ToggleList
        header={
          <SearchWrapper container height="48px" width="100%">
            <FilterWrapper container height="48px" width="36px">
              <Tooltip title="Add new task">
                <PlusIcon className="skipOverride" onClick={openContextMenu} />
              </Tooltip>
            </FilterWrapper>
            <SearchField
              id="sidepanel-search-input"
              size="small"
              value={localValue}
              onChange={handleChange}
              fullWidth
              InputProps={{
                placeholder: 'Type to find task...',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="skipOverride" />
                  </InputAdornment>
                ),
                endAdornment: localValue && (
                  <InputAdornment position="end" style={{ marginRight: '0px' }}>
                    <CloseIcon className="skipOverride" onMouseDown={clearSearch} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeydown}
              onBlur={handleBlur}
              inputRef={inputRef}
              variant="outlined"
            />
          </SearchWrapper>
        }
        list={
          <>
            {filteredOrders.length > 0 ? (
              filteredOrders.map((order) => <ControlledTaskItem key={order.mId} order={order} />)
            ) : (
              <Fallback label="No task items..." />
            )}
          </>
        }
      />
      <ContextMenu
        id={TASK_MENU_ID}
        menuItems={[
          {
            id: 'removeTask',
            label: 'Delete task',
          },
        ]}
        onClick={onMenuClick}
      />
      <DeleteDialog
        open={Boolean(itemToDelete)}
        onClose={() => setItemToDelete(null)}
        onClick={deleteTask}
        title="Delete task?"
        message="Are you sure you want to delete this task? This cannot be undone!"
      />
    </>
  );
}

export default Task;
