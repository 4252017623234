import { isHighlight } from 'components/command/command-types';
import { safeForHTML } from 'components/command/command-utils';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

export const getMemberTitle = (
  item: MemberType,
  forms: Record<string, OrderFormMemberType | undefined>,
  commands: Record<string, EditorCommandConfigType | undefined>,
) => {
  if (item.mFormId && item.mType === MemberTypeEnum.Order) {
    return forms[item.mFormId]?.mDescription ?? item.mTitle ?? '';
  } else if (item.mType === MemberTypeEnum.Block) {
    const command = commands && item.mSecId ? commands[item.mSecId] : undefined;
    return item.mTitle
      ? `${command?.mTitle ?? 'Unknown'}: ${item.mTitle}`
      : command?.mTitle ?? 'Unknown';
  }
  return item.mTitle ?? '';
};

export const getHighlightedTitle = (
  item: MemberType,
  forms: Record<string, OrderFormMemberType | undefined>,
  commands: Record<string, EditorCommandConfigType | undefined>,
) => {
  if (item.highlight) {
    try {
      const text = JSON.parse(item.highlight) as unknown;
      if (isHighlight(text)) {
        if (text.mTitle?.length) {
          return {
            __html: `${safeForHTML(text.mTitle[0])}`,
          };
        }
      }
    } catch {
      // Silently fail, let it fall back.
    }
  }

  return {
    __html: `${getMemberTitle(item, forms, commands)}`,
  };
};
