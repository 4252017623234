import { useMemo } from 'react';

import { CommandGroup } from 'lib/command';

import {
  type GroupOption,
  type MultiSelectContextProps,
  type MultiSelectListProps,
} from '../types';
import { removePickedOption } from '../utils/utils';

import { CreatableItem } from './DropdownListCreatableItem';
import { DropdownListItem } from './DropdownListItem';

type ContextProps = Pick<
  MultiSelectContextProps,
  | 'creatable'
  | 'debouncedSearchTerm'
  | 'inputValue'
  | 'isLoading'
  | 'onChange'
  | 'onSearch'
  | 'options'
  | 'selected'
  | 'setInputValue'
  | 'setSelected'
>;

interface DropdownListContentProps extends ContextProps, MultiSelectListProps {
  maxSelected: number;
}

/** Content of the dropdown list */
export const DropdownListContent = ({
  creatable,
  debouncedSearchTerm,
  inputValue,
  isLoading,
  maxSelected,
  onChange,
  onMaxSelected,
  onSearch,
  options,
  selected,
  setInputValue,
  setSelected,
}: Readonly<DropdownListContentProps>) => {
  const selectables = useMemo<GroupOption>(
    () => removePickedOption(options, selected),
    [options, selected],
  );

  return (
    <>
      <CreatableItem
        {...{
          creatable,
          debouncedSearchTerm,
          inputValue,
          isLoading,
          maxSelected,
          onChange,
          onMaxSelected,
          onSearch,
          options,
          selected,
          setInputValue,
          setSelected,
        }}
      />
      {Object.entries(selectables).map(([key, dropdowns]) => (
        <CommandGroup key={key} heading={key}>
          <>
            {dropdowns.map((option) => {
              return (
                <DropdownListItem
                  key={option.value}
                  option={option}
                  {...{
                    inputValue,
                    maxSelected,
                    onChange,
                    onMaxSelected,
                    selected,
                    setInputValue,
                    setSelected,
                  }}
                />
              );
            })}
          </>
        </CommandGroup>
      ))}
    </>
  );
};
