import { atom, useAtom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { Instance } from 'types';
import { atomWithSessionStorage } from 'utils/atoms/atomWithSessionStorage';

export type MousePosition = {
  x: number | null;
  y: number | null;
};

export const initialMousePosition = {
  x: null,
  y: null,
};

export const PaneScope = createScope<`${string}:${number}` | undefined>(undefined);

const storyPaneMolecule = molecule((_getMol, getScope) => {
  const paneScope = getScope(PaneScope);
  const [storyId, index] = (paneScope ?? ':0').split(':');

  const blockExpandStateAtom = atomWithSessionStorage<Record<string, boolean>>(
    `editor:block-collapse-state:${index}`,
    {},
  );
  const showSidePanelAtom = atomWithStorage<boolean>(`sidepanel:${storyId}:${index}`, true);
  const selectedBlockIdAtom = atom<string | undefined>(undefined);
  const sidepanelTabAtom = atomWithStorage<'planning' | 'tasks'>(
    `sidepanel-tab:${storyId}:${index}`,
    'planning',
  );
  const forceOpenId = atom<string | undefined>(undefined);
  const paneIndexAtom = atom<number>(parseInt(index));
  const creatingInstanceAtom = atom(false);
  const newlyAddedInstanceAtom = atom<Instance | null>(null);
  const selectedStoryInstancesAtom = atom<string[]>([]);
  const mousePositionAtom = atom<MousePosition>(initialMousePosition);

  return {
    usePaneIndexValue: () => useAtomValue(paneIndexAtom),
    useCreatingInstance: () => useAtom(creatingInstanceAtom),
    useNewlyAddedInstance: () => useAtom(newlyAddedInstanceAtom),
    useSelectedStoryInstances: () => useAtom(selectedStoryInstancesAtom),
    useMousePosition: () => useAtom(mousePositionAtom),
    useShowSidePanel: () => useAtom(showSidePanelAtom),
    useSelectedBlockId: () => useAtom(selectedBlockIdAtom),
    useSidepanelTab: () => useAtom(sidepanelTabAtom),
    useBlockExpandState: () => useAtom(blockExpandStateAtom),
    useForceOpenId: () => useAtom(forceOpenId),
  };
});

export const useStoryPaneMolecule = () => useMolecule(storyPaneMolecule);
