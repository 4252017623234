import { ReactNode } from 'react';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import { v4 as uuidV4 } from 'uuid';

import defaultThumbnail from 'assets/images/default/defaultThumbnail.png';
import getCleanLink from 'components/editor/utils/getCleanLink';
import useStorageImage from 'hooks/useStorageImage';
import { CustomData, CustomElement, CustomText, EditorValue, isCustomElement } from 'types';
import { getThumbnailKey } from 'utils/mediaKey';
import getDirection from 'utils/text/getDirection';

import AccessoryIcon from '../components/AccessoryIcon';
import AdlibIcon from '../components/AdlibIcon';
import AudioIcon from '../components/AudioIcon';
import AutoDirText from '../components/AutoDirText';
import BreakIcon from '../components/BreakIcon';
import CameraIcon from '../components/CameraIcon';
import CGIcon from '../components/CGIcon';
import CheckboxOff from '../components/CheckboxOff';
import CheckboxOn from '../components/CheckboxOn';
import DveIcon from '../components/DveIcon';
import GraphicsIcon from '../components/GraphicsIcon';
import JingleIcon from '../components/JingleIcon';
import LiveIcon from '../components/LiveIcon';
import TelephoneIcon from '../components/TelephoneIcon';
import VideoClipIcon from '../components/VideoClipIcon';
import VoiceOverIcon from '../components/VoiceOverIcon';

import { styles } from './styles';

const getHeaderFontSize = (type: string) => {
  switch (type) {
    case 'heading-one':
      return 34;
    case 'heading-two':
      return 30;
    case 'heading-three':
      return 24;
    case 'heading-four':
      return 20;
    case 'heading-five':
      return 18;
    case 'heading-six':
      return 16;
    default:
      return 16;
  }
};

const typeToIconMap = {
  dve: <DveIcon />,
  jingle: <JingleIcon />,
  break: <BreakIcon />,
  adlib: <AdlibIcon />,
  telephone: <TelephoneIcon />,
  audio: <AudioIcon />,
  accessory: <AccessoryIcon />,
};

const getNodeStyles = (node: CustomText) => {
  const { bold, color, underline, strikeThrough, italic } = node;
  const nodeStyles: Record<string, unknown>[] = [];

  if (bold) nodeStyles.push(styles.bold);

  if (italic) nodeStyles.push(styles.italic);

  if (color) nodeStyles.push({ color: color === '#fff' || color === '#ffffff' ? '#000' : color });

  if (underline && !strikeThrough) nodeStyles.push(styles.underline);

  if (strikeThrough && !underline) nodeStyles.push(styles.strikeThrough);

  if (underline && strikeThrough) nodeStyles.push(styles.underlineAndST);

  return nodeStyles;
};

const scriptDeterminantColors = ['#fff', '#ffffff', '#000', '#000000'];
export const getScriptFromContent = (content?: EditorValue | null) => {
  if (!content) return;

  return content.document.reduce((acc, doc) => {
    const { type, children } = doc;
    if (type === 'paragraph' && !!children) {
      children.forEach((child) => {
        if (isCustomElement(child)) return;
        const { color } = child;

        if ((!color || scriptDeterminantColors.includes(color)) && !!child.text) acc.push(child);
      });
    }
    return acc;
  }, [] as CustomText[]);
};

export const ImageComponent = ({ data, type }: { data: CustomData; type: string }) => {
  const { mId, mRefId, src, proxy, mTitle, title, mediaType } = data;
  const key = (src || getThumbnailKey(mId, mRefId)) ?? undefined;
  const {
    data: thumbnailData,
    error: storageError,
    loading: storageLoading,
  } = useStorageImage(key);
  const s3ThumbUrl = storageError && !storageLoading ? defaultThumbnail : thumbnailData;
  const imgSrc = s3ThumbUrl ?? proxy;

  if (!imgSrc) return <Text>{type}</Text>;

  return (
    <View key={imgSrc}>
      <Image src={imgSrc} style={styles.image} />
      <Text style={styles.caption}>
        Title: {mTitle ?? title ?? ''} {mediaType ? `Type: ${mediaType}` : ''}
      </Text>
    </View>
  );
};

const ListItem = ({
  value = '',
  type = 'unordered-list',
  order,
  style = {},
}: {
  value: string | CustomElement;
  type: string;
  order: number;
  style?: Record<string, unknown> | Record<string, unknown>[];
}) => {
  const direction = typeof value === 'string' ? getDirection(value) : 'ltr';

  return (
    <View
      style={
        direction === 'rtl'
          ? { flexDirection: 'row-reverse', alignItems: 'flex-end' }
          : { flexDirection: 'row' }
      }
      key={uuidV4()}
    >
      <View style={styles.bullet}>
        {type === 'unordered-list' ? (
          <Text>{`${direction === 'rtl' ? ' \u2022' : '\u2022 '}`}</Text>
        ) : (
          <Text>{`${direction === 'rtl' ? ` .${order}` : `${order}. `}`}</Text>
        )}
      </View>
      {typeof value === 'string' ? (
        <AutoDirText value={value} style={style} />
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getDocumentComponent(value)
      )}
    </View>
  );
};

export const getDocumentComponent = (doc: CustomElement): ReactNode => {
  const { data, type, children } = doc;

  switch (type) {
    case 'paragraph': {
      let direction = 'ltr';

      const childrenView = children.map((child, index) => {
        if (isCustomElement(child)) return getDocumentComponent(child);
        const { text } = child;
        const childStyles = getNodeStyles(child);

        if (index === 0) {
          // determine to render as rtl if there's an arabic word at the begining of the sentence
          direction = getDirection(text);
        }

        const filteredStyleForArabic =
          direction === 'rtl'
            ? childStyles.filter((cStyle) => cStyle.fontStyle !== 'italic')
            : childStyles;

        return <AutoDirText key={uuidV4()} value={text} style={filteredStyleForArabic} />;
      });

      return (
        <Text
          key={uuidV4()}
          style={
            direction === 'rtl'
              ? { flexDirection: 'row-reverse', alignItems: 'flex-end', gap: 4 }
              : { flexDirection: 'row', gap: 4 }
          }
        >
          {childrenView}
        </Text>
      );
    }

    case 'heading-one':
    case 'heading-two':
    case 'heading-three':
    case 'heading-four':
    case 'heading-five':
    case 'heading-six':
      return children.map((child) => {
        if (isCustomElement(child)) return;
        const childStyles = getNodeStyles(child);
        const headerStyles = {
          ...childStyles,
          fontSize: getHeaderFontSize(type),
          fontWeight: 600,
        };

        return <AutoDirText key={uuidV4()} style={headerStyles} value={child.text} />;
      });

    case 'ordered-list':
    case 'unordered-list':
      return (
        <View style={{ marginTop: 8 }} key={uuidV4()}>
          {children.map((child, index) => {
            if (!isCustomElement(child)) return;
            let listChildren = child.children;
            if (listChildren.length > 1) {
              listChildren = listChildren.filter((ch) => isCustomElement(ch));
            }

            return listChildren.map((grandChild) => {
              const childStyles = 'text' in grandChild ? getNodeStyles(grandChild) : undefined;
              return (
                <ListItem
                  value={'text' in grandChild ? grandChild.text : grandChild}
                  type={type}
                  order={index + 1}
                  style={childStyles}
                  key={uuidV4()}
                />
              );
            });
          })}
        </View>
      );

    case 'block-quote': {
      const text = `"${(children[0] as CustomText).text}"`;
      const direction = getDirection(text);

      return (
        <AutoDirText
          key={uuidV4()}
          value={text}
          style={direction === 'rtl' ? { color: 'gray' } : styles.blockQuote}
        />
      );
    }

    case 'checklist': {
      let direction: string = 'ltr';

      const childText = children.map((child, index) => {
        if (isCustomElement(child)) return;
        const { text } = child;
        const childStyles = getNodeStyles({
          ...child,
          strikeThrough: data?.checked,
        });

        if (index === 0) {
          direction = getDirection(text);
        }

        return <AutoDirText key={uuidV4()} value={child.text} style={childStyles} />;
      });

      return (
        <View
          style={{
            flexDirection: direction === 'rtl' ? 'row-reverse' : 'row',
            alignItems: 'center',
            marginTop: '4px',
            marginLeft: '8px',
          }}
          key={uuidV4()}
        >
          <View style={styles.checkbox}>{data?.checked ? <CheckboxOn /> : <CheckboxOff />}</View>
          {childText}
        </View>
      );
    }

    case 'link':
      return (
        <Link src={getCleanLink(data?.href as string)} key={uuidV4()}>
          {children.map((child) => {
            return <AutoDirText key={uuidV4()} value={(child as CustomText).text} />;
          })}
        </Link>
      );

    case 'package':
    case 'voiceOver':
      return (
        <View style={[styles.column, styles.package]} key={uuidV4()}>
          <View style={[styles.row, styles.primaryItems]}>
            {type.toLowerCase() === 'package' ? <VideoClipIcon /> : <VoiceOverIcon />}
            <AutoDirText value={`${data?.templateType} - ${data?.templateVariant}`} />
          </View>
          {data?.assets?.map((asset) => (
            <AutoDirText
              key={uuidV4()}
              value={asset?.title ?? ''}
              style={{ paddingLeft: '40px', fontSize: '14px' }}
            />
          ))}
        </View>
      );

    case 'camera':
      return (
        <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
          <CameraIcon />
          <AutoDirText
            value={`${data?.templateType} - ${data?.templateVariant}`}
            style={styles.camera}
          />
        </View>
      );

    case 'live':
      return (
        <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
          <LiveIcon />
          <AutoDirText
            value={`${data?.templateType} - ${data?.templateVariant}`}
            style={styles.live}
          />
        </View>
      );

    case 'overlayGraphics':
    case 'fullscreenGraphics':
      return (
        <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
          {type === 'fullscreenGraphics' ? <GraphicsIcon /> : <CGIcon />}
          <AutoDirText
            value={`${data?.templateType} - ${data?.templateVariant}`}
            style={styles.graphics}
          />
        </View>
      );

    case 'mention': {
      return (
        <View key={uuidV4()}>
          <AutoDirText value={`@${data?.mTitle}`} style={styles.mention} />;
        </View>
      );
    }

    case 'horizontal-rule':
      return <View key={uuidV4()} style={styles.horizontalLine} />;

    default:
      return (
        <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
          {typeToIconMap[type as keyof typeof typeToIconMap] ?? null}
          <Text>{type}</Text>
        </View>
      );
  }
};
