import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Text from 'components/text/Text';
import { Box, HStack, VStack } from 'layouts/box/Box';
import defaultStateValues from 'screens/planning/components/status/utils/statusBoards/defaultStateValues';
import transientOptions from 'theme/helpers';
import { ColorVariants } from 'types';

function hexToRgb(hex: string) {
  // Remove the hash if it exists
  hex = hex.replace(/^#/, '');

  // Parse the hex values to RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

export const StyledInstanceWrapper = styled('div', transientOptions)<{
  $hovered: boolean;
  $isInstanceHovered: boolean;
}>`
  ${({ $hovered, $isInstanceHovered, theme }) =>
    $hovered &&
    $isInstanceHovered &&
    css`
      box-shadow: 0 0 0 2px ${theme.palette.dina.statusWarning};
      border-radius: 4px;
    `}
  width: 100%;
  height: 100%;
`;

export const StyledInstanceInnerWrapper = styled('div')`
  min-width: 460px;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const StyledPaper = styled(Paper, transientOptions)<{
  $writeLock?: boolean;
  $readLock?: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${({ theme, $readLock, $writeLock }) => {
    if ($writeLock) return `1px solid ${theme.palette.dina.statusApproved}`;
    if ($readLock) return `1px solid ${theme.palette.dina.statusWarning}`;
    return '1px solid transparent';
  }};
`;

export const HeaderWrapper = styled(VStack, transientOptions)<{
  $backgroundColor: keyof ColorVariants;
  $opacity: number;
}>`
  background-color: ${({ $backgroundColor, theme, $opacity }) => {
    const color =
      theme.palette.dina[$backgroundColor] ??
      theme.palette.dina[defaultStateValues.backgroundColor];
    const rgbColor = hexToRgb(color);
    return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${$opacity})`;
  }};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.dina.dividerLight}`};
`;

export const EditorContentWrapper = styled(Box, transientOptions)<{ $disabled: boolean }>`
  flex: 1;
  height: 100%;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};
`;

export const MetadataHeader = styled('div')`
  ${({ theme }) => css`
    background: ${theme.palette.dina.surfaceCard};
    background-color: ${theme.palette.dina.backgroundResting};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `};
  padding-inline: 12px;
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const FooterWrapper = styled(VStack, transientOptions)<{
  $backgroundColor: keyof ColorVariants;
  $opacity: number;
}>`
  background-color: ${({ $backgroundColor, theme, $opacity }) => {
    const color =
      theme.palette.dina[$backgroundColor] ??
      theme.palette.dina[defaultStateValues.backgroundColor];
    const rgbColor = hexToRgb(color);
    return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${$opacity})`;
  }};
  border-top: ${({ theme }) => `1px solid ${theme.palette.dina.dividerLight}`};
`;

export const HeaderTop = styled(HStack)`
  flex: 1 0 auto;
  width: 100%;
`;

export const HeaderBottom = styled(HStack)`
  flex: 1 0 auto;
  width: 100%;
  padding: 0.5rem;
`;

export const FooterTop = styled(HStack)<{ $backgroundColor: keyof ColorVariants }>`
  flex: 1 0 auto;
  width: 100%;
  padding: 0.25rem 0.5rem;
  border-top: 1px solid
    ${({ theme, $backgroundColor }) =>
      theme.palette.dina[$backgroundColor === 'surfaceCard' ? 'dividerLight' : $backgroundColor]};
`;

export const FooterBottom = styled(HStack)`
  flex: 1 0 auto;
  width: 100%;
`;

export const StyledFooterText = styled(Text)`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const DestinationAvatar = styled('div')`
  width: 32px;
  height: 32px;
  position: relative;
`;

export const ThumbnailImg = styled('img')`
  width: 26px;
  height: 26px;
  border-radius: 2px;
  object-fit: cover;
`;

export const PublishingPointImg = styled('img')`
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const Destination = styled(Typography, transientOptions)<{ $canOpenDestination: boolean }>`
  ${({ theme }) => theme.typography.dina.captionRegular};
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${({ $canOpenDestination = false }) =>
    $canOpenDestination &&
    css`
      cursor: pointer;
      :hover {
        transform: scale(1.08);
        text-decoration: underline;
      }
    `}
`;
