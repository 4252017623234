import { memo, useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { capitalize } from 'lodash';

import { ResourceDetails } from 'hooks/useResourceDetails';
import { Box } from 'layouts/box/Box';
import {
  TabsContent as RTabsContent,
  TabsList as RTabsList,
  TabsRoot as RTabsRoot,
  TabsTrigger as RTabsTrigger,
} from 'lib/tabs';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';

import Planning from './Planning';
import Task from './Tasks';

export const TabsRoot = styled(RTabsRoot)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TabsList = styled(RTabsList)`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding-right: 4px;
`;

const TabsTrigger = styled(RTabsTrigger)`
  ${({ theme }) => theme.typography.dina.tabLabelSmall}
  height: 40px;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  :hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &[data-state='active'] {
    ${({ theme }) => css`
      color: ${theme.palette.dina.highEmphasis};
      box-shadow: inset 0 -2px 0 0 ${theme.palette.dina.highEmphasis};
    `};
  }
`;

const TabsContent = styled(RTabsContent)`
  position: relative;
  outline: none;
  height: 100%;
  flex-grow: 1;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  overflow: hidden;
  &[data-state='active'] {
    display: flex;
  }
`;

const Text = styled('span')`
  cursor: pointer;
  text-decoration: none;
  user-select: none;
`;

export const sidepanelTabs = {
  planning: 'planning',
  tasks: 'tasks',
} as const;

export type SidepanelTabValue = (typeof sidepanelTabs)[keyof typeof sidepanelTabs];

const isValidTab = (value: string): value is SidepanelTabValue =>
  value === 'planning' || value === 'tasks';

const tabs = Object.values(sidepanelTabs).map((value) => ({ label: value }));

interface SidepanelProps {
  resourceDetails: ResourceDetails;
}

function SidePanel({ resourceDetails }: Readonly<SidepanelProps>) {
  const { useSidepanelTab } = useStoryPaneMolecule();
  const [sidepanelTab, setSidepanelTab] = useSidepanelTab();

  const onTabChange = useCallback((tabValue: string) => {
    if (isValidTab(tabValue)) setSidepanelTab(tabValue);
  }, []);

  return (
    <TabsRoot onValueChange={onTabChange} value={sidepanelTab}>
      <TabsList>
        {tabs.map((tab) => (
          <TabsTrigger key={tab.label} value={tab.label} asChild>
            <Text>{capitalize(tab.label)}</Text>
          </TabsTrigger>
        ))}
      </TabsList>
      <Box height="100%" width="100%">
        <TabsContent value={sidepanelTabs.planning}>
          <Planning resourceDetails={resourceDetails} />
        </TabsContent>
        <TabsContent value={sidepanelTabs.tasks}>
          <Task resourceDetails={resourceDetails} />
        </TabsContent>
      </Box>
    </TabsRoot>
  );
}

export default memo(SidePanel);
