import { ColumnDef } from '@tanstack/react-table';

import Actions from 'features/dataTable/components/cells/Actions';
import DateCell from 'features/dataTable/components/cells/DateCell';
import MemberCell from 'features/dataTable/components/cells/MemberCell';
import TitleCell from 'features/dataTable/components/cells/TitleCell';
import ColumnVisibilityMenu from 'features/dataTable/components/ColumnVisibilityMenu';
import { ParsedMemberType } from 'types/members';

interface GridWidgetColumnProps {
  onOpen: (member: ParsedMemberType) => void;
  onRemove: (member: ParsedMemberType) => void;
}

/** Default column definitions. Includes common member fields and action buttons. */
export const getDefaultColumns = ({
  onOpen,
  onRemove,
}: Readonly<GridWidgetColumnProps>): ColumnDef<ParsedMemberType>[] => [
  {
    accessorKey: 'mTitle',
    cell: ({ getValue, row }) => <TitleCell getValue={getValue} row={row} />,
    id: 'visibilityMenu',
    header: ({ table }) => <ColumnVisibilityMenu table={table}>Title</ColumnVisibilityMenu>,
    size: 300,
    enableHiding: false,
  },
  {
    accessorKey: 'mCreatedById',
    cell: ({ getValue }) => <MemberCell getValue={getValue} />,
    header: 'Created by',
    size: 160,
  },
  {
    accessorKey: 'mCreatedAt',
    cell: ({ getValue }) => <DateCell getValue={getValue} />,
    header: 'Created',
    size: 120,
  },
  {
    accessorKey: 'mUpdatedAt',
    cell: ({ getValue }) => <DateCell getValue={getValue} />,
    header: 'Updated',
    size: 120,
  },
  {
    cell: ({ row }) => <Actions row={row} onOpen={onOpen} onRemove={onRemove} />,
    id: 'actions',
    enableHiding: false,
    enableResizing: false,
    enableSorting: false,
    maxSize: 56,
  },
];
