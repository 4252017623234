import { useDrop } from 'react-dnd';

import useToast from 'components/toast/useToast';
import { acceptedMTypes } from 'features/dataTable/types';
import { MemberType } from 'types/graphqlTypes';

import { Dropzone } from './styled';

interface Props {
  itemGroupId?: string;
  itemIds: string[];
  onDrop: (incomingMember: MemberType, itemGroupId: string) => Promise<void>;
  children: React.ReactNode;
}

export default function MemberDropzone({
  itemGroupId,
  itemIds,
  onDrop,
  children,
}: Readonly<Props>) {
  const { toast } = useToast();

  // ─── Drag And Drop Handling ──────────────────────────────────────────
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ['STORY_DRAG'],
    async drop(incomingMember: MemberType) {
      if (!itemGroupId || itemIds.includes(incomingMember.mId as string)) {
        toast({
          title: 'Item already exists',
          description: incomingMember?.mTitle,
          type: 'info',
        });
        return;
      }
      void onDrop(incomingMember, itemGroupId);
    },
    canDrop(incomingMember: MemberType) {
      return Boolean(incomingMember.mType && acceptedMTypes.includes(incomingMember.mType));
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Dropzone ref={(ref: HTMLDivElement) => drop(ref)} isOver={isOver && canDrop}>
      {children}
    </Dropzone>
  );
}
