import { MemberTypeEnum } from 'types/graphqlTypes';

export type ConfigurableActionMTypes =
  | MemberTypeEnum.Story
  | MemberTypeEnum.Note
  | MemberTypeEnum.Rundown
  | MemberTypeEnum.Instance
  | MemberTypeEnum.Pitch
  | MemberTypeEnum.Order
  | MemberTypeEnum.Contact
  | MemberTypeEnum.User;

export const configurableActionMTypes: ConfigurableActionMTypes[] = [
  MemberTypeEnum.Story,
  MemberTypeEnum.Note,
  MemberTypeEnum.Rundown,
  MemberTypeEnum.Instance,
  MemberTypeEnum.Pitch,
  MemberTypeEnum.Order,
  MemberTypeEnum.Contact,
  MemberTypeEnum.User,
];

export type ConfigurableActionMTypeHook = {
  type: ConfigurableActionMTypes;
  functional: boolean;
};

export const configurableActionMTypesHook: ConfigurableActionMTypeHook[] = [
  { type: MemberTypeEnum.Story, functional: true },
  { type: MemberTypeEnum.Pitch, functional: true },
  { type: MemberTypeEnum.Rundown, functional: true },
  { type: MemberTypeEnum.Instance, functional: true },
  { type: MemberTypeEnum.Order, functional: true },
  { type: MemberTypeEnum.Contact, functional: true },
  { type: MemberTypeEnum.Note, functional: false },
  { type: MemberTypeEnum.User, functional: false },
];

export const isConfigurableActionType = (
  obj: MemberTypeEnum | null | undefined,
): obj is ConfigurableActionMTypes => {
  if (!obj) return false;
  return configurableActionMTypes.includes(obj as ConfigurableActionMTypes);
};
