import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Row } from '@tanstack/react-table';
import { capitalize } from 'lodash';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import Tooltip from 'components/tooltip';
import { Center } from 'layouts/box/Box';
import { ParsedMemberType } from 'types/members';

interface Props {
  row: Row<ParsedMemberType>;
  onOpen: (member: ParsedMemberType) => void;
  onRemove: (member: ParsedMemberType) => void;
}

const iconStyles = css`
  cursor: pointer;
  width: 18px;
  height: 18px;
  &:hover path {
    fill-opacity: 1;
  }
`;

const OpenIcon = styled(Open)`
  ${iconStyles}
`;

const RemoveIcon = styled(DeleteIcon)`
  ${iconStyles}
`;

export default function RowActions({ row, onOpen, onRemove }: Readonly<Props>) {
  const member = row.original;

  const {
    original: { mType },
  } = row;

  return (
    <Center gap="6px" height="100%" width="100%">
      <Tooltip title={`Go to ${capitalize(mType)}`}>
        <OpenIcon onClick={() => onOpen(member)} />
      </Tooltip>
      <Tooltip title="Remove">
        <RemoveIcon onClick={() => onRemove(member)} />
      </Tooltip>
    </Center>
  );
}
