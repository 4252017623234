import { type ColumnDef, type Table, type TableMeta } from '@tanstack/react-table';

import { MemberTypeEnum } from 'types/graphqlTypes';
import { type ParsedMemberType } from 'types/members';

export type TableInstance = {
  tableInstance: Table<ParsedMemberType>;
};

/** Accepted member types for the grid widget */
export const acceptedMTypes = [
  MemberTypeEnum.Story,
  MemberTypeEnum.Instance,
  MemberTypeEnum.Rundown,
  MemberTypeEnum.Pitch,
  MemberTypeEnum.Note,
  MemberTypeEnum.User,
  MemberTypeEnum.Contact,
];

export interface DataTableProps {
  data: ParsedMemberType[];
  columnDefinitions: ColumnDef<ParsedMemberType>[];
  tableId: string;
  itemGroupId?: string;
  itemIds: string[];
  /** Can hold data and functions that can be accessed by the table instance */
  meta?: TableMeta<ParsedMemberType>;
}
