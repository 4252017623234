import { useCallback, useContext } from 'react';
import { useCommandState } from 'carloslfu-cmdk-internal';
import { atom, useAtom } from 'jotai';

import { useAddInstanceDialog } from 'components/addInstanceDialog/AddInstanceDialog';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import { useSpaceDialog } from 'components/createNewV3/SpaceDialog';
import UserContext from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { CommandGroup } from 'lib/command';
import { useMyOrdersDialog } from 'store';
import { useCurrentTabType } from 'store/tabs';
import { assertUnreachable } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';

import { Instruction } from '../command-types';
import { CommandItem } from '../CommandItem';

interface FeedInstructionProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ViewTasksItem = ({ doInstruction }: { doInstruction: (val: Instruction) => void }) => {
  const search = useCommandState((state) => state.search);
  if (!search || search.length < 2) return null;
  return (
    <CommandItem
      key="returning"
      onSelect={() => doInstruction('open_tasks')}
      instruction="open_tasks"
      forceMount={'view tasks'.includes(search)}
    >
      View your tasks
    </CommandItem>
  );
};

export interface CommandInstruction {
  label: string;
  onClick: () => void;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const availableInstructions = atom<Record<string, CommandInstruction>>({});

export const useAvailableInstructions = () => useAtom(availableInstructions);

const InstructionGroup = ({ setOpen }: FeedInstructionProps) => {
  const [checkUserRight] = useCheckUserRight();
  const canCreateSpace = checkUserRight('space', 'create', true);
  const canCreateInstance = checkUserRight('instance', 'create', true);
  const [currentTabType] = useCurrentTabType();
  const [commands] = useAvailableInstructions();
  const [, setAddInstance] = useAddInstanceDialog();
  const [, showCreateMemberDialog] = useCreateMemberDialog();
  const [, showSpaceDialog] = useSpaceDialog();
  const [, setShowOrders] = useMyOrdersDialog();
  const { mId: userId } = useContext(UserContext);

  const doInstruction = useCallback(
    (val: Instruction) => {
      switch (val) {
        case 'create_instance': {
          setAddInstance({ text: '' });
          setOpen(false);
          break;
        }
        case 'create_story': {
          showCreateMemberDialog({
            mTitle: '',
            forceShow: true,
            anchorEl: null,
          });
          setOpen(false);
          break;
        }
        case 'create_space': {
          showSpaceDialog({
            show: true,
            type: 'create',
          });
          setOpen(false);
          break;
        }
        case 'open_tasks': {
          setShowOrders({
            resourceId: userId,
            open: true,
            type: 'user',
          });
          break;
        }
        default:
          assertUnreachable(val);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showCreateMemberDialog, setOpen, setShowOrders, setAddInstance],
  );

  return (
    <CommandGroup heading="Commands">
      <CommandItem onSelect={() => doInstruction('create_story')} instruction="create_story">
        Create story or pitch
      </CommandItem>
      {canCreateSpace && (
        <CommandItem onSelect={() => doInstruction('create_space')} instruction="create_space">
          Create space
        </CommandItem>
      )}
      {currentTabType === MemberTypeEnum.Story && canCreateInstance && (
        <CommandItem
          onSelect={() => doInstruction('create_instance')}
          instruction="create_instance"
        >
          Create instance
        </CommandItem>
      )}
      {Object.values(commands).map((command) => (
        <CommandItem
          key={command.label}
          onSelect={() => {
            command.onClick();
            setOpen(false);
          }}
          icon={command.icon}
        >
          {command.label}
        </CommandItem>
      ))}
      <ViewTasksItem doInstruction={doInstruction} />
    </CommandGroup>
  );
};

export default InstructionGroup;
