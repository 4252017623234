import { useEffect } from 'react';
import { ApolloClient } from '@apollo/client';
import styled from '@emotion/styled/macro';

import { ReactComponent as Orders } from 'assets/icons/systemicons/orders.svg';
import NotificationIndicator from 'components/statusIndicators/Notification';
import Tooltip from 'components/tooltip';
import useApolloSubscription from 'hooks/useApolloSubscription';
import { UPDATED_ORDERS } from 'operations/subscriptions/notifyOrderUpdate';
import {
  removeOrderFromCacheByType,
  useGetOrders,
  writeOrderToCacheByType,
} from 'screens/space/api/useGetOrdersAndForms';
import { useMyOrdersDialog } from 'store';
import { RawOrder } from 'types/forms/forms';
import { CrudActionEnum, GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import { getActiveFromOrder } from './OrderGridFull';
interface Props {
  userId: string;
}
interface SubscriptionEvent {
  data: {
    notifyOrderUpdateSubscription: RawOrder;
  };
}

const NotificationWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: -4px;
  pointer-events: none;
`;

const TaskIcon = styled(Orders)`
  cursor: pointer;
  width: 18px;
  height: 18px;
  &:hover path {
    fill-opacity: 1;
  }
`;

function MyOrdersButton({ userId }: Readonly<Props>) {
  const [, setShowOrders] = useMyOrdersDialog();
  const { orders } = useGetOrders(userId, GetOrderEnum.Assignee, TaskStatusEnum.active);
  const [subscribe, unsubscribe] = useApolloSubscription(UPDATED_ORDERS, {
    variables: { mIdSubscribed: userId },
    onSubscriptionData: (data: {
      subscriptionData: SubscriptionEvent;
      client: ApolloClient<object>;
    }) => {
      const { subscriptionData, client } = data;
      const order = subscriptionData.data.notifyOrderUpdateSubscription;
      const active = getActiveFromOrder(order);
      switch (order.crudAction) {
        case CrudActionEnum.Remove:
          removeOrderFromCacheByType(
            client,
            userId,
            order.mId,
            GetOrderEnum.Assignee,
            TaskStatusEnum.active,
          );
          break;
        case CrudActionEnum.Insert:
        case CrudActionEnum.Modify:
          if (order.mAssignee === userId && active === TaskStatusEnum.active) {
            writeOrderToCacheByType(
              client,
              userId,
              order,
              GetOrderEnum.Assignee,
              TaskStatusEnum.active,
              CrudActionEnum.Insert,
            );
          } else if (order.mAssignee === userId && active === TaskStatusEnum.inactive) {
            removeOrderFromCacheByType(
              client,
              userId,
              order.mId,
              GetOrderEnum.Assignee,
              TaskStatusEnum.active,
            );
          } else if (order.mAssignee !== userId) {
            removeOrderFromCacheByType(
              client,
              userId,
              order.mId,
              GetOrderEnum.Assignee,
              TaskStatusEnum.active,
            );
            removeOrderFromCacheByType(
              client,
              userId,
              order.mId,
              GetOrderEnum.Assignee,
              TaskStatusEnum.inactive,
            );
          }
          break;
      }
    },
  });

  useEffect(() => {
    subscribe();
    return () => {
      unsubscribe();
    };
  }, []);

  const handleShowOrders = () => {
    setShowOrders({
      resourceId: userId,
      open: true,
      type: 'user',
    });
  };

  return (
    <Tooltip title="View your active tasks">
      <div style={{ display: 'flex' }}>
        <NotificationWrapper>
          <NotificationIndicator notificationCount={orders.length} />
        </NotificationWrapper>
        <TaskIcon onClick={handleShowOrders} className="skipOverride"></TaskIcon>
      </div>
    </Tooltip>
  );
}

export default MyOrdersButton;
