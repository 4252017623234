import { useState } from 'react';

import Dialog from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';

import { VesselProps } from '../VesselFactory';

export function DialogVessel({ children, open, onClose, title }: Readonly<VesselProps>) {
  const [dimension, setDimension] = useState({
    width: `${window.innerWidth < 720 ? window.innerWidth : '825'}`,
    height: `${window.innerHeight < 1080 ? window.innerHeight : '1080'}`,
  });

  return (
    <DraggableDialog
      open={open}
      onClose={onClose}
      initialPosition={{
        x: 400,
        y: 100,
      }}
      minWidth={500}
      minHeight={500}
      dimension={dimension}
      setDimension={setDimension}
    >
      <Dialog.Header className="dragHandler">{title}</Dialog.Header>
      <Dialog.Body bodyHeight="calc(100% - 38px)" overflow="auto">
        {children}
      </Dialog.Body>
    </DraggableDialog>
  );
}
