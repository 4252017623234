import { css } from '@emotion/react';

import { ReactComponent as SortDown } from 'assets/icons/systemicons/sort_down.svg';
import { ReactComponent as SortUp } from 'assets/icons/systemicons/sort_up.svg';

const iconStyles = css`
  size: 18px;
  width: 18px;
  &:hover path {
    fill-opacity: 1;
  }
`;

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

const IconMap = {
  [SortDirection.ASC]: SortUp,
  [SortDirection.DESC]: SortDown,
};

export const SortIcon = ({ direction }: Readonly<{ direction: SortDirection }>) => {
  const Icon = IconMap[direction];

  return <Icon css={iconStyles} />;
};
