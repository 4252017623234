import { useMemo } from 'react';

import { useGetMdfColumns } from 'features/mdf/useGetMdfColumns';
import { ParsedMemberType } from 'types/members';

import { getDefaultColumns } from './defaultColumnDefinitions';
import useRowActions from './useRowActions';
import { orderColumns } from './utils';

/** Hook that generates the column definitions, combining default columns with MDF columns. */
export default function useGetColumnDefinitions(
  items: ParsedMemberType[],
  boardId: string,
  groupId?: string,
) {
  const actionCallbacks = useRowActions(boardId, groupId);
  const defaultDefinitions = useMemo(() => getDefaultColumns(actionCallbacks), [groupId]);
  const mdfDefinitions = useGetMdfColumns(items);

  // Combine default definitions with MDF definitions.
  const columnDefinitions = useMemo(() => {
    return orderColumns(defaultDefinitions, mdfDefinitions);
  }, [defaultDefinitions, mdfDefinitions]);

  return { columnDefinitions };
}
