import { Content, Label, MenuItem, Separator } from 'components/dropdownMenu/styled';
import {
  DropdownMenu as Menu,
  DropdownMenuPortal as Portal,
  DropdownMenuTrigger as Trigger,
} from 'lib/dropdown';

interface DropdownMenuProps {
  children: React.ReactNode;
}

function DropdownMenu({ children }: Readonly<DropdownMenuProps>) {
  return <Menu>{children}</Menu>;
}

DropdownMenu.Trigger = function DropdownMenuTrigger({ children }: Readonly<DropdownMenuProps>) {
  return (
    <Trigger asChild>
      <span>{children}</span>
    </Trigger>
  );
};

DropdownMenu.Portal = function DropdownMenuPortal({ children }: Readonly<DropdownMenuProps>) {
  return <Portal>{children}</Portal>;
};

DropdownMenu.Content = function DropdownMenuContent({ children }: Readonly<DropdownMenuProps>) {
  return <Content>{children}</Content>;
};

DropdownMenu.Header = function DropdownMenuHeader({ children }: Readonly<DropdownMenuProps>) {
  return (
    <>
      <Label>{children}</Label>
      <Separator />
    </>
  );
};

DropdownMenu.Separator = function DropdownMenuSeparator() {
  return <Separator horizontalInset={true} />;
};

DropdownMenu.Item = function DropdownMenuLabel({ children }: Readonly<DropdownMenuProps>) {
  return <MenuItem>{children}</MenuItem>;
};

DropdownMenu.Footer = function DropdownMenuFooter({ children }: Readonly<DropdownMenuProps>) {
  return (
    <>
      <Separator />
      {children}
    </>
  );
};

export default DropdownMenu;
