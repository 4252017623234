import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import { getIcon, hasAvatar } from 'components/command/command-constants';
import { MemberType } from 'types/graphqlTypes';

interface MemberIconProps {
  member: MemberType;
}

export function MemberIcon({ member }: Readonly<MemberIconProps>) {
  const Icon = getIcon(member);
  const supportsAvatar = member.mType ? hasAvatar.includes(member.mType) : false;

  return (
    <>
      {supportsAvatar ? (
        <Avatar
          variant={member.mType as AvatarVariant}
          imageKey={member.mAvatarKey}
          size={24}
          title={member.mTitle}
        />
      ) : (
        Icon && (
          <Icon style={{ width: '20px', height: '20px', flexShrink: 0 }} className="skipOverride" />
        )
      )}
    </>
  );
}
