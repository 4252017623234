import { useRef } from 'react';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';
import { ReactEditor } from 'slate-react';

import { Destination, EditorValue, Instance, Platform } from 'types';
import { MMetaDataField } from 'types/graphqlTypes';
import { PlatformVariant } from 'utils/mergePlatforms';

export const InstanceScope = createScope<string | null>(null);

export const instanceMolecule = molecule((_getMol, getScope) => {
  getScope(InstanceScope);

  const instanceAtom = atom<Instance | null>(null);
  const savingInstanceAtom = atom(false);
  const hasChangesAtom = atom(false);
  const metadataAtom = atom<MMetaDataField[]>([]);
  const platformAtom = atom<Platform | null>(null);
  const platformVariantAtom = atom<PlatformVariant | null>(null);
  const isCancelledAtom = atom(false);
  const templateKeyAtom = atom('');
  const currentDestinationAtom = atom<Destination | null>(null);
  const disableEditAtom = atom(false);
  const publishingPointAtom = atom((get) => {
    const instance = get(instanceAtom);
    return instance?.mProperties?.platform ?? 'default';
  });
  const hideTemplateOptionsAtom = atom(false);
  const viewAtom = atom<'edit' | 'preview'>('edit');
  const metadataViewAtom = atomWithStorage('editor-metadata-view', true);
  const showCmsEditDialog = atom(false);
  const editorDataAtom = atom<EditorValue | null>(null);
  const skipDownloadAtom = atom(false);
  const lockedByAtom = atom<string>('Someone');
  const lockedByCurrentUserAtom = atom(false);
  const lockingAtom = atom(false);
  const readLockAtom = atom(false);
  const writeLockAtom = atom(false);
  const publishAnchorElAtom = atom<EventTarget | null>(null);
  const editorAtom = atom<ReactEditor | null>(null);
  const readOnlyAtom = atom(false);
  const contentDataAtom = atom<EditorValue | null>(null);
  const contentLoadingAtom = atom(false);
  const contentRefetchAtom = atom<(() => void) | null>(null);
  const durationsAtom = atom<{ clip: string; script: string; total: string }>({
    clip: '',
    script: '',
    total: '',
  });
  const downloadDataAtom = atom<{
    mId: string;
    mRefId: string;
    contentType: string;
    rundownSection: string;
  } | null>(null);
  const showPrintDialog = atom(false);

  const initialContentRef = useRef<EditorValue | null>(null);
  const editorValueRef = useRef<EditorValue | null>(null);
  const writeLockRef = useRef<boolean>(false);
  const instanceRef = useRef<Instance | null>(null);
  const lockedIdRef = useRef<string | null>(null);
  const notificationRef = useRef<HTMLDivElement | null>(null);

  return {
    useInstanceValue: () => useAtomValue(instanceAtom),
    useSetInstance: () => useSetAtom(instanceAtom),
    useIsSavingInstance: () => useAtom(savingInstanceAtom),
    useHasChanges: () => useAtom(hasChangesAtom),
    useMetadata: () => useAtom(metadataAtom),
    usePlatform: () => useAtom(platformAtom),
    usePlatformVariant: () => useAtom(platformVariantAtom),
    useIsCancelled: () => useAtom(isCancelledAtom),
    useTemplateKey: () => useAtom(templateKeyAtom),
    useCurrentDestination: () => useAtom(currentDestinationAtom),
    useDisableEdit: () => useAtom(disableEditAtom),
    usePublishingPointValue: () => useAtomValue(publishingPointAtom),
    useHideTemplateOptions: () => useAtom(hideTemplateOptionsAtom),
    useView: () => useAtom(viewAtom),
    useMetadataView: () => useAtom(metadataViewAtom),
    useShowCmsEditDialog: () => useAtom(showCmsEditDialog),
    useEditorData: () => useAtom(editorDataAtom),
    useSkipDownload: () => useAtom(skipDownloadAtom),
    useLockedBy: () => useAtom(lockedByAtom),
    useLockedByCurrentUser: () => useAtom(lockedByCurrentUserAtom),
    useLocking: () => useAtom(lockingAtom),
    useReadLock: () => useAtom(readLockAtom),
    useWriteLock: () => useAtom(writeLockAtom),
    usePublishAnchorEl: () => useAtom(publishAnchorElAtom),
    useEditor: () => useAtom(editorAtom),
    useReadOnly: () => useAtom(readOnlyAtom),
    useInstanceContentData: () => useAtom(contentDataAtom),
    useInstanceContentLoading: () => useAtom(contentLoadingAtom),
    useInstanceContentRefetch: () => useAtom(contentRefetchAtom),
    useDurations: () => useAtom(durationsAtom),
    useDowloadData: () => useAtom(downloadDataAtom),
    usePrintDialog: () => useAtom(showPrintDialog),
    initialContentRef,
    writeLockRef,
    editorValueRef,
    instanceRef,
    lockedIdRef,
    notificationRef,
  };
});
export const useInstanceMolecule = () => useMolecule(instanceMolecule);
