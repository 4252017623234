import {
  ChangeEvent,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Fade, InputAdornment } from '@material-ui/core';
import { useAtom } from 'jotai';
import { v4 } from 'uuid';

import { Block } from 'api/mdfBlocks/types';
import { useCreateBlock } from 'api/mdfBlocks/useCreateMdfBlock';
import useDeleteBlock from 'api/mdfBlocks/useDeleteMdfBlock';
import { useGetBlocks } from 'api/mdfBlocks/useGetMdfBlocks';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip/Tooltip';
import ContextMenu from 'features/contextMenu';
import { SearchField } from 'features/search/styled';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useFuseSearch from 'hooks/useFuseSearch';
import useInputEvents from 'hooks/useInputEvents';
import { ResourceDetails } from 'hooks/useResourceDetails';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import { useEditorCommands, useEditorCommandsKeyed } from 'store';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { atomWithSessionStorage } from 'utils/atoms/atomWithSessionStorage';

import { BlockWithLabel, Fallback, PLANNING_MENU_ID, PlanningItem } from './ComponentUtils';
import { ToggleList } from './ToggleList';

import {
  CloseIcon,
  FilterWrapper,
  PlusIcon,
  SearchIcon,
  SearchWrapper,
  StyledMenu,
  StyledMenuItem,
} from './styled';

const titleSearchAtom = atomWithSessionStorage<string>('planning-block:titleSearch', '');

interface ControlledPlanningItemProps {
  block: BlockWithLabel;
  resourceId: string;
}
function ControlledPlanningItem({ block, resourceId }: Readonly<ControlledPlanningItemProps>) {
  const [open, setOpen] = useState(false);
  const { useSelectedBlockId, useForceOpenId } = useStoryPaneMolecule();
  const [selectedBlockId, setSelectedBlockId] = useSelectedBlockId();
  const [forceOpenId, setForceOpenId] = useForceOpenId();

  useEffect(() => {
    if (forceOpenId === block.mRefId) {
      setOpen(true);
    }
  }, [forceOpenId]);

  const onSelect = useCallback(
    (blockId: string) => {
      if (selectedBlockId !== blockId) {
        setSelectedBlockId(blockId);
        setForceOpenId(undefined);
      }
    },
    [selectedBlockId],
  );

  return (
    <PlanningItem
      id={`toggle-${block.mRefId}`}
      resourceId={resourceId}
      block={block}
      open={open}
      setOpen={setOpen}
      onSelect={() => onSelect(block.mRefId)}
      selected={selectedBlockId === block.mRefId}
    />
  );
}
interface PlanningProps {
  resourceDetails: ResourceDetails;
}

type MenuClickType = {
  id: string;
  props: {
    block: Block;
  };
};

function Planning({ resourceDetails }: Readonly<PlanningProps>) {
  const { errorToast } = useToast();
  const { deleteBlock } = useDeleteBlock();
  const [itemToDelete, setItemToDelete] = useState<Block | null>(null);
  const { resourceId } = resourceDetails ?? {};
  const { useSelectedBlockId } = useStoryPaneMolecule();
  const [searchText, setSearchText] = useAtom(titleSearchAtom);
  const [editorCommands] = useEditorCommands();
  const { createBlock } = useCreateBlock();
  const [debouncedSetText] = useDebouncedCallback(setSearchText, 300);
  const [editorCommandsKeyed] = useEditorCommandsKeyed();
  const [, setSelectedBlockId] = useSelectedBlockId();

  const filteredCommands = useMemo(
    () => editorCommands.filter((cmd) => cmd.mResourceType !== MemberTypeEnum.OrderForm),
    [editorCommands],
  );

  const [createAnchor, setCreateAnchor] = useState<(EventTarget & SVGSVGElement) | null>();
  const [localValue, setLocalValue] = useState(searchText);

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(
    setSearchText,
    localValue,
    searchText,
  );

  const { blocks, refetch } = useGetBlocks(resourceId);

  const fuseSearch = useFuseSearch();

  const matchFunction = useCallback(
    (list: BlockWithLabel[]) =>
      fuseSearch(list, ['mTitle', 'commandLabel'], localValue?.substring(1) || ''),
    [fuseSearch, localValue],
  );

  const blocksWithType: BlockWithLabel[] = useMemo(() => {
    return blocks.map((block) => {
      const cmd = editorCommandsKeyed[block.mSecId];
      return {
        ...block,
        commandLabel: cmd.mTitle ?? '',
        color: cmd.mColor,
      };
    });
  }, [blocks, editorCommands]);

  const filteredBlocks = useMemo(
    () => matchFunction(blocksWithType),
    [matchFunction, blocksWithType],
  );

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.preventDefault();
    const inputValue = event.target.value;
    void debouncedSetText(inputValue);
    setLocalValue(inputValue);
  }, []);

  const clearSearch: MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      event.preventDefault();
      void debouncedSetText('');
      setLocalValue('');
    },
    [setLocalValue],
  );

  const openCreateMenu: MouseEventHandler<SVGSVGElement> = useCallback((event) => {
    event.preventDefault();
    setCreateAnchor(event.currentTarget);
  }, []);

  const closeCreateMenu = useCallback(() => {
    setCreateAnchor(null);
  }, []);

  const onSelectMenu = useCallback(
    async (event: MouseEvent<HTMLLIElement>, cmd: EditorCommandConfigType) => {
      event.preventDefault();
      closeCreateMenu();
      const newBlock = await createBlock({
        mId: resourceId,
        mRefId: v4(),
        mdfId: cmd.mSecId,
        mSecId: cmd.mRefId,
      });
      setSelectedBlockId(newBlock?.mRefId);
    },
    [resourceId],
  );

  const handleDeleteBlock = useCallback(() => {
    if (itemToDelete) {
      deleteBlock({ mId: itemToDelete.mId, mRefId: itemToDelete.mRefId })
        .catch(errorToast)
        .finally(() => {
          refetch().catch(errorToast);
          setItemToDelete(null);
        });
    }
  }, [itemToDelete, deleteBlock, setItemToDelete, refetch]);

  const handleMenuItemClicked = useCallback(
    (data: MenuClickType) => {
      if (data.id === 'removeBlock') setItemToDelete(data.props.block);
    },
    [setItemToDelete],
  );

  return (
    <>
      <ToggleList
        header={
          <SearchWrapper container height="48px" width="100%">
            <FilterWrapper container height="48px" width="36px">
              <Tooltip title="Add new item">
                <PlusIcon className="skipOverride" onMouseDown={openCreateMenu} />
              </Tooltip>
            </FilterWrapper>
            <SearchField
              id="sidepanel-search-input"
              size="small"
              value={localValue}
              onChange={handleChange}
              fullWidth
              InputProps={{
                placeholder: 'Type to find...',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="skipOverride" />
                  </InputAdornment>
                ),
                endAdornment: localValue && (
                  <InputAdornment position="end" style={{ marginRight: '0px' }}>
                    <CloseIcon className="skipOverride" onMouseDown={clearSearch} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeydown}
              onBlur={handleBlur}
              inputRef={inputRef}
              variant="outlined"
            />
          </SearchWrapper>
        }
        list={
          <>
            {filteredBlocks.length > 0 ? (
              filteredBlocks.map((block) => (
                <ControlledPlanningItem key={block.mRefId} block={block} resourceId={resourceId} />
              ))
            ) : (
              <Fallback label="No planning items..." />
            )}
          </>
        }
      />
      <StyledMenu
        id="create-menu"
        anchorEl={createAnchor}
        open={Boolean(createAnchor)}
        onClose={closeCreateMenu}
        TransitionComponent={Fade}
      >
        {filteredCommands.map((cmd) => {
          return (
            <StyledMenuItem key={cmd.mRefId} onClick={(event) => void onSelectMenu(event, cmd)}>
              {cmd.mTitle}
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
      <ContextMenu
        id={PLANNING_MENU_ID}
        menuItems={[
          {
            id: 'removeBlock',
            label: 'Delete item',
          },
        ]}
        onClick={handleMenuItemClicked}
      />
      <DeleteDialog
        open={Boolean(itemToDelete)}
        onClose={() => setItemToDelete(null)}
        onClick={handleDeleteBlock}
        title="Delete item?"
        message="Are you sure you want to delete this planning item? This cannot be undone!"
      />
    </>
  );
}

export default Planning;
