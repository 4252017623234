import gql from 'graphql-tag';

export default gql`
  query ReadInstance($input: GetMemberInput) {
    instance(input: $input) @client {
      mId
      mRefId
      mTitle
      mThumbnailKey
      mStoryId
      mCreatedAt
      mUpdatedAt
      mUpdatedById
      mPublishingAt
      mContentKey
      mDefaultContentKey
      locked
      mState
      mType
      metadata
      mTemplateId
      isTemplateInstance
      mProperties {
        __typename
        ... on PlatformType {
          platform
          platformKind
          account {
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
      }
      items {
        itemId
        title
        templateType
        templateVariant
        iconUrl
        state
        eventTime
        startTime
        stopTime
        subItems {
          itemId
          title
          templateType
          templateVariant
          iconUrl
        }
      }
      mMetaData {
        key
        value
        manual
        autoValue
      }
      mAssignedMembers {
        mId
        mType
      }
      mRelatedMembers
      assets {
        mId
        assetRefId
        itemId
        itemType
        state
        eventTime
        startTime
        stopTime
      }
    }
  }
`;
