import { MouseEventHandler, useCallback } from 'react';

import { Box } from 'layouts/box/Box';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'lib/collapsible';
import accessibleOnClick from 'utils/accessibleOnClick';

import { Button, DownIcon, ListWrapper, MdfWrapper, RightIcon, SelectionIndicator } from './styled';

export interface ToggleItemProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
  selected: boolean;
  onSelect: (event: React.MouseEvent<HTMLDivElement>) => void;
  id?: string;
  color?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  onContextMenu?: MouseEventHandler<HTMLDivElement>;
}

export function ToggleItem({
  id,
  trigger,
  content,
  color,
  selected,
  onSelect,
  onContextMenu,
  open,
  setOpen,
}: Readonly<ToggleItemProps>) {
  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (setOpen) setOpen(isOpen);
    },
    [setOpen],
  );

  return (
    <SelectionIndicator
      id={id}
      $selected={selected}
      $color={color}
      {...accessibleOnClick(onSelect, 'button')}
    >
      <Collapsible open={open} onOpenChange={handleOpenChange} onContextMenu={onContextMenu}>
        <CollapsibleTrigger asChild>
          <Button>
            <DownIcon className="skipOverride down" />
            <RightIcon className="skipOverride right" />
            {trigger}
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>{content && <MdfWrapper>{content}</MdfWrapper>}</CollapsibleContent>
      </Collapsible>
    </SelectionIndicator>
  );
}

interface ToggleListProps {
  header: React.ReactNode;
  list: React.ReactNode;
}

export function ToggleList({ header, list }: Readonly<ToggleListProps>) {
  return (
    <Box container flexDirection="column" height="100%" width="100%">
      {header}
      <ListWrapper>{list}</ListWrapper>
    </Box>
  );
}
