import { memo, useCallback, useMemo } from 'react';

import MimirDeck from 'features/mimirDeck';
import WidgetWrapper from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { useUpdateWidgetFilters } from 'screens/space/store/widgets';
import { FilterValueType } from 'types/widget';

import { WidgetProps } from './types';
import { getPropFilters } from './utils';

function AssetsWidget({
  layout,
  filters,
  federatedSearchString,
  mId,
  mRefId,
  title,
  writeAccess,
}: Readonly<WidgetProps>) {
  const updateWidgetFilters = useUpdateWidgetFilters();

  const handleUpdateFilters = useCallback((newFilters: FilterValueType) => {
    updateWidgetFilters({ id: mId, filters: newFilters });
  }, []);

  const memoizedFilters = useMemo(() => {
    const propFilters = getPropFilters({ filters });
    return propFilters;
  }, [filters]);

  const activeFilters = useMemo(() => {
    const activeSearch = memoizedFilters?.searchString as string;
    return activeSearch?.length > 0;
  }, [memoizedFilters]);

  return (
    <WidgetWrapper
      layout={layout}
      mId={mId}
      mRefId={mRefId}
      title={title}
      writeAccess={writeAccess}
      filters={memoizedFilters}
      activeFilters={activeFilters}
      type={WIDGETS.ASSETS}
    >
      <MimirDeck
        filters={memoizedFilters}
        federatedSearchString={federatedSearchString}
        onSearchStringChange={(val: string) => handleUpdateFilters({ searchString: val })}
      />
    </WidgetWrapper>
  );
}

export default memo(AssetsWidget);
