import { Table } from '@tanstack/react-table';

import { ReactComponent as Settings } from 'assets/icons/systemicons/columns.svg';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import { Checkbox } from 'components/dropdownMenu/styled';
import { HStack } from 'layouts/box/Box';
import { ParsedMemberType } from 'types/members';

interface VisibilityMenuProps {
  table: Table<ParsedMemberType>;
  children: React.ReactNode;
}

export default function ColumnVisibilityMenu({ table, children }: Readonly<VisibilityMenuProps>) {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <HStack justifyContent="flex-start">
          <Settings style={{ height: '18px', width: '18px' }} />
        </HStack>
      </DropdownMenu.Trigger>
      {children}
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          <DropdownMenu.Header>Choose columns to show</DropdownMenu.Header>
          <DropdownMenu.Item>
            <label>
              <Checkbox
                {...{
                  type: 'checkbox',
                  checked: table.getIsAllColumnsVisible(),
                  onChange: table.getToggleAllColumnsVisibilityHandler(),
                }}
              />
              {'All'}
            </label>
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          {table.getAllLeafColumns().map((column) => {
            if (column.id === 'visibilityMenu' || column.id === 'actions') return null;

            return (
              <DropdownMenu.Item key={column.id}>
                <label>
                  <Checkbox
                    {...{
                      type: 'checkbox',
                      checked: column.getIsVisible(),
                      onChange: column.getToggleVisibilityHandler(),
                    }}
                  />
                  {(column.columnDef.header as string) ?? column.id}
                </label>
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
}
