import { memo, useCallback, useEffect, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import useIntersectionObserver from '@react-hook/intersection-observer';

import Radio from 'components/buttons/radioButton';
import { ToolbarIcons } from 'components/command/command-constants';
import { CommandToolbarProps, Option, Order, SortOption } from 'components/command/command-types';
import { isFiltering } from 'components/command/command-utils';
import {
  ColumnHeader,
  StyledText,
  ToolbarItem,
  ToolbarItemColumn,
  ToolbarItemLabel,
} from 'components/command/toolbar/styled';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Popper from 'components/shared/popper';
import Tooltip from 'components/tooltip/Tooltip';
import { Box, HStack } from 'layouts/box/Box';
import { CommandGroup } from 'lib/command';
import { configurableActionMTypes } from 'screens/main/components/header/navbar/settings/components/integrations/constants';
import { useEditorCommandsKeyed } from 'store';
import { AssignedMember } from 'types';
import {
  ConfigurableActionMTypes,
  MemberType,
  MemberTypeEnum,
  SortDirection,
  SortType,
} from 'types/graphqlTypes';

import SearchItem from './SearchItem';

import { IntersectionObserver } from './styled';

const orders: Order[] = [
  {
    key: 'desc',
    label: 'Descending',
  },
  {
    key: 'asc',
    label: 'Ascending',
  },
];

const options: Option[] = [
  {
    key: 'best',
    label: 'Best matches',
  },
  {
    key: 'updatedAt',
    label: 'Last updated',
  },
  {
    key: 'createdAt',
    label: 'Created',
  },
  {
    key: 'scheduledAt',
    label: 'Scheduled',
  },
  {
    key: 'totalInstances',
    label: 'Total instances',
  },
];

const sortToLabel: Record<SortType | 'best', string> = {
  best: 'Sort',
  createdAt: 'created',
  updatedAt: 'updated',
  scheduledAt: 'scheduled',
  totalInstances: 'total instances',
};

const getSortLabel = (sortedBy: SortOption, ord: SortDirection) => {
  if (sortedBy === 'best') return 'Sort';
  return `Sorted by ${sortToLabel[sortedBy]}, ${ord}`;
};

interface SearchResultProps {
  openItem: (item: MemberType) => void;
  setOpen: (val: boolean) => void;
  loadMore: () => Promise<void>;
  loading: boolean;
  searchValue: string;
  sortedBy: SortOption;
  order: SortDirection;
  items: MemberType[];
  members: Record<string, AssignedMember>;
  hideHeader?: boolean;
  toolbarState: CommandToolbarProps;
  setToolbarState?: React.Dispatch<React.SetStateAction<CommandToolbarProps>>;
}

const getKey = (item: MemberType) => {
  if (
    item.mType && [
      MemberTypeEnum.Note,
      MemberTypeEnum.Asset,
      MemberTypeEnum.Order.includes(item.mType),
    ]
  ) {
    return item.mRefId;
  }
  return item.mId;
};

const SearchResults = ({
  items,
  sortedBy,
  toolbarState,
  setToolbarState,
  searchValue,
  order,
  loadMore,
  openItem,
  setOpen,
  loading,
  members,
  hideHeader = false,
}: SearchResultProps) => {
  const [editorCommands] = useEditorCommandsKeyed();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);
  const { isIntersecting } = useIntersectionObserver(ref, { pollInterval: 500 });
  const { show } = useContextMenu({ id: 'memberMenu' });

  const updateState = useCallback(
    (val: Partial<CommandToolbarProps>) => {
      if (setToolbarState)
        setToolbarState((prevState) => {
          const newValue = {
            ...prevState,
            ...val,
          };
          return {
            ...newValue,
            isFiltering: isFiltering(newValue),
          };
        });
    },
    [setToolbarState],
  );

  const setSortOption = useCallback(
    (val: SortOption) => {
      updateState({ sortBy: val });
      setPopperEl(null);
    },
    [updateState, setPopperEl],
  );

  const setSortDirection = useCallback(
    (val: SortDirection) => {
      updateState({ order: val });
      setPopperEl(null);
    },
    [updateState, setPopperEl],
  );

  const onContextMenu = useCallback(
    (item: MemberType, ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (item.mType && configurableActionMTypes.includes(item.mType as ConfigurableActionMTypes)) {
        ev.preventDefault();
        ev.stopPropagation();
        show({
          event: ev,
          props: {
            member: item,
            close: () => setOpen(false),
          },
        });
      }
    },
    [show],
  );

  useEffect(() => {
    if (isIntersecting) {
      void loadMore();
    }
  }, [isIntersecting, loadMore]);

  return (
    <>
      <CommandGroup
        heading={
          hideHeader ? undefined : (
            <HStack justifyContent="space-between">
              <div>Search results</div>
              <Tooltip title="Sort by">
                <StyledText
                  variant="caption"
                  onClick={(ev: React.MouseEvent<HTMLDivElement>) => setPopperEl(ev.currentTarget)}
                >
                  <ToolbarIcons.Sort />
                  {getSortLabel(sortedBy, order)}
                </StyledText>
              </Tooltip>
            </HStack>
          )
        }
      >
        {items.length === 0 && (
          <Box padding="12px">{loading ? 'Searching ..' : 'No results found'}</Box>
        )}
        {items.map((item, i) => {
          const key = getKey(item);
          return (
            <SearchItem
              item={item}
              value={searchValue.replace(/"/g, '\\"')}
              index={i}
              key={key}
              sortedBy={sortedBy}
              members={members}
              openItem={openItem}
              onContextMenu={onContextMenu}
              commandById={editorCommands}
            />
          );
        })}
        {items.length > 0 && <IntersectionObserver ref={setRef} />}
      </CommandGroup>
      <Popper anchorEl={popperEl} position="bottom" style={{ pointerEvents: 'auto' }}>
        <ToolbarItem>
          <ToolbarItemColumn>
            <ColumnHeader variant="overline">Sort results by</ColumnHeader>
            {options.map((op) => (
              <HStack key={op.key} onClick={() => setSortOption(op.key)} margin="0 0 4px 0">
                <Radio selected={toolbarState.sortBy === op.key} size={24} />
                <ToolbarItemLabel variant="listItemLabel">{op.label}</ToolbarItemLabel>
              </HStack>
            ))}
          </ToolbarItemColumn>
          <ToolbarItemColumn className={toolbarState.sortBy === 'best' ? 'disabled' : ''}>
            <ColumnHeader variant="overline">Sort direction</ColumnHeader>
            {orders.map((op) => (
              <HStack
                key={op.key}
                onClick={() => (toolbarState.sortBy !== 'best' ? setSortDirection(op.key) : {})}
                margin="0 0 4px 0"
              >
                <Radio selected={toolbarState.order === op.key} size={24} />
                <ToolbarItemLabel variant="listItemLabel">{op.label}</ToolbarItemLabel>
              </HStack>
            ))}
          </ToolbarItemColumn>
          <CloseIcon onClick={() => setPopperEl(null)} />
        </ToolbarItem>
      </Popper>
    </>
  );
};

export default memo(SearchResults);
