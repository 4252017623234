import { useCallback } from 'react';
import { isObject } from 'lodash';

import useToast from 'components/toast/useToast';
import useGoToOrderResource, {
  isNavigatable,
} from 'features/orderForm/components/useGoToOrderResource';
import useCreateConvo from 'hooks/useCreateConvo';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useNavigateToAsset from 'hooks/useNavigateToAsset';
import {
  getMappedToOtherMType,
  isContentType,
  useIsMessageHubOpen,
  useSelectedConversationId,
} from 'store';
import { useSetPreview } from 'store/preview';
import { AssignedMember } from 'types';
import { AccountType, MemberType, MemberTypeEnum } from 'types/graphqlTypes';

interface AccountId {
  account: AccountType;
}

const isAccountType = (obj: unknown): obj is AccountId => {
  if (!isObject(obj)) return false;
  const maybeAccountId = obj as Partial<AccountId>;
  return maybeAccountId.account !== undefined;
};

const getAccountId = (obj: unknown): string | undefined => {
  if (isAccountType(obj)) {
    return obj.account.accountId ?? undefined;
  }
};

const useOpenMember = () => {
  const setPreview = useSetPreview();
  const { navigateTo } = useDinaNavigate();
  const { errorToast } = useToast();
  const { goToResource } = useGoToOrderResource();
  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();
  const navigateToAsset = useNavigateToAsset();

  const openItem = useCallback(
    (val: MemberType) => {
      if (!val.mId || !val.mType) return;
      const { mId, mType, mSecId, mRefId, mProperties } = val;
      const accountId = getAccountId(mProperties);
      const navigateToMappedMType = getMappedToOtherMType(mType);
      const actualType = navigateToMappedMType ?? mType;

      if (isContentType(actualType)) {
        navigateTo(actualType, mId);
      } else if (actualType === MemberTypeEnum.Asset && val.mRefId) {
        navigateToAsset(val);
      } else if (mProperties?.platform === 'linear' && accountId) {
        if (accountId) navigateTo('rundown', accountId);
      } else if (actualType === MemberTypeEnum.Instance && mSecId) {
        navigateTo('story', mSecId, {
          tab: 'instances',
          entityId: mId,
        });
      } else if (actualType === MemberTypeEnum.Note) {
        navigateTo('story', mId, {
          tab: 'notes',
          entityId: mRefId,
        });
      } else if (actualType === MemberTypeEnum.Order) {
        if (isNavigatable(val.mResourceType))
          goToResource(val.mId, val.mResourceType).catch(errorToast);
      } else if (actualType === MemberTypeEnum.Block) {
        navigateTo('story', mId, {
          tab: 'blocks',
        });
      } else if (
        [
          MemberTypeEnum.Contact,
          MemberTypeEnum.User,
          MemberTypeEnum.Team,
          MemberTypeEnum.Department,
        ].includes(actualType)
      ) {
        setPreview(val);
      }
    },
    [
      navigateTo,
      setPreview,
      navigateToAsset,
      createConvo,
      errorToast,
      setSelectedConvoId,
      setIsMessageHubOpen,
    ],
  );

  const openChat = useCallback((val: MemberType) => {
    if (
      val?.mType &&
      [(MemberTypeEnum.User, MemberTypeEnum.Team, MemberTypeEnum.Department)].includes(val.mType)
    ) {
      createConvo(val as unknown as AssignedMember, (convo) => {
        setSelectedConvoId(convo?.mId);
        setIsMessageHubOpen(true);
      }).catch(errorToast);
    }
  }, []);

  return { openItem, openChat };
};

export default useOpenMember;
