import { Dispatch, SetStateAction } from 'react';

import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';

import { MiniMember } from './forms/forms';
import { EditorValue } from './editor';

export type MProperties = {
  [k: string]: string | number | object | boolean | null | undefined;
};

export type AuditPayloadType = {
  mId: string;
  mTitle?: string;
  mType?: string;
  mContentKey?: string;
  oldState?: string;
  newState?: string;
};

export type AccountType = {
  __typename?: 'AccountType';
  accountId?: string | null;
  accountRefId?: string;
  accountLogo?: string;
  accountTitle?: string;
  accountUrl?: string;
  id?: string;
  orderType?: RundownOrderTypeEnum;
  recurrence?: Recurrence;
};

export type ActiveRundownInstance = {
  __typename?: 'ActiveRundownInstance';
  mId: string;
  startTime?: string;
};

export enum PublishedStateEnum {
  Assigned = 'assigned',
  Deleted = 'deleted',
  Draft = 'draft',
  Failed = 'failed',
  Pending = 'pending',
  Processed = 'processed',
  Scheduled = 'scheduled',
  Unassigned = 'unassigned',
  Unknown = 'unknown',
  Play = 'play',
  Stop = 'stop',
  Ready = 'ready',
  Notready = 'notready',
}

export enum AuditActionEnum {
  Added = 'added',
  Archived = 'archived',
  Assigned = 'assigned',
  Attached = 'attached',
  Changed = 'changed',
  Created = 'created',
  Deleted = 'deleted',
  Failure = 'failure',
  Publishing = 'publishing',
  Removed = 'removed',
  Synchronized = 'synchronized',
  Scheduled = 'scheduled',
  Unassigned = 'unassigned',
  Unattached = 'unattached',
  Version = 'version',
  StateChanged = 'stateChanged',
}

export type AuditLabelType = {
  __typename?: 'AuditLabelType';
  mLabelId: string;
  mMessage?: string;
  mCreatedAt?: string;
  mUpdatedAt?: string;
  mUpdatedBy?: string;
};

export type AuditType = {
  __typename?: 'AuditType';
  mId: string;
  mEventId: string;
  mUpdatedAt?: string;
  mActionId?: AuditActionEnum;
  mSeverity?: string;
  mStatusCode?: string;
  mType?: string;
  mMessage?: string;
  mUpdatedBy?: string;
  mPayload?: AuditPayloadType;
  mLabels?: AuditLabelType[];
};

export type ProviderType = {
  __typename?: 'ProviderType';
  id?: string;
  requestId?: string;
  updateEndpoint?: string;
  embeddedEndpoint?: string;
  previewEndpoint?: string;
  message?: string;
  state?: string;
  eventTime?: string;
  startTime?: string;
  stopTime?: string;
};

export type AutomationTemplateConfigType = MProperties & {
  __typename?: 'AutomationTemplateConfigType';
  version?: string;
  description?: string;
  defaultSet?: string;
  resources?: AutomationResourcesType;
  templateSets?: AutomationTemplateSetType[];
};

export type AssetType = {
  __typename?: 'AssetType';
  duration?: string;
  type?: string;
  headline?: string;
  byline?: string;
  renditions?: RenditionType[];
};

export type BookmarkType = {
  __typename?: 'BookmarkType';
  bookmarkedId?: string;
  bookmarkedType?: string;
};

export type CategoryType = {
  __typename?: 'CategoryType';
  categoryId?: string;
  label?: string;
  score?: number;
};

export type CommandType = {
  __typename?: 'CommandType';
  name?: string;
  arguments?: MMetaDataField[];
};

export type ConfigType = {
  __typename?: 'ConfigType';
  key?: string;
  name?: string;
  type?: string;
  value?: string;
  values?: string[];
  alternatives?: Alternative[];
  treeAlternatives?: string[][];
};

export type ContactType = MProperties & {
  __typename?: 'ContactType';
  dateOfBirth?: string;
  email?: string;
  firstName: string;
  jobTitle?: string;
  phone?: string;
  surname: string;
  username?: string;
  notListed?: boolean;
  readSpeed?: string;
};

export enum ConversationTypeEnum {
  Direct = 'direct',
  Group = 'group',
  Channel = 'channel',
  All = 'all',
  Team = 'team',
  Department = 'department',
  Space = 'space',
  Story = 'story',
  Pitch = 'pitch',
  Instance = 'instance',
  Rundown = 'rundown',
  Assignment = 'assignment',
  Notification = 'notification', // Remove when message and system notifications are separated
}

export type ConversationType = {
  __typename?: 'ConversationType';
  mTitle?: string;
  mId?: string;
  mRefId?: string;
  mType?: MemberTypeEnum;
  convoType?: ConversationTypeEnum;
  mCreatedAt?: string;
  mUpdatedAt?: string;
  mUpdatedById?: string;
  mCreatedById?: string;
  mAssignedMembers?: AssignedMemberType[];
  mIdSubscribed?: string;
  message?: string;
  convoReadAt?: string;
  convoLeaveOption?: boolean;
  muted?: boolean;
};

export type FolderType = {
  __typename?: 'FolderType';
  mId: string;
  mRefId: string;
  mType: string;
  mTitle?: string;
  mParentId?: string;
  mProperties?: PlatformType;
  items?: MemberType[];
};

export type FeedItem = {
  __typename?: 'FeedItem';
  providers?: string;
  provider?: string;
  mId?: string;
  version?: number;
  mPublishedAt?: string;
  infosource?: string;
  section?: string;
  priority?: string;
  pubstatus?: string;
  ednotes?: string;
  newscodes?: string[];
  mediatopics?: string[];
  firstcreated?: string;
  versioncreated?: string;
  mTitle?: string;
  byline?: string;
  mContent?: string;
  mDescription?: string;
  location?: string;
  embargoed?: string;
  copyrightnotice?: string;
  usageterms?: string;
  href?: string;
  assets?: AssetType[];
  storyList?: string;
  revision?: number;
  located?: string;
  language?: string;
  slugline?: string;
  freeText?: string;
  assetTypes?: string[];
  keywords?: string;
  places?: PlaceType[];
  people?: PersonType[];
  priorities?: string;
  languages?: string;
};

export type PlaceType = {
  __typename?: 'PlaceType';
  name?: string;
  rel?: string;
  geojson?: GeoType;
};

export type GeoType = {
  __typename?: 'GeoType';
  type?: string;
  coordinates?: number[];
};

export type PersonType = {
  __typename?: 'PersonType';
  name?: string;
  rel?: string;
};

export type FeedsConnection = {
  __typename?: 'FeedsConnection';
  items?: FeedItem[];
  nextToken?: string;
};

export type ColumnType = {
  __typename?: 'ColumnType';
  id: string;
  title: string;
  members: MemberType[];
};

export type BoardType = {
  __typename?: 'BoardType';
  mId: string;
  columns?: ColumnType[];
  mRefId?: string;
  mType?: string;
};

export type BoardUpdatedType = {
  __typename?: 'BoardUpdatedType';
  crudAction: UpdateBoardActionEnum;
  boardId: string;
  columnId?: string;
  title?: string;
  memberIds?: string[] | null;
  memberId?: string | null;
  member?: MemberType | null;
};

export type StoryResult = MemberType | RestrictedErrorType;

type GroupPermissionType = {
  __typename?: 'GroupPermissionType';
  mId: string;
  mRefId: string;
  mTitle?: string | null;
  mProperties?: GroupPolicy | null;
};

export type GroupPolicy = MProperties & {
  __typename?: 'GroupPolicy';
  policies?: ResourcePolicy[];
  userCount?: number;
};

export type ResourcePolicy = {
  __typename?: 'ResourcePolicy';
  resourceName: string;
  permissions?: Permission[];
};

export type ScheduleEntryProperties = MProperties & {
  __typename?: 'ScheduleEntryProperties';
  description?: string;
  project?: MemberType;
  agency?: MemberType;
  client?: MemberType;
  contact?: MemberType;
  location?: string;
  address?: string;
  instructions?: string;
  status?: string;
  metadata?: MMetaDataField[];
};

export type NoteProperties = MProperties & {
  __typename?: 'NoteProperties';
  pinned?: boolean;
  labelId?: string;
};

export type InstanceDestination = {
  __typename?: 'InstanceDestination';
  destination?: string;
  mType?: string;
};

export type Item = {
  __typename?: 'Item';
  itemId?: string;
  subItems?: Item[];
  templateType?: string;
  templateVariant?: string;
  title?: string;
  state?: string;
  eventTime?: string;
  startTime?: string;
  stopTime?: string;
  iconUrl?: string;
};

export type MediaAssetType = {
  __typename?: 'MediaAssetType';
  mId?: string;
  assetRefId?: string;
  assetTitle?: string;
  itemId?: string;
  itemType?: string;
  state?: string;
};

export type MemberType = {
  __typename?: 'MemberType';
  mPlannedDuration?: string;
  highlight?: string;
  assets?: MediaAssetType[];
  configs?: ConfigType[];
  crudAction?: CrudActionEnum;
  disabledAt?: string;
  images?: string;
  isRestricted?: boolean;
  isTemplateInstance?: boolean;
  itemBymId?: string;
  itemBymRefId?: string;
  items?: Item[];
  itemType?: string;
  locked?: string;
  mAction?: ActionEnum;
  mAssetId?: string;
  mAssignees?: string;
  mLastLogout?: string;
  mLastLogin?: string;
  mAssignedMembers?: AssignedMemberType[];
  mAvatarKey?: string;
  mAvatarUrl?: string;
  mBookmarks?: BookmarkType[];
  mCategories?: CategoryType[];
  mContent?: EditorValue | null;
  mContentUrl?: string;
  mContentKey?: string;
  mCreatedAt?: string;
  mCreatedById?: string;
  mCurrentContentKey?: string;
  mDefaultContentKey?: string;
  mDescription?: string;
  mExternalId?: string;
  mediaType?: string;
  mId?: string;
  mLastVersion?: string;
  mMetaData?: MMetaDataField[];
  mOrder?: string[];
  mPreorder?: string[];
  mPriority?: string;
  mProperties?: MProperties;
  mPublishingAt?: string;
  mPublishingEnd?: string;
  mRefId?: string;
  mRelatedMembers?: string;
  mResourceId?: string;
  mSessionExpiresAt?: string;
  mState?: string;
  mStoryId?: string;
  mStudios?: string;
  mSyncActive?: string;
  mSyncProviders?: SyncProviderType[];
  mTemplateId?: string;
  mThumbnailKey?: string;
  mThumbnailUrl?: string;
  mTime?: string;
  mTitle?: string;
  mType?: MemberTypeEnum;
  mUpdatedAt?: string;
  mUpdatedById?: string;
  mUsers?: MemberType[];
  mChildren?: Child[];
  mRoot?: boolean;
  mSeen?: string;
  membersOfType?: MemberTypeEnum;
  message?: string;
  user?: UserType;
  userId?: string;
  version?: number;
  parentId?: string;
  provider?: string;
  rundownId?: string;
  mIdSubscribed?: string;
  planStatusViewDate?: string;
  publishingDate?: string;
  weekView?: string;
  monthView?: string;
  accessToken?: string;
  useProxy?: boolean;
  command?: CommandType;
  groupPermissions?: GroupPermissionType[];
  mosObj?: string;
  ttl?: number;
  messageAssign?: string;
  metadata?: string;
  mSecId?: string;
  mAllowedEntities?: {
    mType?: string[];
  };
  mInvokeUrl?: string;
  mProvider?: string;
  permissions?: MemberPermissions;
  mdfId?: string;
  mRundownTemplateId?: string;
  mFormId?: string;
  mTertId?: string;
  mTertRefId?: string;
  mActive?: boolean;
  slashCommand?: string;
  singleOnly?: boolean;
  mResourceType?: MemberTypeEnum;
  mIcon?: string;
  mColor?: string;
  totalInstances?: number;
};

export enum SearchItemTypeEnum {
  order_form = 'order_form',
  rnd_ins = 'rnd_ins',
  space = 'space',
  story = 'story',
  scheduleentry = 'scheduleentry',
  str_ins = 'str_ins',
  archived_story = 'archived_story',
  archived_pitch = 'archived_pitch',
  archived_res_story = 'archived_res_story',
  archived_res_pitch = 'archived_res_pitch',
  pitch = 'pitch',
  res_story = 'res_story',
  res_pitch = 'res_pitch',
  note = 'note',
  asset = 'asset',
  order = 'order',
  instance = 'instance',
  archived_instance = 'archived_instance',
  marker = 'marker',
  user = 'user',
  contact = 'contact',
  team = 'team',
  department = 'department',
  rundown = 'rundown',
  draft = 'draft',
  block = 'block',
}

export type FieldValue = string | boolean | number | string[] | string[][] | DateRange;
export type Metadata = Record<string, FieldValue>;

export type SortDirection = 'asc' | 'desc';
export type SortType = 'createdAt' | 'scheduledAt' | 'updatedAt' | 'totalInstances';

export type DateRangeQL = {
  from: string;
  to: string | null;
};

export type RangeBy = {
  createdAt?: DateRangeQL;
  updatedAt?: DateRangeQL;
  scheduledAt?: DateRangeQL;
};

export type SearchItemInput = {
  metaDataFilter?: string; // stringified json
  metaDataFilterByType?: string; // stringified json, exclusive with normal filter
  assignedMemberIds?: string[];
  assignedMemberIdsMustOccur?: string[];
  createdByIds?: string[];
  mIds?: string[];
  mdfId?: string;
  mTitle?: string;
  mStates?: string[];
  mTypes?: SearchItemTypeEnum[];
  searchString?: string;
  perPagelimit?: number;
  searchAfter?: string;
  orderBy?: Partial<Record<SortType, SortDirection>>;
  rangeBy?: RangeBy;
  platformTypes?: string[];
  semanticSearch?: boolean;
  isScheduled?: boolean;
};

export type ReadWritePermission = {
  name: string;
  groups: string[];
};

export type MemberPermissions = {
  write: ReadWritePermission[];
  read: ReadWritePermission[];
};

export type MemberTypeMThumbnailKeyArgs = {
  linearVariant?: MemberTypeEnum;
};

export type MemberTypeMThumbnailUrlArgs = {
  linearVariant?: MemberTypeEnum;
};

export type MetadataFieldParameterType = {
  __typename?: 'MetadataFieldParameterType';
  id?: string;
};

export type MetadataFormColumnDefType = {
  __typename?: 'MetadataFormColumnDefType';
  variant: string;
  columns?: MetadataFormViewColumnType[];
};

export type MetadataFormDefaultViewType = {
  __typename?: 'MetadataFormDefaultViewType';
  type: string;
  defaultView: string;
  variant: string;
};

export type MetadataFormFieldType = {
  __typename?: 'MetadataFormFieldType';
  id: string;
  name: string;
  type: string;
  label?: string;
  options?: MetadataFormOptionType[];
  description?: string;
  value?: string;
  function?: string;
  parameters?: MetadataFieldParameterType[];
  format?: string;
  mostag?: string;
  mandatory?: boolean;
  readonly?: boolean;
  defaultValue?: string;
};

export type AddColumnInput = {
  mId: string;
  mTitle?: string;
  members?: string[];
};

export type UpdateBoardInput = {
  crudAction: UpdateBoardActionEnum;
  boardId: string;
  column?: AddColumnInput;
  columnId?: string;
  columnOrder?: string[];
  title?: string | null;
  memberId?: string | null;
  memberOrder?: string[] | null;
};

export enum AuthType {
  None = 'NONE',
  Apikey = 'APIKEY',
}

export type Auth = {
  __typename?: 'Auth';
  type: AuthType;
};

export enum ListActionType {
  Admin = 'ADMIN',
  User = 'USER',
}

export type GetIntegrationInput = {
  type: ListActionType;
  integrationType: IntegrationEnum;
};

export type PluginUserType = Omit<IntegrationUserType, 'mTypes'>;

export type IntegrationUserType = Pick<
  IntegrationType,
  '__typename' | 'iconUrl' | 'mRefId' | 'mTitle' | 'mDescription' | 'mTypes' | 'connectedIds'
>;

export type ConfigurableActionMTypes =
  | MemberTypeEnum.Story
  | MemberTypeEnum.Note
  | MemberTypeEnum.Rundown
  | MemberTypeEnum.Instance
  | MemberTypeEnum.Pitch
  | MemberTypeEnum.Order
  | MemberTypeEnum.Contact
  | MemberTypeEnum.User;

export enum IntegrationEnum {
  CustomAction = 'customAction',
  SearchPlugin = 'searchPlugin',
  Webhook = 'webhook',
  EndPoint = 'endpoint',
}

export type IntegrationType = {
  __typename?: 'IntegrationType';
  integrationType: IntegrationEnum;
  mId: string;
  mRefId: string;
  externalId: string;
  mTitle: string;
  mUpdatedAt: string;
  mActive: boolean;
  auth: Auth;
  mAllowedEntities?: {
    mType?: string[];
  };
  mInvokeUrl: string | null;
  mDescription: string | null;
  mProvider: string | null;
  mTypes: ConfigurableActionMTypes[] | null;
  endpoint: string | null;
  iconUrl: string | null;
  connectedIds?: string[] | null;
};

export type MetadataFormType = MProperties & {
  __typename?: 'MetadataFormType';
  defaultView?: string;
  defaultViews?: MetadataFormDefaultViewType[];
  fields?: MetadataFormFieldType[];
  views?: MetadataFormViewType[];
};

export type MetadataFormOptionType = {
  __typename?: 'MetadataFormOptionType';
  id: string;
  title?: string;
  value?: string;
  icon?: string;
};

export type MetadataFormViewColumnType = {
  __typename?: 'MetadataFormViewColumnType';
  id: string;
  width?: string;
  name?: string;
  label?: string;
  group?: string;
  readonly?: boolean;
  severity?: string;
  style?: string;
  columnId?: string;
};

export type MetadataFormViewType = {
  __typename?: 'MetadataFormViewType';
  id: string;
  name: string;
  type: string;
  columndefs?: MetadataFormColumnDefType[];
};

export type DeleteAssetResponse = {
  __typename?: 'DeleteAssetResponse';
  mId: string;
  mRefId: string;
  mAssetId?: string;
  mProvider?: string;
  message: string;
  usages?: MemberType[];
  isSuccessful: boolean;
};

export enum ItemTypeEnum {
  Video = 'video',
  Image = 'image',
  Audio = 'audio',
  File = 'file',
  Undefined = 'undefined',
}

export type UploadAssetInput = {
  mId: string;
  itemType: ItemTypeEnum;
  filename: string;
  fileType: string;
  mRefId?: string;
  mTitle?: string;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateStorySync?: MemberType;
  addAuditError?: AuditType;
  addFeedToStory?: MemberType;
  logout?: MemberType;
  archiveStory?: MemberType;
  archiveMember?: MemberType;
  batchDeleteMemberRelation?: MemberType[];
  batchUpdateInstance?: MemberType[];
  createAssets?: MemberType[];
  createContact?: MemberType;
  createContentTemplate?: MemberType;
  createConversation?: ConversationType;
  createMessage?: MessageType;
  updateMessage?: MessageType;
  deleteMessage?: MessageType;
  crudScheduleEntry?: MemberType;
  createFolder?: FolderType;
  createInstance?: MemberType;
  createMasterRundown?: Rundown;
  createMember?: MemberType;
  createMembers?: MemberType[];
  createNote?: MemberType;
  createPlaceholder?: MemberType;
  createPitch?: MemberType;
  createRundownFromTemplate?: Rundown;
  createRundownInstance?: MemberType;
  createSession?: MemberType;
  createStoryForMember?: MemberType;
  createStoryFromFeed?: MemberType;
  createStoryFromPitch?: MemberType;
  deleteAsset?: DeleteAssetResponse;
  postEvent?: MemberType;
  serviceRequest?: ServiceRequestResponse;
  deleteMember?: MemberType[];
  deleteMemberRelation?: MemberType;
  deleteNote?: MemberType;
  deleteSingleMember?: MemberType;
  disableStorySync?: MemberType;
  lockInstance?: MemberType;
  lockMember?: MemberType;
  moveInstanceToRundown?: MemberType[];
  notifyBreakingFeed?: MemberType;
  notifyConversationUpdate?: ConversationType;
  notifyScheduleEntry?: MemberType;
  notifyFeedUpdate?: FeedItem;
  notifyRundownInstanceUpdate?: MemberType;
  notifyRundownUpdate?: Rundown;
  notifyUser?: MemberType;
  notifyMemberUpdate?: MemberType;
  restrictMember?: MemberType;
  unarchiveMember?: MemberType;
  unlockMember?: MemberType;
  updateContact?: MemberType;
  updateContent?: UpdateContentType;
  updateInstance?: MemberType;
  updateMember?: MemberType;
  updateConversation?: ConversationType;
  updateConvoReadAt?: ConversationType;
  createConversationUser?: ConversationType;
  leaveConversation?: ConversationType;
  updateNote?: MemberType;
  updateRelatedMembers?: MemberType;
  updateRundown?: Rundown;
  updateRundownSync?: Rundown;
  uploadMemberImage?: MemberType;
  userUtils?: MemberType[];
  uploadAsset?: MemberType;
  updateGroupPermissions?: MemberType;
  updateGroupUsers?: MemberType;
  updatePushToken?: MemberType;
  updateUserStoryLayout?: MemberType;
  updateForm?: MemberType;
  updateBoard: BoardUpdatedType;
};

export type MutationActivateStorySyncArgs = {
  input?: ActivateStorySyncInput;
};

export type MutationAddAuditErrorArgs = {
  input?: AddAuditErrorInput;
};

export type MutationAddFeedToStoryArgs = {
  input?: AddFeedToStoryInput;
};

export type MutationLogoutArgs = {
  input?: UpdateMemberInput;
};

export type MutationArchiveStoryArgs = {
  input?: ArchiveStoryInput;
};

export type MutationArchiveMemberArgs = {
  input?: ArchiveStoryInput;
};

export type MutationBatchDeleteMemberRelationArgs = {
  input?: BacthDeleteMemberRelationInput;
};

export type MutationBatchUpdateInstanceArgs = {
  input?: BatchUpdateInstanceInput;
};

export type MutationCreateAssetsArgs = {
  input?: CreateAssetsInput;
};

export type MutationCreateContactArgs = {
  input?: CreateContactInput;
};

export type MutationCreateContentTemplateArgs = {
  input?: CreateContentTemplateInput;
};

export type MutationCreateConversationArgs = {
  input?: CreateConversationInput;
};

export type MutationCreateMessageArgs = {
  input?: CreateMessageInput;
};

export type MutationUpdateMessageArgs = {
  input?: CreateMessageInput;
};

export type MutationDeleteMessageArgs = {
  input?: CreateMessageInput;
};

export type MutationCrudScheduleEntryArgs = {
  input?: CruDscheduleEntryInput;
};

export type MutationCreateFolderArgs = {
  input?: CreateFolderInput;
};

export type MutationCreateInstanceArgs = {
  input?: CreateInstanceInput;
};

export type MutationCreateMasterRundownArgs = {
  input?: CreateMasterRundownInput;
};

export type MutationCreateMemberArgs = {
  input?: CreateMemberInput;
};

export type MutationCreateMembersArgs = {
  input?: CreateMembersInput;
};

export type MutationCreateNoteArgs = {
  input?: CreateNoteInput;
};

export type MutationCreatePlaceholderArgs = {
  input?: CreatePlaceholderInput;
};

export type MutationCreatePitchArgs = {
  input?: CreatePitchInput;
};

export type MutationCreateRundownFromTemplateArgs = {
  input?: CreateRundownInput;
};

export type MutationCreateRundownInstanceArgs = {
  input?: CreateRundownInstanceInput;
};

export type MutationCreateSessionArgs = {
  input?: CreateSessionInput;
};

export type MutationCreateStoryForMemberArgs = {
  input?: CreateStoryForMemberInput;
};

export type MutationCreateStoryFromFeedArgs = {
  input?: CreateStoryFromFeedInput;
};

export type MutationCreateStoryFromPitchArgs = {
  input?: CreateStoryFromPitchInput;
};

export type MutationDeleteAssetArgs = {
  input?: DeleteAssetInput;
};

export type MutationPostEventArgs = {
  input?: PostEventInput;
};

export type MutationServiceRequestArgs = {
  input?: ServiceRequestInput;
};

export type MutationDeleteMemberArgs = {
  input?: DeleteMemberInput;
};

export type MutationDeleteMemberRelationArgs = {
  input?: DeleteMemberRelationInput;
};

export type MutationDeleteNoteArgs = {
  input?: DeleteSingleMemberInput;
};

export type MutationDeleteSingleMemberArgs = {
  input?: DeleteSingleMemberInput;
};

export type MutationDisableStorySyncArgs = {
  input?: DisableStorySyncInput;
};

export type MutationLockInstanceArgs = {
  input?: LockInstanceInput;
};

export type MutationLockMemberArgs = {
  input?: LockMemberInput;
};

export type MutationMoveInstanceToRundownArgs = {
  input?: MoveInstanceToRundownInput;
};

export type MutationNotifyBreakingFeedArgs = {
  input?: BreakingFeedInput;
};

export type MutationNotifyConversationUpdateArgs = {
  input?: UpdateConversationInput;
  mIdSubscribed?: string;
};

export type MutationNotifyScheduleEntryArgs = {
  input?: NotifyScheduleEntryInput;
  mIdSubscribed?: string;
};

export type MutationNotifyFeedUpdateArgs = {
  input?: UpdateFeedInput;
};

export type MutationNotifyRundownInstanceUpdateArgs = {
  input?: UpdateMemberInput;
  userId?: string;
};

export type MutationNotifyRundownUpdateArgs = {
  input?: UpdateRundownInput;
};

export type MutationNotifyUserArgs = {
  input?: UpdateMemberInput;
  message?: string;
  userId?: string;
};

export type MutationNotifyMemberUpdateArgs = {
  input?: UpdateMemberInput;
  noteInput?: UpdateNoteInput;
  mIdSubscribed?: string;
};

export type MutationRestrictMemberArgs = {
  input?: RestrictMemberInput;
};

export type MutationUnarchiveMemberArgs = {
  input?: ArchiveStoryInput;
};

export type MutationUnlockMemberArgs = {
  input?: UnlockMemberInput;
};

export type MutationUpdateContactArgs = {
  input?: UpdateContactInput;
};

export type MutationUpdateContentArgs = {
  input?: UpdateContentInput;
};

export type MutationUpdateInstanceArgs = {
  input?: UpdateInstanceInput;
};

export type MutationUpdateMemberArgs = {
  input?: UpdateMemberInput;
};

export type MutationUpdateConversationArgs = {
  input?: UpdateConversationInput;
};

export type MutationUpdateConvoReadAtArgs = {
  input?: UpdateConversationInput;
};

export type MutationCreateConversationUserArgs = {
  input?: UpdateConversationInput;
};

export type MutationLeaveConversationArgs = {
  input?: LeaveConversationInput;
};

export type MutationUpdateNoteArgs = {
  input?: UpdateNoteInput;
};

export type MutationUpdateRelatedMembersArgs = {
  input?: UpdateRelatedMembersInput;
};

export type MutationUpdateRundownArgs = {
  input?: UpdateRundownInput;
};

export type MutationUpdateRundownSyncArgs = {
  input?: UpdateRundownInput;
};

export type MutationUploadMemberImageArgs = {
  input?: UploadMemberImageInput;
};

export type MutationUserUtilsArgs = {
  input?: UserUtilsInput;
};

export type MutationUploadAssetArgs = {
  input?: UploadAssetInput;
};

export type MutationUpdateGroupPermissionsArgs = {
  input?: UpdateGroupPermissionsInput;
};

export type MutationUpdateGroupUsersArgs = {
  input?: UpdateGroupUserInput;
};

export type MutationUpdatePushTokenArgs = {
  input?: UpdatePushTokenInput;
};

export type MutationUpdateUserStoryLayoutArgs = {
  input?: UpdateUserStoryLayoutInput;
};

export type MutationUpdateFormArgs = {
  input?: UpdateFormInput;
};

export type MutationUpdateBoardArgs = {
  input?: UpdateBoardInput;
};

export type NotifyUserType = {
  __typename?: 'NotifyUserType';
  crudAction?: CrudActionEnum;
  mContent?: string;
  mId?: string;
  mTitle?: string;
  mType?: MemberTypeEnum;
  message?: string;
  userId?: string;
};

export type AssignedMemberType = {
  __typename?: 'AssignedMemberType';
  mId: string;
  mType: MemberTypeEnum;
  mTitle?: string;
};

export type PaginatedMemberType = {
  __typename?: 'PaginatedMemberType';
  items?: MemberType[];
  total?: number;
  nextToken?: string;
  aggregations?: Aggregation[];
};

export type PaginatedItemType = {
  __typename?: 'PaginatedItemType';
  items: MemberType[];
  total: number;
  nextToken: string;
};

export type Aggregation = {
  __typename?: 'Aggregation';
  name?: string;
  buckets?: Bucket[];
};

export type Bucket = {
  __typename?: 'bucket';
  doc_count?: number;
  key?: string;
  key_as_string?: string;
  items?: MemberType[];
};

export type PaginatedConversationType = {
  __typename?: 'PaginatedConversationType';
  items?: ConversationType[];
  total?: number;
  nextToken?: string;
};

export type PaginatedMessageType = {
  __typename?: 'PaginatedMessageType';
  items?: MessageType[];
  total?: number;
  nextToken?: string;
};

export type AuditAssetType = AuditPayloadType & {
  __typename?: 'AuditAssetType';
  mId?: string;
  mTitle?: string;
  mType?: string;
  mStoryId?: string;
  mAssetId?: string;
  mMediaType?: string;
};

export type AuditMemberType = AuditPayloadType & {
  __typename?: 'AuditMemberType';
  mId?: string;
  mTitle?: string;
  mType?: string;
};

export type AuditVersionType = AuditPayloadType & {
  __typename?: 'AuditVersionType';
  mContentKey?: string;
};

export type JsonType = AuditPayloadType & {
  __typename?: 'JsonType';
  value?: string;
};

export type StringType = AuditPayloadType & {
  __typename?: 'StringType';
  value?: string;
};

export type AuditPublishingType = AuditPayloadType & {
  __typename?: 'AuditPublishingType';
  platform?: string;
  account?: AccountType;
  provider?: ProviderType;
  mPublishingAt?: string;
};

export type Permission = {
  __typename?: 'Permission';
  action: string;
  access?: AccessTypeEnum;
};

export type PlatformSection = {
  __typename?: 'PlatformSection';
  name?: string;
  id?: string;
  blocks?: string[];
};

export type PlatformSectionConfig = {
  __typename?: 'PlatformSectionConfig';
  block?: string;
  mMetaData?: MMetaDataField[];
};

export type PlatformStructure = {
  __typename?: 'PlatformStructure';
  name?: string;
  id?: string;
  variant?: string;
  sections?: PlatformSection[];
  config?: PlatformSectionConfig[];
  blocks?: string[];
  allowVideoInPhotogallery?: boolean;
};

export type PlatformType = MProperties & {
  __typename?: 'PlatformType';
  account?: AccountType;
  accounts?: AccountType[];
  id?: string;
  platform?: string;
  platformKind?: string;
  platformAccount?: string;
  platformIcon?: string;
  platformStructure?: PlatformStructure;
  provider?: ProviderType;
  scheduledAt?: string;
  publishedAt?: string;
  updatedAt?: string;
  pinned?: boolean;
};

export type RestrictedErrorType = {
  __typename?: 'RestrictedErrorType';
  mId?: string;
  mRefId?: string;
  mType?: MemberTypeEnum;
  message?: string;
};

export type RenditionType = {
  __typename?: 'RenditionType';
  href?: string;
  mimetype?: string;
  uri?: string;
  duration?: string;
  videoaspectratio?: string;
  format?: string;
  height?: string;
  width?: string;
  filename?: string;
  rendition?: string;
  sizeinbytes?: string;
  fileextension?: string;
  orientation?: string;
  previewUri?: string;
  thumbnailUri?: string;
  videoPreviewUri?: string;
  type?: string;
};

export type JobActionType = {
  __typename?: 'JobActionType';
  action: string;
  method?: string;
  properties?: MMetaDataField[];
};

export enum InstanceActionEnum {
  OnCreated = 'onCreated',
  OnAssigned = 'onAssigned',
  OnVersion = 'onVersion',
  OnUpdated = 'onUpdated',
  OnDeleted = 'onDeleted',
  OnArchived = 'onArchived',
  OnPublishAssigned = 'onPublishAssigned',
  OnPublishScheduled = 'onPublishScheduled',
  OnPublishPending = 'onPublishPending',
  OnPublishProcessed = 'onPublishProcessed',
  OnPublishFailure = 'onPublishFailure',
}

export enum UserUtilsActionEnum {
  Disable = 'disable',
  Delete = 'delete',
  Restore = 'restore',
  RemoveMessages = 'removeMessages',
  RemoveReferances = 'removeReferances',
}

export type InstanceActionType = {
  __typename?: 'InstanceActionType';
  action: InstanceActionEnum;
  jobs?: JobActionType[];
};

export type InstanceConfigType = MProperties & {
  __typename?: 'InstanceConfigType';
  actions?: InstanceActionType[];
};

export type StateActionsType = {
  __typename?: 'StateActionsType';
  onEnter?: JobActionType[];
  onExit?: JobActionType[];
};

export type InstanceStateType = {
  __typename?: 'InstanceStateType';
  id: string;
  name?: string;
  description?: string;
  icon?: string;
  backgroundColor?: string;
  actions?: StateActionsType;
};

export type InstanceStateRefType = {
  __typename?: 'InstanceStateRefType';
  id: string;
};

export type InstanceStateViewType = {
  __typename?: 'InstanceStateViewType';
  id: string;
  name?: string;
  states?: InstanceStateRefType[];
};

export type StatusViewType = MProperties & {
  __typename?: 'StatusViewType';
  defaultView?: string;
  states?: InstanceStateType[];
  views?: InstanceStateViewType[];
};

export type UserStoryLayoutProperties = MProperties & {
  __typename?: 'UserStoryLayoutProperties';
  openInstances?: string;
};

export type ServiceResponse = {
  __typename?: 'ServiceResponse';
};

export type ServiceRequestResponse = {
  __typename?: 'ServiceRequestResponse';
  provider: string;
  status: number;
  ok: boolean;
  statusText?: string;
  body?: string;
  service?: ServiceResponse;
};

export type Query = {
  __typename?: 'Query';
  getAudits?: AuditType[];
  batchGetMembers?: MemberType[];
  getAllMembers?: MemberType[];
  getAnalytics?: CategoryType[];
  getAsset?: MemberType;
  getAssets?: MemberType[];
  getBoard?: BoardType;
  getContentTemplates?: MemberType[];
  getConversationsOfUser?: ConversationType[];
  getUnreadConversations?: ConversationType[];
  getConversationsOfConvoType?: PaginatedConversationType;
  getFeeds?: FeedsConnection;
  getFolders?: FolderType[];
  getMember?: MemberType;
  getMemberFromId?: MemberType[];
  getMembers?: MemberType[];
  getMembersByPublishingDate?: MemberType[];
  getMembersByUpdatedAtDate?: PaginatedMemberType;
  getMembersOf?: MemberType[];
  getMembersOftype?: MemberType[];
  getMembersPagination?: PaginatedMemberType;
  getMessagesOfConversation?: PaginatedMessageType;
  getNotes?: MemberType[];
  getPlatform?: MemberType;
  getPlatforms?: MemberType[];
  getScheduleResources?: MemberType[];
  /** @deprecated More generic query needed: use getSignedUrl */
  getS3Content?: S3ContentType;
  getSignedUrl?: string;
  getSignedCookie?: SignedCookieType;
  getResourceBookings?: MemberType[];
  getRundown?: Rundown;
  getRundowns?: Rundown[];
  getRundownsByPublishingDate?: Rundown[];
  getSummary?: SummaryType;
  getStory?: StoryResult;
  exportContent?: ExportContentType;
  searchApi?: PaginatedMemberType;
  getGroupUsers?: PaginatedMemberType;
  getMetadataForms?: MemberType[];
  getLatestAppVersion?: AppVersionType;
  getMembersOfTypeBySecId?: MemberType[];
};

export type QueryGetAuditsArgs = {
  input?: AuditInput;
};

export type QueryBatchGetMembersArgs = {
  input?: BatchGetMembersInput;
};

export type QueryGetAnalyticsArgs = {
  input?: CreateStoryInput;
};

export type QueryGetAssetArgs = {
  input?: GetAssetInput;
};

export type QueryGetAssetsArgs = {
  input?: GetMemberInput;
};

export type QueryGetContentTemplatesArgs = {
  mId?: string;
};

export type QueryGetConversationsOfUserArgs = {
  input?: ConversationsOfUserInput;
};

export type QueryGetUnreadConversationsArgs = {
  input?: ConversationsOfUserInput;
};

export type QueryGetConversationsOfConvoTypeArgs = {
  input?: GetConversationsOfConvoTypeInput;
  limit?: number;
  nextToken?: string;
};

export type QueryGetFeedsArgs = {
  input?: GetFeedsInput;
  searchIndex?: string;
  dateRange?: DateRangeQL;
  limit?: number;
};

export type QueryGetMemberArgs = {
  input?: GetMemberInput;
};

export type QueryGetMemberFromIdArgs = {
  input?: GetMemberInput;
};

export type QueryGetBoardArgs = {
  input?: GetBoardInput;
};

export type QueryGetMembersArgs = {
  input?: GetMemberInput;
};

export type QueryGetMembersByPublishingDateArgs = {
  input?: GetMembersByPublishingDateInput;
};

export type QueryGetMembersByUpdatedAtDateArgs = {
  input?: GetMembersByPublishingDateInput;
  limit?: number;
  nextToken?: string;
};

export type QueryGetMembersOfArgs = {
  input?: GetMemberInput;
};

export type QueryGetMembersOftypeArgs = {
  input?: GetMemberOfTypeInput;
};

export type QueryGetMembersPaginationArgs = {
  input?: GetMemberInput;
  limit?: number;
  nextToken?: string;
};

export type QueryGetMessagesOfConversationArgs = {
  input?: GetMessagesOfConversationInput;
  limit?: number;
  nextToken?: string;
};

export type QueryGetNotesArgs = {
  input?: GetNotesInput;
};

export type QueryGetPlatformArgs = {
  input?: GetPlatformInput;
};

export type QueryGetPlatformsArgs = {
  input?: GetPlatformsInput;
};

export type QueryGetScheduleResourcesArgs = {
  input?: GetScheduleResourcesInput;
};

export type QueryGetS3ContentArgs = {
  input?: GetS3ContentInput;
};

export type QueryGetSignedUrlArgs = {
  input?: GetSignedUrlInput;
};

export type QueryGetResourceBookingsArgs = {
  input?: GetResourceBookingsInput;
};

export type QueryGetRundownArgs = {
  input?: GetRundownInput;
};

export type QueryGetRundownsArgs = {
  mId?: string;
};

export type QueryGetRundownsByPublishingDateArgs = {
  input?: GetMembersByPublishingDateInput;
};

export type QueryGetSummaryArgs = {
  input?: GetSummaryType;
};

export type QueryGetStoryArgs = {
  input?: GetMemberInput;
};

export type QueryExportContentArgs = {
  input?: ExportContentInput;
};

export type QuerySearchApiArgs = {
  filter?: SearchFilter;
  nextToken?: string;
  limit?: number;
  aggregations?: AggregationInput[];
};

export type QueryGetGroupUsersArgs = {
  input?: GetGroupUserInput;
};

export type QueryGetMetadataFormsArgs = {
  input?: GetMemberInput;
};

export type Recurrence = {
  __typename?: 'Recurrence';
  duration?: string;
  rRule?: string;
  start?: string;
  startTime?: string;
  timeZone?: string;
  dailyExclusive?: boolean;
};

export type AppVersionType = {
  __typename?: 'AppVersionType';
  minVersion?: string;
  latestVersion?: string;
  forceUpdate?: boolean;
};

export type AutomationRequiresType = {
  __typename?: 'AutomationRequiresType';
  video?: boolean;
  audio?: boolean;
  graphics?: boolean;
};

export type AutomationResourcesType = {
  __typename?: 'AutomationResourcesType';
  audioFaders?: AuomationTemplateFieldType[];
  auxiliaries?: AuomationTemplateFieldType[];
  effects?: AuomationTemplateFieldType[];
  graphicsDestinations?: AuomationTemplateFieldType[];
  mixerInputs?: AuomationTemplateFieldType[];
  routerDestinations?: AuomationTemplateFieldType[];
  routerSources?: AuomationTemplateFieldType[];
  serverChannels?: AuomationTemplateFieldType[];
};

export type ExportContentType = {
  __typename?: 'ExportContentType';
  mId?: string;
  mRefId?: string;
  contentType?: string;
  mTitle?: string;
  data?: number[];
  jsonData?: string;
};

export type S3ContentType = {
  __typename?: 'S3ContentType';
  mId?: string;
  mRefId?: string;
  mContent?: string;
};

export type SignedCookieType = {
  __typename?: 'SignedCookieType';
  cookies?: string;
};

export type Rundown = {
  __typename?: 'Rundown' | 'RestrictedErrorType';
  duration?: string;
  mCoverPhotoKey?: string;
  mCoverPhotoUrl?: string;
  mCreatedAt?: string;
  mId: string;
  mOrder?: string[];
  mPlannedDuration?: string;
  mPrePlannedDuration?: string;
  mPreparingState?: RundownStateEnum;
  mPreorder?: string[];
  mProperties?: MProperties;
  mPublishingAt?: string;
  mRefId?: string;
  mState?: RundownStateEnum;
  mStudios?: string;
  mThumbnailKey?: string;
  mThumbnailUrl?: string;
  mTitle?: string;
  mType?: MemberTypeEnum;
  mType_mState_mUpdatedAt?: string;
  mUpdatedAt?: string;
  mUpdatedById?: string;
  mRundownTemplateId?: string;
  recurrence?: Recurrence;
  platformKind?: string;
  startTime?: string;
  version?: number;
  mMetaData?: MMetaDataField[];
  mSyncProviders?: SyncProviderType[];
  rundownPublishingDate?: string;
  permissions?: RundownPermissionsType;
};

export type RundownPermissionsType = {
  read: string[];
  write: string[];
};

export type RundownPermissionsInput = {
  read: string[];
  write: string[];
};

export type RundownPlaybackState = {
  __typename?: 'RundownPlaybackState';
  state?: string;
  startTime?: string;
  stopTime?: string;
  eventTime?: string;
  currentInstance?: ActiveRundownInstance;
};

export type RundownStateType = MProperties & {
  // __typename?: 'RundownStateType';
  ready?: RundownPlaybackState;
  preparing?: RundownPlaybackState;
};

export type S3Object = {
  __typename?: 'S3Object';
  bucket: string;
  key: string;
  region: string;
};

export type Subscription = {
  __typename?: 'Subscription';
  notifyBreakingFeedSubscription?: MemberType;
  notifyConversationSubscription?: ConversationType;
  notifyMemberUpdateSubscription?: MemberType;
  notifyUser?: MemberType;
  scheduleEntrySubscription?: MemberType;
  updateFeedSubscription?: FeedItem;
  updatePlanStatusViewSubscription?: MemberType;
  updateWeekViewSubscription?: MemberType;
  updateMonthViewSubscription?: MemberType;
  updateDayViewSubscription?: MemberType;
  updateRundownInstanceSubscription?: MemberType;
  updateRundownSubscription?: Rundown;
  createRundownsSubscription?: Rundown;
  updateRundownHourViewSubscription?: Rundown;
  updatedContent?: UpdateContentType;
  updateContentChat?: MessageType;
  updatedBoard?: BoardUpdatedType;
};

export type SubscriptionNotifyConversationSubscriptionArgs = {
  mIdSubscribed?: string;
};

export type SubscriptionNotifyMemberUpdateSubscriptionArgs = {
  mIdSubscribed?: string;
};

export type SubscriptionNotifyUserArgs = {
  userId?: string;
};

export type SubscriptionScheduleEntrySubscriptionArgs = {
  mIdSubscribed?: string;
};

export type SubscriptionUpdateFeedSubscriptionArgs = {
  filters?: string;
};

export type SubscriptionUpdatePlanStatusViewSubscriptionArgs = {
  planStatusViewDate?: string;
};

export type SubscriptionUpdateWeekViewSubscriptionArgs = {
  weekView?: string;
};

export type SubscriptionUpdateMonthViewSubscriptionArgs = {
  monthView?: string;
};

export type SubscriptionUpdateDayViewSubscriptionArgs = {
  publishingDate?: string;
};

export type SubscriptionUpdateRundownInstanceSubscriptionArgs = {
  userId?: string;
};

export type SubscriptionUpdateRundownSubscriptionArgs = {
  mId?: string;
  mRefId?: string;
};

export type SubscriptionUpdateRundownHourViewSubscriptionArgs = {
  rundownPublishingDate?: string;
};

export type SubscriptionUpdatedContentArgs = {
  mId?: string;
};

export type SubscriptionUpdateContentChatArgs = {
  contentIds?: string;
};

export type SubscriptionUpdatedBoardArgs = {
  boardId: string;
};

export type SummaryType = {
  __typename?: 'SummaryType';
  mContent: string;
  mSummarize?: string;
  mSummarizeLines: number;
};

export type SyncProviderType = {
  __typename?: 'SyncProviderType';
  mId: string;
  provider?: string;
  mMetaData?: MMetaDataField[];
  endpoint?: string;
};

export type AuomationTemplateFieldType = {
  __typename?: 'AuomationTemplateFieldType';
  name?: string;
  type?: string;
  value?: string;
  options?: string;
};

export type AutomationTemplateSetType = {
  __typename?: 'AutomationTemplateSetType';
  name?: string;
  defaultSet?: boolean;
  created?: string;
  templates?: AutomationTemplateType[];
};

export type AutomationTemplateType = {
  __typename?: 'AutomationTemplateType';
  name?: string;
  type?: string;
  variant?: string;
  description?: string;
  transition?: AuomationTemplateFieldType;
  defaultVariant?: boolean;
  disabled?: boolean;
  requires?: AutomationRequiresType;
  fields?: AuomationTemplateFieldType[];
};

export type SessionType = MProperties & {
  // __typename?: 'SessionType';
  sessionExpirationTime?: string;
  refreshTokenExpirationTime?: string;
  idlePeriod1ExpirationTime?: string;
  idlePeriod2ExpirationTime?: string;
};

export type TestData = {
  __typename?: 'TestData';
  userDetails?: string;
  userId?: string;
};

export type UpdateUserType = {
  mId: string;
  mRefId: string;
  username?: string;
  groups?: string[];
  newGroups?: string[];
  removedGroups?: string[];
  notListed?: boolean;
  errorToast: (err: unknown, description?: string) => void;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
};

export type UpdateContentType = {
  __typename?: 'UpdateContentType';
  mContent?: string;
  mId?: string;
  mUpdatedAt?: string;
  updatedBy?: string;
};

export type MItem = {
  __typename?: 'mItem';
  itemType?: string;
};

export type MessageType = {
  __typename?: 'MessageType';
  contentIds?: string;
  mId?: string;
  mRefId?: string;
  mType?: string;
  mTitle?: string;
  mContent?: string;
  mCreatedAt?: string;
  mUpdatedAt?: string;
  mCreatedById?: string;
  mUpdatedById?: string;
  convoType?: string;
};

export type MMetaDataField = {
  __typename?: 'mMetaDataField';
  key: string;
  value?: string;
  mostag?: string;
  manual?: boolean;
  autoValue?: string;
};

export type Identity = {
  dateCreated?: number;
  issuer?: string;
  primary?: boolean;
  providerName?: string;
  providerType?: string;
  userId?: string;
};

export type UserType = {
  __typename?: 'userType';
  email?: string;
  userName?: string;
  provider?: string;
  identities?: [Identity];
  status?: string;
};

export enum ActionEnum {
  Create = 'create',
  Delete = 'delete',
  Read = 'read',
  Update = 'update',
}

export enum AggregationTypeEnum {
  DateHistogram = 'date_histogram',
}

export enum AssetTypeEnum {
  Audio = 'audio',
  Video = 'video',
  Image = 'image',
}

export enum GetOrderEnum {
  Resource = 'RESOURCE',
  Owner = 'OWNER',
  Assignee = 'ASSIGNEE',
  Space = 'SPACE',
  Form = 'FORM',
}

export enum TaskStatusEnum {
  active = 'active',
  inactive = 'inactive',
  all = 'all',
}

export enum CrudActionEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Insert = 'INSERT',
  Modify = 'MODIFY',
  Read = 'READ',
  Remove = 'REMOVE',
  Update = 'UPDATE',
  Update_Type = 'UPDATE_TYPE', // only use when there is any entity conversion i.e(pitch->story)
  Move = 'MOVE',
  None = 'NONE',
  Float = 'FLOAT',
  Unfloat = 'UNFLOAT',
  Currentitem = 'CURRENTITEM',
}

export enum UpdateBoardActionEnum {
  AddColumn = 'ADD_COLUMN',
  RemoveColumn = 'REMOVE_COLUMN',
  RenameColumn = 'RENAME_COLUMN',
  ReorderColumns = 'REORDER_COLUMNS',
  AddMember = 'ADD_MEMBER',
  RemoveMember = 'REMOVE_MEMBER',
  ReorderMembers = 'REORDER_MEMBERS',
}

export enum ImageTypeEnum {
  Cover = 'cover',
  Thumbnail = 'thumbnail',
}

export enum MemberTypeEnum {
  Asset = 'asset',
  Booking = 'booking',
  Block = 'block',
  Bookmark = 'bookmark',
  CluStr = 'clu_str',
  CluUsr = 'clu_usr',
  Cluster = 'cluster',
  Config = 'config',
  Contact = 'contact',
  Contenttemplate = 'contenttemplate',
  DailyNote = 'dailyNote',
  DepClu = 'dep_clu',
  DepRnd = 'dep_rnd',
  DepStr = 'dep_str',
  DepTea = 'dep_tea',
  DepUsr = 'dep_usr',
  DepIns = 'dep_ins',
  DepPitch = 'dep_pitch',
  Department = 'department',
  Draft = 'draft',
  Folder = 'folder',
  Group = 'group',
  Instance = 'instance',
  Job = 'job',
  MarStr = 'mar_str',
  Market = 'market',
  Marker = 'marker',
  Note = 'note',
  OrgDep = 'org_dep',
  OrgUsr = 'org_usr',
  Organization = 'organization',
  Order = 'order',
  OrderForm = 'order_form',
  Mdf = 'mdf',
  Platform = 'platform',
  Resource = 'resource',
  RndIns = 'rnd_ins',
  RndRnd = 'rnd_rnd',
  RndUsr = 'rnd_usr',
  Rundown = 'rundown',
  Rundowntemplate = 'rundowntemplate',
  ResourceBooking = 'resourceBooking',
  Session = 'session',
  Space = 'space',
  SpaceView = 'space_view',
  SpaceContact = 'space_contact',
  Story = 'story',
  Scheduleentry = 'scheduleentry',
  StrCon = 'str_con',
  StrIns = 'str_ins',
  StrJob = 'str_job',
  TeaClu = 'tea_clu',
  TeaCon = 'tea_con',
  TeaRnd = 'tea_rnd',
  TeaStr = 'tea_str',
  TeaUsr = 'tea_usr',
  TeaIns = 'tea_ins',
  TeaPitch = 'tea_pitch',
  Team = 'team',
  User = 'user',
  UsrData = 'usr_data',
  UsrCon = 'usr_con',
  UsrIns = 'usr_ins',
  UsrJob = 'usr_job',
  UsrPitch = 'usr_pitch',
  UsrStr = 'usr_str',
  ArchivedDepartment = 'archived_department',
  ArchivedTeam = 'archived_team',
  ArchivedStory = 'archived_story',
  ArchivedPitch = 'archived_pitch',
  ArchivedInstance = 'archived_instance',
  ArchivedRundown = 'archived_rundown',
  ArchivedResStory = 'archived_res_story',
  ArchivedResPitch = 'archived_res_pitch',
  ArchivedUser = 'archived_user',
  DisabledUser = 'disabled_user',
  Pitch = 'pitch',
  Convo = 'convo',
  ConUsr = 'con_usr',
  Message = 'message',
  ResStory = 'res_story',
  ResPitch = 'res_pitch',
}

export enum AccessTypeEnum {
  Allow = 'allow',
  Deny = 'deny',
}

export enum RenditionVariantEnum {
  Main = 'main',
  Cover = 'cover',
  Thumbnail = 'thumbnail',
}

export enum RundownStateEnum {
  NotReady = 'notReady',
  Active = 'active',
  Ready = 'ready',
}

export enum RundownOrderTypeEnum {
  Ready = 'ready',
  Preparing = 'preparing',
}

export enum ScheduleTypeEnum {
  All = 'all',
  Unscheduled = 'unscheduled',
  Scheduled = 'scheduled',
}

export enum ScheduleEntryTypeEnum {
  Booking = 'booking',
  Note = 'note',
}

export enum Visibility {
  Private = 'private',
  Public = 'public',
}

export type AccountInput = {
  accountId?: string | null;
  accountRefId?: string;
  accountLogo?: string;
  accountTitle?: string;
  accountUrl?: string;
  orderType?: RundownOrderTypeEnum;
};

export type ActiveRundownInstanceInput = {
  mId: string;
  startTime?: string;
};

export type ActivateStorySyncInput = {
  mId: string;
  activeProviders?: string;
};

export type AddFeedToStoryInput = {
  storyId: string;
  storyType?: string;
  feedId: string;
  provider: string;
};

export type ArchiveStoryInput = {
  mId: string;
  rundownId?: string;
};

export type AuditErrorObjectInput = {
  code?: string;
  message?: string;
  stack?: string;
};

export type AuditSourceInput = {
  repository?: string;
  branchName?: string;
  commitId?: string;
};

export type AuditErrorPayloadInput = {
  version: string;
  error?: AuditErrorObjectInput;
  source?: AuditSourceInput;
  origin: string;
};

export type AddAuditErrorInput = {
  mActionId: AuditActionEnum;
  mId: string;
  mPayload?: AuditErrorPayloadInput;
  mType: string;
  mTimestamp: string;
};

export type AggregationInput = {
  name: string;
  type: AggregationTypeEnum;
  itemsLimit?: number;
  field?: string;
};

export type AssetTypeInput = {
  duration?: string;
  type?: string;
  headline?: string;
  byline?: string;
  renditions?: RenditionTypeInput[];
};

export type AssignedMemberInput = {
  mId: string;
  mType: string;
};

export type AuditInput = {
  mId: string;
  mActionId?: AuditActionEnum;
};

export type AuditMessageInput = {
  source?: string;
  message?: string;
  timestamp?: string;
};

export type MUsersInput = {
  mId?: string;
  mTitle?: string;
  mAvatarUrl?: string;
};

export type BacthDeleteMemberRelationInput = {
  members?: DeleteMemberRelationInput[];
};

export type BatchGetMembersInput = {
  limit?: number;
  mIds?: string[];
  keys?: GetMemberByKeyInput[];
};

export type BatchUpdateInstanceInput = {
  instances?: UpdateInstanceInput[];
};

export type BookMarkInput = {
  bookmarkedId: string;
  bookmarkedType: MemberTypeEnum;
};

export type BookingInput = {
  mDescription?: string;
  mId: string;
  mMetaData?: MMetaDataFieldInput[];
  mRefId?: string;
  mResourceId?: string;
  mState?: string;
  mTitle?: string;
  mType?: MemberTypeEnum;
  mPublishingAt?: string;
  mPublishingEnd?: string;
  mUpdatedById?: string;
  mUpdatedAt?: string;
  mCreatedAt?: string;
};

export type BreakingFeedInput = {
  mId: string;
  mRefId: string;
  mTitle?: string;
  mPublishingAt?: string;
  provider?: string;
};

export type CommandInput = {
  name: string;
  arguments?: MMetaDataFieldInput[];
};

export type CategoryInput = {
  categoryId?: string;
  label?: string;
  score?: number;
};

export type ContactPropertiesInput = {
  dateOfBirth?: string;
  email?: string;
  firstName: string;
  jobTitle?: string;
  phone?: string;
  surname: string;
  readSpeed?: string;
};

export type ConversationsOfUserInput = {
  mId: string;
  membersOfType?: MemberTypeEnum;
};

export type GetConversationsOfConvoTypeInput = {
  convoType: ConversationTypeEnum;
};

export type GetMessagesOfConversationInput = {
  mId: string;
};

export type CreateAssetInput = {
  mId: string;
  mRefId?: string;
  mAssetId?: string;
  mTitle?: string;
  mMetaData?: MMetaDataFieldInput[];
  extension?: string;
  renditions?: RenditionInput[];
  mediaType?: string;
  itemType?: string;
};

export type CreateAssetsInput = {
  assets?: CreateAssetInput[];
  copyVideoRenditions?: boolean;
  update?: boolean;
};

export type CreateContentTemplateInput = {
  mId: string;
  mTitle: string;
  mDescription: string;
  mProperties?: CreatePlatformInput;
};

export type CreateFolderInput = {
  mTitle: string;
  mParentId?: string;
  mProperties?: CreatePlatformInput;
};

export type CreateContactInput = {
  mDescription?: string;
  mProperties: ContactPropertiesInput;
  mTitle: string;
};

export type CreateConversationInput = {
  convoType: ConversationTypeEnum;
  mTitle?: string;
  mAssignedMembers?: AssignedMemberInput[];
};

export type CreateInstanceInput = {
  isRestricted?: boolean;
  metadata?: string;
  mAvatarUrl?: string;
  mContent?: string;
  mDescription?: string;
  mId: string;
  mProperties?: CreatePlatformInput;
  mPublishingAt?: string;
  mStoryId?: string;
  mTitle?: string;
  items?: ItemInput[];
  mMetaData?: MMetaDataFieldInput[];
};

export type CreateMasterRundownInput = {
  mTitle: string;
  mUpdatedById?: string;
  recurrence?: RecurrenceInput;
  platformKind?: string;
};

export type Child = {
  __typename?: 'Child';
  id?: string;
  label?: string;
  mChildren?: Child[];
};

export type ChildInput = {
  id?: string;
  label?: string;
  mChildren?: ChildInput[];
};

export type ConfigInput = {
  __typename?: 'ConfigType';
  key?: string;
  name?: string;
  type?: string;
  value?: string;
  values?: string[];
  alternatives?: Alternative[];
  treeAlternatives?: string[][];
};

export type CreateMemberInput = {
  avatarUrl?: string;
  mAction?: ActionEnum;
  configs?: ConfigInput[];
  mDescription?: string;
  mChildren?: ChildInput[];
  mRoot?: boolean;
  mId?: string;
  mMetaData?: MMetaDataFieldInput[];
  mPublishingAt?: string;
  mRefId?: string;
  mTitle?: string;
  mType: MemberTypeEnum;
  mState?: string;
  messageAssign?: string;
  isSyncActive?: boolean;
  mSecId?: string;
  mTertId?: string;
  mStatusField?: MdfFieldEntity;
  mActive?: boolean;
  slashCommand?: string;
  singleOnly?: boolean;
  mResourceType?: MemberTypeEnum;
  mdfId?: string;
  metadata?: string;
  mIcon?: string;
  mColor?: string;
  mStoryId?: string;
};

export type CreateMembersInput = {
  members?: CreateMemberInput[];
};

export type DailyNoteInput = {
  mId: string;
  mRefId: string;
  mType?: MemberTypeEnum;
  locked?: string;
  content?: string;
  isCancelEvent?: boolean;
};

export type CreateMessageInput = {
  mId: string;
  mRefId?: string;
  mContent?: string;
  convoType?: string;
  crudAction?: CrudActionEnum;
};

export type CreateNoteInput = {
  mTitle?: string;
  mDescription?: string;
  mStoryId: string;
  mInstanceId?: string;
};

export type CreatePlaceholderInput = {
  mId: string;
  mRefId?: string;
  mTitle?: string;
  mMetaData?: MMetaDataFieldInput[];
  itemType?: string;
  mProperties?: CreatePlatformInput;
};

export type CruDscheduleEntryInput = {
  crudAction: CrudActionEnum;
  scheduleEntryType?: ScheduleEntryTypeEnum;
  mId: string;
  mPublishingAt: string;
  mPublishingEnd: string;
  mRefId?: string;
  mTitle?: string;
  mProperties?: ScheduleEntryPropertiesInput;
  mMetadata?: MMetaDataFieldInput[];
  bookings?: BookingInput[];
};

export type ProviderInput = {
  requestId?: string;
  updateEndpoint?: string;
  embeddedEndpoint?: string;
  previewEndpoint?: string;
  message?: string;
  state?: string;
  eventTime?: string;
  startTime?: string;
  stopTime?: string;
};

export type CreatePlatformInput = {
  account?: AccountInput;
  platform?: string;
  platformKind?: string;
  scheduledAt?: string;
  publishedAt?: string;
  provider?: ProviderInput;
  pinned?: boolean;
};

export type CreateRundownInput = {
  mId?: string;
  mRefId?: string;
  mTitle?: string;
  mPublishingAt?: string;
  platformKind?: string;
};

export type CreateRundownInstanceInput = {
  index?: number;
  isRestricted?: boolean;
  isTemplateInstance?: boolean;
  skipCreateStory?: boolean;
  mId: string;
  mProperties?: CreatePlatformInput;
  mPublishingAt?: string;
  mStoryId?: string;
  mTitle: string;
  items?: ItemInput[];
  mMetaData?: MMetaDataFieldInput[];
};

export type CreateSessionInput = {
  mId: string;
  mRefId: string;
};

export type UpdatePushTokenInput = {
  mId: string;
  mRefId: string;
  mTitle?: string;
  shouldRemoveToken?: boolean;
};

export type CreateStoryForMemberInput = {
  mContent?: string;
  mCreatedById?: string;
  mId?: string;
  mTitle?: string;
  mType?: string;
  mCategories?: CategoryInput[];
  mDescription?: string;
  mPublishingAt?: string;
  mPublishingEnd?: string;
  isRestricted?: boolean;
  isSyncActive?: boolean;
  ttl?: number;
};

export type CreateStoryFromFeedInput = {
  mId?: string;
  mTitle?: string;
  mCreatedById?: string;
  provider?: string;
  mType?: string;
  mPublishingAt?: string;
  mPublishingEnd?: string;
  isRestricted?: boolean;
  isSyncActive?: boolean;
  ttl?: number;
};

export type CreateStoryFromPitchInput = {
  mId: string;
  mCreatedById: string;
  isSyncActive?: boolean;
  ttl?: number;
};

export type PostEventInput = {
  name: string;
  subtopic?: string;
  mId?: string;
  mType?: string;
  mMetaData?: MMetaDataFieldInput[];
};

export enum RequestMethodEnum {
  Delete = 'DELETE',
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
}

export type RequestInput = {
  url?: string;
  method?: RequestMethodEnum;
  params?: string;
  headers?: string;
  body?: string;
};

export type ServiceRequestInput = {
  provider: string;
  service?: string;
  request?: RequestInput;
};

export type CreateStoryInput = {
  mCategories?: CategoryInput[];
  mContent?: string;
  mDescription?: string;
  mId?: string;
  mTitle?: string;
  mPublishingAt?: string;
};

export type CreatePitchInput = {
  mDescription?: string;
  mId?: string;
  mTitle?: string;
  mPublishingAt?: string;
  isRestricted?: boolean;
};

export type DeleteAssetInput = {
  mId: string;
  mRefId: string;
  mAssetId?: string;
  mProvider?: string;
};

export type DeleteMemberInput = {
  mId: string;
  mType?: MemberTypeEnum;
};

export type DeleteMemberRelationInput = {
  mAction?: ActionEnum;
  mId: string;
  mRefId: string;
  mType?: MemberTypeEnum;
};

export type DeleteSingleMemberInput = {
  deleteRefMembers?: boolean;
  mId: string;
  mRefId: string;
};

export type DisableStorySyncInput = {
  mId: string;
};

export type GetScheduleResourcesInput = {
  mId?: string;
};

export type GetFeedsInput = {
  nextToken?: string;
  searchString?: string;
  dateRange?: DateRangeQL;
  providers: string[];
  filters?: UpdateFeedInput;
};

export type ExportContentInput = {
  mId: string;
  mRefId: string;
  contentType: string;
  rundownSection: 'ready' | 'preorder' | '-';
};

export type GetAssetInput = {
  mId: string;
  mRefId: string;
};

export type GetGroupUserInput = {
  groupName: string;
  userPoolId: string;
  limit?: string;
  nextToken?: string;
};

export type GetMemberInput = {
  mCreatedAt?: string;
  mId: string;
  mRefId?: string;
  mType?: MemberTypeEnum;
  mUpdatedAt?: string;
  membersOfType?: MemberTypeEnum;
  endDate?: string;
  startDate?: string;
  ignoreStartDate?: boolean;
};

export type GetMemberOfTypeInput = {
  mCreatedAt?: string;
  mType: MemberTypeEnum;
};

export type GetMemberByKeyInput = {
  mId?: string;
  mRefId?: string;
};

export type GetMembersByPublishingDateInput = {
  endDate: string;
  mType: MemberTypeEnum;
  startDate: string;
  scheduleType?: ScheduleTypeEnum;
  ignoreStartDate?: boolean;
};

export type GetMembersOfTypeBySecIdInput = {
  mSecId: string;
  mType: MemberTypeEnum;
};

export type GetBoardInput = {
  mId: string;
};

export type GetNotesInput = {
  mId: string;
};

export type GetPlatformInput = {
  mId: string;
  refresh?: boolean;
};

export type GetPlatformsInput = {
  mType?: MemberTypeEnum;
};

export type GetResourceBookingsInput = {
  mId?: string;
};

export type GetRundownInput = {
  mId?: string;
  mRefId?: string;
};

export type GetSummaryType = {
  mContent: string;
  mSummarize?: string;
  mSummarizeLines: number;
};

export type GetS3ContentInput = {
  mId?: string;
  mRefId?: string;
  mContentKey: string;
};

export type GetSignedUrlInput = {
  key?: string;
};

export type ItemInput = {
  itemId?: string;
  subItems?: ItemInput[];
  templateType?: string;
  templateVariant?: string;
  title?: string;
  state?: string;
  eventTime?: string;
  startTime?: string;
  stopTime?: string;
  iconUrl?: string;
};

export type LockInstanceInput = {
  mId: string;
  userId?: string;
};

export type LockMemberInput = {
  mId: string;
  mRefId?: string;
  userId?: string;
};

export type MediaAssetInput = {
  assetRefId?: string;
  itemType?: string;
  mId?: string;
  assetTitle?: string;
  itemId?: string;
  templateType?: string;
  state?: string;
};

export type MoveInstanceToRundownInput = {
  mIds: string[];
  sourceRundown?: RundownInput;
  targetRundown: RundownInput;
  copy?: boolean;
  index?: number;
  orderType?: RundownOrderTypeEnum;
  rundownOnly?: boolean;
};

export type NotePropertiesInput = {
  pinned?: boolean | null;
  labelId?: string | null;
  __typename: 'NoteProperties';
};

export type PlatformFilterInput = {
  name?: string;
  platformKind?: string;
};

export type UpdateNoteInput = {
  mId: string;
  mRefId: string;
  mTitle?: string;
  mDescription?: string;
  mProperties?: NotePropertiesInput;
  crudAction?: CrudActionEnum;
  mType?: MemberTypeEnum;
  locked?: string;
  mStoryId?: string;
  mUpdatedAt?: string;
  mUpdatedById?: string;
};

export type NotifyUserInput = {
  crudAction?: CrudActionEnum;
  mContent?: string;
  mId?: string;
  mTitle?: string;
  mType?: MemberTypeEnum;
  message?: string;
};

export type NotifyScheduleEntryInput = {
  crudAction?: CrudActionEnum;
  mId?: string;
  mPublishingAt?: string;
  mPublishingEnd?: string;
  mRefId?: string;
  mTitle?: string;
  mProperties?: ScheduleEntryPropertiesInput;
  mMetadata?: MMetaDataFieldInput[];
  mState?: string;
  mUpdatedAt?: string;
};

export type RecurrenceInput = {
  duration?: string;
  rRule?: string;
  start?: string;
  startTime?: string;
  timeZone?: string;
  dailyExclusive?: boolean;
};

export type RenditionInput = {
  mId: string;
  mRefId?: string;
  source: string;
  fileName?: string;
  variant?: RenditionVariantEnum;
};

export type RestrictMemberInput = {
  mId: string;
  isRestricted?: boolean;
};

export type RundownInput = {
  mId: string;
  mRefId: string;
};

export type RundownPlaybackStateInput = {
  state?: string;
  startTime?: string;
  stopTime?: string;
  eventTime?: string;
  currentInstance?: ActiveRundownInstanceInput;
};

export type RundownStateInput = {
  ready?: RundownPlaybackStateInput;
  preparing?: RundownPlaybackStateInput;
};

export type S3ObjectInput = {
  bucket: string;
  key?: string;
  localUri?: string;
  mimeType?: string;
  region: string;
  visibility?: Visibility;
};

export type SearchFilter = {
  mIds?: string;
  mTypes?: MemberTypeEnum[];
  searchString?: string;
  assignedMembers?: string;
  assignedMembersMust?: string;
  platforms?: string;
  platformsFilter?: PlatformFilterInput[];
  isUnscheduled?: boolean;
  includeRange?: boolean;
  searchBefore?: string;
  searchAfter?: string;
  index?: string;
  userItemsOnly?: boolean;
  searchDateField?: string;
};

export type PermissionInput = {
  action: string;
  access?: AccessTypeEnum;
};

export type ResourcePolicyInput = {
  resourceName: string;
  permissions?: PermissionInput[];
};

export type GroupPolicyInput = {
  policies?: ResourcePolicyInput[];
};

export type UpdateGroupPolicyInput = {
  mId: string;
  mRefId: string;
  mTitle?: string;
  mProperties?: GroupPolicyInput;
};

export type UpdateGroupPermissionsInput = {
  payload?: UpdateGroupPolicyInput[];
};

export type RenditionTypeInput = {
  href?: string;
  mimetype?: string;
  uri?: string;
  duration?: string;
  videoaspectratio?: string;
  format?: string;
  height?: string;
  width?: string;
  filename?: string;
  rendition?: string;
  sizeinbytes?: string;
  fileextension?: string;
  orientation?: string;
  previewUri?: string;
  thumbnailUri?: string;
  videoPreviewUri?: string;
};

export type ResourceInput = {
  mDescription?: string;
  mId: string;
  mExternalId?: string;
  mMetaData?: MMetaDataFieldInput[];
  mRefId?: string;
  mState?: string;
  mTitle?: string;
  mType?: MemberTypeEnum;
  mPublishedAt?: string;
  mPublishingEnd?: string;
  mUpdatedById?: string;
  mUpdatedAt?: string;
  mCreatedAt?: string;
};

export type UnlockMemberInput = {
  mId: string;
  mRefId?: string;
  content?: string;
  isCancelEvent?: boolean;
};

export type UpdateContactInput = {
  expectedVersion?: number;
  mDescription?: string;
  mId: string;
  mProperties?: ContactPropertiesInput;
};

export type UpdateContentInput = {
  expectedVersion?: number;
  mContent?: string;
  mId: string;
};

export type UpdateConversationInput = {
  mId: string;
  mRefId?: string;
  convoType?: ConversationTypeEnum;
  mTitle?: string;
  mType?: MemberTypeEnum;
  mAssignedMembers?: AssignedMemberInput[];
  mCreatedAt?: string;
  mUpdatedAt?: string;
  mUpdatedById?: string;
  mCreatedById?: string;
  message?: string;
  muted?: boolean;
};

export type UpdateRelatedMemberInput = {
  mId: string;
  crudAction: CrudActionEnum;
};

export type UpdateRelatedMembersInput = {
  mId: string;
  mRefId?: string;
  mRelatedMembers?: UpdateRelatedMemberInput[];
  isUniDirectional?: boolean;
};

export type LeaveConversationInput = {
  mId: string;
  mRefId: string;
};

export type UpdateInstanceInput = {
  expectedVersion?: number;
  items?: ItemInput[];
  assets?: MediaAssetInput[];
  mDescription?: string;
  mCurrentContentLocation?: string;
  mId: string;
  mMetaData?: MMetaDataFieldInput[];
  mProperties?: CreatePlatformInput;
  mPublishingAt?: string;
  mPublishingEnd?: string;
  mState?: string;
  mTitle?: string;
  mUpdatedAt?: string;
  locked?: string;
  content?: string;
  autosave?: boolean;
  version?: string;
  audit?: AuditMessageInput;
  mUpdatedById?: string;
};

export type UpdateMemberContentInput = {
  expectedVersion?: number;
  mContentLocation: S3ObjectInput;
  mId: string;
};

export type ScheduleEntryPropertiesInput = {
  description?: string;
  project?: ResourceInput;
  agency?: ResourceInput;
  client?: ResourceInput;
  contact?: ResourceInput;
  location?: string;
  address?: string;
  instructions?: string;
  status?: string;
  metadata?: MMetaDataFieldInput[];
};

export type SyncProviderInput = {
  mId: string;
  provider?: string;
  mMetaData?: MMetaDataFieldInput[];
  endpoint?: string;
};

export type LayoutSettingsInput = {
  fieldId: string;
  label: string;
  visible: boolean;
  hint?: string;
  flex?: number;
  icon?: string;

  // Only for text fields
  multiline?: boolean;
  fieldHeight?: number;
};

export type CreateMdfInput = {
  id?: string;
  label: string;
  isSubtype?: boolean;
  fields?: MdfFieldInput[];
  views?: LayoutSettingsInput[];
  permissions?: MdfPermissionInput;
};

// Keep in sync with enum, just for easier use in client code
export type FieldType =
  | 'text'
  | 'choice'
  | 'treechoice'
  | 'checkbox'
  | 'link'
  | 'number'
  | 'multiplechoice'
  | 'user'
  | 'subtype'
  | 'relation';

export enum FieldTypeEnum {
  text = 'text',
  choice = 'choice',
  treechoice = 'treechoice',
  number = 'number',
  user = 'user',
  link = 'link',
  multiplechoice = 'multiplechoice',
  checkbox = 'checkbox',
  date = 'date',
  subtype = 'subtype',
  relation = 'relation',
}

export type ViewTypes =
  | 'default'
  | 'order_grid'
  | 'order_form'
  | 'story_view'
  | 'story_create'
  | 'contact_view'
  | 'search_view';

export type Views = Record<ViewTypes, LayoutSettings[]>;

type MdfPermissionRaw = {
  read: string;
  write: string;
};

export type MdfPermission = {
  read: Record<string, string[]>;
  write: Record<string, string[]>;
};

/**
 * After JSON conversion
 */
export interface Mdf {
  id: string;
  label: string;
  views: Views;
  fields: MdfField[];
  permissions: MdfPermission;
  flavor: 'order_form';
  isSubtype?: boolean;
}

export interface UpdateOptionListInput {
  id: string;
  label: string;
  alternatives: Pick<Alternative, 'label' | 'value'>[];
}
export interface OptionListRaw {
  id: string;
  label: string;
  alternatives: Pick<Alternative, 'label' | 'value'>[];
  optionListType: 'choice' | 'treechoice';
}

export interface OptionList {
  id: string;
  label: string;
  alternatives: Alternative[];
  optionListType: 'choice' | 'treechoice';
}

export type CreateOptionList = Pick<OptionList, 'id' | 'label' | 'optionListType'>;

/**
 * Before JSON conversion - only use in API layer
 */
export type MdfType = {
  id: string; // mId & mRefId in db - unique formId
  label: string; // mSecId in db - unique label - we want to support querying by label
  views: Views;
  fields: MdfFieldEntity[];
  permissions: MdfPermissionRaw;
  flavor: 'order_form'; // order_form to begin with - mType in db
  isSubtype?: boolean;
};

export type ViewsInput = {
  default: LayoutSettingsInput[];
  order_form?: LayoutSettingsInput[];
  order_grid?: LayoutSettingsInput[];
  story_view?: LayoutSettingsInput[];
  story_create?: LayoutSettingsInput[];
};

export type UpdateMdfInput = {
  id: string;
  label?: string;
  fields?: MdfFieldInput[];
  views?: ViewsInput;
  permissions?: MdfPermissionInput;
};

export type MdfFieldInput = {
  fieldId: string;
  type: FieldTypeEnum;
  required?: boolean;
  alternatives?: Alternative[];
  treeAlternatives?: string[][];
  defaultValue?: string; // must be stringified json
};

export type MdfPermissionInput = {
  read: string;
  write: string;
};

export interface DefaultValueWrapper {
  value: DefaultValue;
}

export type DefaultValue =
  | null
  | string
  | boolean
  | number
  | string[]
  | string[][]
  | DateRange
  | MiniMember[];

export interface MdfField {
  fieldId: string;
  type: FieldTypeEnum;
  defaultValue: DefaultValueWrapper;
  constraint?: Record<string, unknown>;
  required?: boolean;
  systemDefault?: boolean;
  filters?: Record<string, unknown>;
  filter?: string[];

  // Only relevant for choice fields
  freeform?: boolean;
  alternatives?: Alternative[];

  // Only relevant for tree choice field
  treeAlternatives?: string[][];

  // Does it refer to an external list? Choice/MChoice/Tree choice only.
  optionListId?: string;
}

export type MdfFieldEntity = {
  fieldId: string;
  type: FieldTypeEnum;
  filters?: string; // stringified - must be parsed to get the right type
  constraint?: string; // stringified - must be parsed to get the right type
  defaultValue?: string; // stringified - must be parsed to get the right type
  required?: boolean;

  // generic way to apply filter logic, for example useful for User field type
  // to allow filtering a list of additional types (User, dept etc)
  filter?: string[];

  // Only relevant for choice fields
  freeform?: boolean;
  alternatives?: Alternative[];

  // Only relevant for tree choice field
  treeAlternatives?: string[][];

  // Does it refer to an external list? Choice/MChoice/Tree choice only.
  optionListId?: string;
};

export type LayoutSettings = {
  fieldId: string;
  label: string;
  hint: string;
  visible: boolean;
  flex?: number;
  icon?: string;

  // Only for text fields
  multiline?: boolean;
  fieldHeight?: number;
};

export type Alternative = {
  id: string;
  label: string;
  value: string;
  icon?: string;
};

export type GetMdfsInput = {
  ids?: string[];
  flavor?: string; // not yet implemented
  all?: boolean;
};

export type UpdateFeedInput = {
  provider?: string;
  providers?: string;
  mId?: string;
  version?: number;
  mPublishedAt?: string;
  infosource?: string;
  section?: string;
  priority?: string;
  pubstatus?: string;
  ednotes?: string;
  newscodes?: string[];
  mediatopics?: string[];
  excludeMediaTopics?: string[];
  firstcreated?: string;
  versioncreated?: string;
  mTitle?: string;
  byline?: string;
  mContent?: string;
  mDescription?: string;
  location?: string;
  embargoed?: string;
  usageterms?: string;
  copyrightnotice?: string;
  href?: string;
  assetTypes?: string[];
  storyList?: string;
  assets?: AssetTypeInput[];
  revision?: number;
  located?: string;
  language?: string;
  slugline?: string;
  freeText?: string;
  keywords?: string;
  places?: PlaceTypeInput[];
  people?: PersonTypeInput[];
  priorities?: string;
  languages?: string;
};

export type PlaceTypeInput = {
  name?: string;
  rel?: string;
  geojson?: GeoTypeInput;
};

export type GeoTypeInput = {
  type?: string;
  coordinates?: number[];
};

export type PersonTypeInput = {
  name?: string;
  rel?: string;
};

export type UpdateMetadataInput = {
  mId: string;
  mRefId: string | undefined;
  metadata: string; // Stringified JSON
  mdfId?: string; // provide if not a system default
  mType: MemberTypeEnum;
};

export type UpdateMemberInput = {
  mId: string;
  mRefId?: string;
  assets?: MediaAssetInput[];
  mChildren?: ChildInput[];
  mAssetId?: string;
  mAssignedMembers?: AssignedMemberInput[];
  mAssignees?: string;
  mBookmarks?: BookMarkInput[];
  mCategories?: CategoryInput[];
  mCreatedAt?: string;
  mDescription?: string;
  message?: string;
  mMetaData?: MMetaDataFieldInput[];
  mAvatarKey?: string;
  mThumbnailKey?: string;
  mOrder?: string[];
  mPreorder?: string[];
  mPriority?: string;
  mProperties?: CreatePlatformInput;
  mPublishingAt?: string | null;
  mPublishingEnd?: string | null;
  mRelatedMembers?: string;
  mTemplateId?: string;
  mState?: string;
  mSeen?: string;
  mStoryId?: string;
  mSyncActive?: string;
  mSyncProviders?: SyncProviderInput[];
  mTime?: string;
  mTitle?: string;
  mType?: MemberTypeEnum;
  mUpdatedAt?: string;
  mUpdatedById?: string;
  mUsers?: MUsersInput[];
  crudAction?: CrudActionEnum;
  expectedVersion?: number;
  images?: string;
  itemBymId?: string;
  itemBymRefId?: string;
  items?: ItemInput[];
  locked?: string;
  monthView?: string;
  planStatusViewDate?: string;
  publishingDate?: string;
  weekView?: string;
  command?: CommandInput;
  ttl?: number;
  messageAssign?: string;
  types?: string[];
  mFormId?: string;
  permissions?: MemberPermissionsInput;
};

type ReadWritePermissionInput = {
  name: string;
  groups: string[];
};

type MemberPermissionsInput = {
  write: ReadWritePermissionInput[];
  read: ReadWritePermissionInput[];
};

export type UpdateRundownInput = {
  mCreatedAt?: string;
  mId?: string;
  mOrder?: string[];
  mPlannedDuration?: string;
  mPrePlannedDuration?: string;
  mCoverPhotoKey?: string;
  mThumbnailKey?: string;
  mPreorder?: string[];
  mPreparingState?: RundownStateEnum;
  mProperties?: RundownStateInput;
  mPublishingAt?: string;
  mRefId?: string;
  mState?: RundownStateEnum;
  mStudios?: string;
  mTitle?: string;
  mType?: MemberTypeEnum;
  mType_mState_mUpdatedAt?: string;
  mUpdatedAt?: string;
  recurrence?: RecurrenceInput;
  platformKind?: string;
  version?: number;
  mMetaData?: MMetaDataFieldInput[];
  mPayload?: PayloadInput[];
  rundownPublishingDate?: string;
  permissions?: RundownPermissionsInput;
};

export type PayloadInput = {
  crudAction?: CrudActionEnum;
  value?: PayloadValueInput;
};

export type PayloadValueInput = {
  mId?: string;
  index?: number;
  destination?: string;
};

export type UploadMemberImageInput = {
  mId: string;
  mImageLocation: S3ObjectInput;
  mImageType?: ImageTypeEnum;
};

export type UserUtilsUserInput = {
  mId?: string;
  actions?: UserUtilsActionEnum[];
};

export type UserUtilsInput = {
  synchronizeAll?: boolean;
  actions?: UserUtilsActionEnum[];
  users?: UserUtilsUserInput[];
};

export type MMetaDataFieldInput = {
  key: string;
  value?: string | number;
  mostag?: string;
  autoValue?: string;
  manual?: boolean;
};

export type UpdateGroupUserInput = {
  groupUser?: GroupUserInput[];
};

export type GroupUserInput = {
  username: string;
  userPoolId: string;
  groupName: string;
  crudAction: CrudActionEnum;
};

export type UpdateUserStoryLayoutInput = {
  mId: string;
  mRefId: string;
  crudAction?: CrudActionEnum;
  mProperties?: UserStoryLayoutPropertiesInput;
};

export type UserStoryLayoutPropertiesInput = {
  openInstances?: string;
};

export type UpdateFormInput = {
  mId: string;
  mRefId: string;
  mTitle?: string;
  mDescription?: string;
  mProperties?: MetadataFormInput;
  mUpdatedAt?: string;
  mUpdatedById?: string;
};

export type MetadataFormInput = {
  fields?: MetadataFormFieldInput[];
  defaultView?: string;
  defaultViews?: MetadataFormDefaultViewInput[];
  views?: MetadataFormViewInput[];
};

export type MetadataFormFieldInput = {
  id: string;
  name: string;
  type: string;
  label?: string;
  options?: MetadataFormOptionInput[];
  description?: string;
  value?: string;
  function?: string;
  parameters?: MetadataFormViewColumnInput[];
  format?: string;
  mostag?: string;
  mandatory?: boolean;
  readonly?: boolean;
  defaultValue?: string;
};

export type MetadataFormOptionInput = {
  id: string;
  title?: string;
  value?: string;
  icon?: string;
};

export type MetadataFormDefaultViewInput = {
  type: string;
  defaultView: string;
  variant: string;
};

export type MetadataFormViewInput = {
  id: string;
  name: string;
  type: string;
  columndefs?: MetadataFormColumnDefInput[];
};

export type MetadataFormColumnDefInput = {
  variant: string;
  columns?: MetadataFormViewColumnInput[];
};

export type MetadataFormViewColumnInput = {
  id: string;
  width?: string;
  name?: string;
  label?: string;
  group?: string;
  readonly?: boolean;
  severity?: string;
  style?: string;
  columnId?: string;
};
