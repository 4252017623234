/* eslint-disable react/prop-types */
import { memo, Suspense, useLayoutEffect, useMemo } from 'react';

import DebouncedLoadingIndicator from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import variants from 'components/editor/constants/types/editorVariants';
import Editor from 'components/editor/Editor';
import { Update } from 'components/editor/types';
import EmptyState from 'components/emptyState';
import LockedIndicator from 'components/lockedIndicator';
import { useNotesMolecule } from 'features/notes/store';
import Sidepanel from 'features/sidepanel/Sidepanel';
import { RightCollapsibleSplitPane } from 'features/splitView/SplitView';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useResourceDetails from 'hooks/useResourceDetails';
import { Box, Flex } from 'layouts/box/Box';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { useUsers } from 'store';
import { Note } from 'types';

import useNote from './hooks/useNote';

interface NotesViewProps {
  canUpdate: boolean;
  hostReadSpeed?: number;
  note?: Note;
}

const NoteView: React.FC<NotesViewProps> = ({ note, canUpdate, hostReadSpeed }) => {
  const [users] = useUsers();

  const memoizedNote = useMemo(() => note, [note]);

  const { useStory } = useStoryMolecule();
  const { useScopeViewRefValue } = useNotesMolecule();

  const [story] = useStory();
  const [checkUserRight] = useCheckUserRight();
  const canShowMdfBlocks = checkUserRight('feature', 'mdfBlocks');
  const resourceDetails = useResourceDetails({
    mId: story?.mId ?? '',
    mTitle: story?.mTitle ?? '',
    mType: 'story',
  });

  const {
    content,
    readLock,
    writeLock,
    shouldResetSelection,
    lockedByUser,
    locked,
    loading,
    locking,
    isSavingContent,
    isCancelled,
    onSavePress,
    onCancelPress,
    onForceUnlock,
    onEditorUpdate,
    onFocusEditor,
    beforeunloadFn,
    getPlaceholderConfigs,
  } = useNote({
    entity: memoizedNote,
    canUpdate,
  });

  const scopeViewRef = useScopeViewRefValue();

  useLayoutEffect(() => {
    /** unlock note on view change and unload event */
    window.addEventListener('beforeunload', (e) => {
      beforeunloadFn(e);
    });

    return () => {
      beforeunloadFn();
      window.removeEventListener('beforeunload', (e) => {
        beforeunloadFn(e);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeViewRef, note?.mRefId]);

  if (!note) {
    return <EmptyState message="Please select a note" />;
  }

  return (
    <Box container flex="1" width="100%" height="100%">
      <Suspense fallback={<DebouncedLoadingIndicator />}>
        <RightCollapsibleSplitPane
          hidden={!canShowMdfBlocks || !story}
          pane1={
            <>
              <DebouncedLoadingIndicator isLoading={loading || locking} />
              <Flex
                onClick={() => onFocusEditor()}
                width="100%"
                height="100%"
                overflow="hidden"
                flexDirection="column"
              >
                <Editor
                  variant={variants.NOTES}
                  value={content}
                  update={onEditorUpdate as Update}
                  users={users}
                  renderToolbar={writeLock ? undefined : () => null}
                  readOnly={
                    !canUpdate || !writeLock || readLock || locking || loading || isSavingContent
                  }
                  toolbarPosition="top"
                  placeholder="Type Something..."
                  fallbackText="Note content can not be loaded"
                  height={readLock || writeLock ? 'calc(100% - 40px)' : '100%'}
                  shouldResetSelection={shouldResetSelection}
                  hostReadSpeed={hostReadSpeed}
                  resourceDetails={resourceDetails}
                  getPlaceholderConfigs={getPlaceholderConfigs}
                  enableEditorCommand
                  showSidepanelButton
                  showHoveringTooltip
                />
                <LockedIndicator
                  readLock={readLock}
                  writeLock={writeLock}
                  lockedBy={lockedByUser}
                  isSaving={isSavingContent}
                  isCancelled={isCancelled}
                  onDone={onSavePress}
                  onCancel={onCancelPress}
                  lockedId={locked}
                  onForceUnlock={onForceUnlock}
                />
              </Flex>
            </>
          }
          pane2={<Sidepanel resourceDetails={resourceDetails} />}
        />
      </Suspense>
    </Box>
  );
};

export default memo(NoteView);
