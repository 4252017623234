import { memo, useCallback, useMemo } from 'react';
import { ScopeProvider } from 'jotai-molecules';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { useGetMiniMember } from 'api/useGetMember';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { RelationItem } from 'components/mdfEditor/fields/relation/RelationItem';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { kanbanScope } from 'features/kanban/store/kanban';
import OrderGridFull from 'features/orderForm/components/OrderGridFull';
import Tasks from 'features/sidepanel/Tasks';
import WidgetWrapper, { FilterComponentType } from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { Box } from 'layouts/box/Box';
import { ResourceType } from 'types/forms/forms';
import { GetOrderEnum, MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import type { OrderFormMemberType } from 'types/memberTypes/order_form';
import { FilterValueType } from 'types/widget';

import { FilterComponent } from './OrderFilters';
import { WidgetProps } from './types';
import { getPropFilters } from './utils';

const getFallbackText = (
  resourceType: GetOrderEnum,
  resourceId: string,
  orderForms: OrderFormMemberType[],
) => {
  switch (resourceType) {
    case GetOrderEnum.Form: {
      const form = orderForms.find((f) => f.mRefId === resourceId);
      return `Showing all ${form?.mTitle} orders`;
    }
    case GetOrderEnum.Assignee:
      return 'Showing assigned orders';
    case GetOrderEnum.Owner:
      return 'Showing owned orders';
    case GetOrderEnum.Resource:
      return 'Showing orders for a resource';
    case GetOrderEnum.Space:
      return 'Showing all orders for this space';
  }
};

const getTooltip = (member: MemberType, resourceType: GetOrderEnum) => {
  switch (resourceType) {
    case GetOrderEnum.Assignee:
      return `Showing tasks assigned to ${member.mTitle}`;
    case GetOrderEnum.Owner:
      return `Showing tasks owned by ${member.mTitle}`;
    case GetOrderEnum.Resource:
      return `Showing tasks for ${member.mType}: '${member.mTitle}'`;
    case GetOrderEnum.Space:
      return `Showing all tasks for the space ${member.mTitle}`;
    case GetOrderEnum.Form:
      return 'Showing all tasks for a specific type';
  }
};

function OrderWidget({
  mId,
  mRefId,
  title,
  spaceId,
  filters,
  writeAccess,
  layout,
}: Readonly<WidgetProps>) {
  const memoizedFilters = useMemo(() => {
    const propFilters = getPropFilters({ filters });

    return propFilters as FilterValueType;
  }, [filters]);
  const { orderForms } = useGetOrderForms();
  const { openItem } = useOpenMember();
  const getConfigFromFilter = useCallback(() => {
    const resourceId = (memoizedFilters?.resourceId ?? spaceId) as string;
    const viewType = memoizedFilters?.viewType;
    const resourceType = memoizedFilters?.resourceType as GetOrderEnum;
    const resourceMemberType = memoizedFilters?.resourceMemberType as MemberTypeEnum | undefined;
    const resourceConnectedId = memoizedFilters?.resourceConnectedId as string | undefined;

    return { resourceId, viewType, resourceType, resourceMemberType, resourceConnectedId };
  }, [memoizedFilters]);

  const { resourceId, resourceType, viewType, resourceMemberType, resourceConnectedId } =
    getConfigFromFilter();

  const { member } = useGetMiniMember(
    resourceMemberType
      ? {
          id: resourceId,
          connectedId: resourceConnectedId,
          type: resourceMemberType,
        }
      : null,
  );

  const fallbackText = useMemo(() => {
    return getFallbackText(resourceType, resourceId, orderForms);
  }, [resourceType, resourceId, orderForms]);

  return (
    <ScopeProvider scope={kanbanScope} value={mId}>
      <WidgetWrapper
        layout={layout}
        filterComponent={FilterComponent as FilterComponentType}
        filters={memoizedFilters}
        mId={mId}
        mRefId={mRefId}
        title={title}
        activeFilters={Boolean(resourceId)}
        spaceId={spaceId}
        writeAccess={writeAccess}
        type={WIDGETS.TASKS}
      >
        {!viewType || viewType === 'grid' ? (
          <OrderGridFull
            resourceId={resourceId}
            resourceType={(resourceType as GetOrderEnum) ?? GetOrderEnum.Space}
            headerSlot={
              <Box
                container
                width="400px"
                height="100%"
                padding="0 0 0 8px"
                gap="4px"
                justifyContent="start"
              >
                {member ? (
                  <Tooltip title={getTooltip(member, resourceType)}>
                    <span>
                      <RelationItem
                        key={`${member.mId}:${member.mRefId}`}
                        member={member}
                        onOpenClick={() => openItem(member)}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <Text variant="listItemLabel" style={{ whiteSpace: 'pre' }}>
                    {fallbackText}
                  </Text>
                )}
              </Box>
            }
          />
        ) : (
          <Tasks
            queryType={resourceType ?? GetOrderEnum.Space}
            resourceDetails={{
              resourceId,
              resourceType: resourceMemberType as ResourceType,
              resourceTitle: '',
            }}
          />
        )}
      </WidgetWrapper>
    </ScopeProvider>
  );
}

export default memo(OrderWidget);
