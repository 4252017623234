import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Editable as SlateEditable } from 'slate-react';

import transientOptions from 'theme/helpers';
import { EditorFontSize } from 'types/editor';

import { calculatedHW } from './types';

export const ToolbarWrapper = styled.div`
  flex: 0 0 auto;
`;

interface EditorWrapperProps {
  $width: string | number;
  $height: string | number;
  $background?: string;
}

export const EditorWrapper = styled('div', transientOptions)<EditorWrapperProps>`
  width: ${({ $width }) => calculatedHW($width)};
  height: ${({ $height }) => calculatedHW($height)};
  background: ${({ theme, $background }) => $background || theme.palette.dina.surfaceCard};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const getFontSize = (size: number, inputSize: number) => css`
  font-size: ${size}px;
  input,
  textarea {
    font-size: ${inputSize}px !important;
  }
`;

interface EditableWrapperProps {
  padding: number;
  messageVariant: boolean;
  fontSize: EditorFontSize;
}
export const EditableWrapper = styled('div')<EditableWrapperProps>`
  ul {
    display: block;
    list-style-type: disc;
    margin-block: 1em;
    margin-inline: 0px;
    padding-inline-start: 40px;
  }
  ${({ padding, theme }) => css`
    padding: ${padding}px;
    color: ${theme.palette.dina.highEmphasis};
  `}
  height: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ fontSize }) => {
    if (fontSize === 'medium') return getFontSize(24, 18);
    if (fontSize === 'large') return getFontSize(36, 22);
    if (fontSize === 'xLarge') return getFontSize(48, 26);

    return getFontSize(16, 15);
  }}
`;

export const Editable = styled(SlateEditable)`
  outline: none;
`;
