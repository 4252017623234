import styled from '@emotion/styled';

const ROW_HEIGHT = '36px';

export const StyledTable = styled('div')`
  border-spacing: 0;
  height: 100%;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  .td {
    height: ${ROW_HEIGHT};
    user-select: none;
    padding-inline: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    :last-of-type {
      border-right: none;
    }
  }
`;

export const StyledTHeader = styled('div')`
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  ${({ theme }) => theme.typography.dina.overline};
`;

export const StyledTRow = styled('div')`
  display: flex;
`;

export const StyledTHead = styled('div')`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 8px;
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const StyledTbody = styled('div')`
  .tr {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    :nth-of-type(even) {
      background: ${({ theme }) => theme.palette.dina.tableAlternatingRowOverlay};
    }
    .td {
      display: flex;
      align-items: center;
      padding: 0px;
      padding-inline: 8px;
      :hover {
        background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
      }
      input:focus {
        border: 2px solid ${({ theme }) => theme.palette.dina.onFocus};
      }
    }
  }
`;

export const Resizer = styled('div')`
  position: absolute;
  top: 0;
  height: 100%;
  right: -1.5px;
  width: 5px;
  border-radius: 2px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  :hover {
    background: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &.isResizing {
    background: ${({ theme }) => theme.palette.dina.onFocus};
    opacity: 1;
  }
`;
