import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import isNull from 'lodash/isNull';

import { initialValues } from 'components/editor/constants';
import variants from 'components/editor/constants/types/editorVariants';
import { isOlderSlateValue, migrateValue } from 'components/editor/utils';
import UserContext from 'contexts/UserContext';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { Story } from 'types';
import { EditorValue } from 'types/editor';
import { uploadToS3 } from 'utils/s3Utils';

import useDebouncedCallback from './useDebouncedCallback';
import useGetUser from './useGetUser';
import useLockMember from './useLockMember';
import useTextStorage from './useTextStorage';
import useUnlockMember from './useUnlockMember';

const useStoryContent = (story: Story | undefined, canUpdate: boolean) => {
  const { mId: storyId, mContentKey, locked } = story ?? {};
  const { mId: currentUserId } = useContext(UserContext);
  const { useContent } = useStoryMolecule();
  const { getUserTitle } = useGetUser();
  const [lockStory] = useLockMember();
  const [unlockStory] = useUnlockMember();

  const { data: s3Data, loading, refetch } = useTextStorage(mContentKey ?? '', false);

  const [content, setContent] = useContent();
  const [shouldResetSelection, setShouldResetSelection] = useState<boolean>(false);
  const [writeLock, setWriteLock] = useState<boolean>(false);
  const [readLock, setReadLock] = useState<boolean>(false);
  const [lockedByUser, setLockedByUser] = useState<string>('Someone');
  const [isSavingContent, setIsSavingContent] = useState<boolean>(false);
  const [locking, setLocking] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const editorValueRef = useRef<EditorValue | null>(null);
  const writeLockRef = useRef<boolean>(writeLock);
  const storyRef = useRef<Story | null>(story ?? null);
  const initialContentRef = useRef<EditorValue | null>(null);

  const resetEditorValue = useCallback(
    (newValue: EditorValue | null) => {
      if (newValue) {
        const value = isOlderSlateValue(newValue)
          ? (migrateValue(newValue, {}) as EditorValue)
          : { ...newValue };
        setContent(value);
        editorValueRef.current = value;
        setShouldResetSelection(true);
      } else if (isNull(newValue)) {
        setContent(null);
        editorValueRef.current = null;
        setShouldResetSelection(true);
      }
    },
    [setContent],
  );

  const updateLock = useCallback(
    (lockedId: string | null | undefined) => {
      if (lockedId) {
        setWriteLock(lockedId === currentUserId);
        setReadLock(lockedId !== currentUserId);
        if (lockedId !== currentUserId) {
          const newLockedByUser = getUserTitle(lockedId);
          setLockedByUser(newLockedByUser!);
        }
      } else {
        window.requestAnimationFrame(() => {
          setWriteLock(false);
          setReadLock(false);
          setLockedByUser('');
        });
      }
      setLocking(false);
      writeLockRef.current = lockedId === currentUserId;
    },
    [currentUserId, getUserTitle],
  );

  const save = useCallback(
    async (newContent: EditorValue) => {
      const file = new window.File([JSON.stringify(newContent ?? {})], 'content.data', {
        type: 'text/plain',
      });

      await uploadToS3(mContentKey, file);
    },
    [mContentKey],
  );

  const onUnlockStory = useCallback(
    async (cancelled?: boolean) => {
      if (!storyRef.current?.mId) return;

      if (cancelled) {
        await unlockStory(storyRef.current?.mId, editorValueRef.current, true);
      } else {
        await unlockStory(storyRef.current?.mId, editorValueRef.current);
      }
      updateLock(null);
    },
    [unlockStory, updateLock],
  );

  const onForceUnlock = useCallback(async () => {
    if (!story?.mId) return;
    await unlockStory(story?.mId);
  }, [story?.mId, unlockStory]);

  const tryLockStory = useCallback(async () => {
    if (!writeLock && !locked && storyId) {
      const result = await lockStory(storyId, currentUserId);
      const lockedId = result?.data?.lockMember?.locked;
      updateLock(lockedId);
      return lockedId;
    } else {
      updateLock(storyRef?.current?.locked);
      return storyRef?.current?.locked;
    }
  }, [writeLock, locked, storyId, lockStory, currentUserId, updateLock]);

  const onFocusEditor = useCallback(async () => {
    if (canUpdate && storyId && !writeLock && !readLock && !loading) {
      try {
        setLocking(true);
        refetch();

        return await tryLockStory();
      } catch (error) {
        updateLock(null);
        setLocking(false);
        return null;
      }
    }
  }, [canUpdate, storyId, writeLock, readLock, loading, refetch, tryLockStory, updateLock]);

  const onSaveContent = useCallback(
    async (shouldReleaseLock = false) => {
      if (!loading && writeLockRef.current) {
        setIsSavingContent(true);

        if (storyId === storyRef.current?.mId) {
          resetEditorValue(editorValueRef.current);
          initialContentRef.current = editorValueRef.current;
        }

        if (editorValueRef.current) await save(editorValueRef.current);
        if (shouldReleaseLock) {
          await onUnlockStory();
        }
        setIsSavingContent(false);
      }
    },
    [loading, onUnlockStory, resetEditorValue, save, storyId],
  );

  const [debouncedSave, cancelDebounce] = useDebouncedCallback(onSaveContent, 15000);

  const onSave = useCallback(
    async (releaseLock = false) => {
      cancelDebounce();
      await onSaveContent(releaseLock);
    },
    [cancelDebounce, onSaveContent],
  );

  const onCancel = useCallback(async () => {
    setIsCancelled(true);
    cancelDebounce();
    const initialValue = isNull(initialContentRef.current)
      ? initialValues(variants.GENERAL)
      : initialContentRef.current;

    resetEditorValue(initialValue);
    await Promise.all([save(initialValue), onUnlockStory(true)]);

    setIsCancelled(false);
  }, [cancelDebounce, resetEditorValue, save, onUnlockStory]);

  const onChange = useCallback(
    (newContent: EditorValue) => {
      if (writeLockRef.current) {
        editorValueRef.current = newContent;
        void debouncedSave();
      }
    },
    [debouncedSave],
  );

  const checkVersionRestorability = async () => {
    if (locked === currentUserId) return true;
    const lockedId = await tryLockStory();
    return lockedId === currentUserId;
  };

  const onRestoreVersion = useCallback(
    async (newContent: EditorValue) => {
      resetEditorValue(newContent);
      await onSave(true);
    },
    [onSave, resetEditorValue],
  );

  useEffect(() => {
    /** refetch content when story is unlocked */
    if (storyRef.current?.locked && !story?.locked) refetch();

    storyRef.current = story ?? null;
  }, [refetch, story]);

  useEffect(() => {
    writeLockRef.current = writeLock;
  }, [writeLock]);

  useEffect(() => {
    if (s3Data) {
      resetEditorValue(s3Data);
      initialContentRef.current = s3Data;
    } else {
      const defaultValue = initialValues(variants.GENERAL);
      resetEditorValue(defaultValue);
      initialContentRef.current = defaultValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3Data]);

  useEffect(() => {
    updateLock(locked);
  }, [storyId, locked, updateLock]);

  const unloadFunc = useCallback(async (e?: BeforeUnloadEvent) => {
    e?.preventDefault();
    delete e?.returnValue;

    if (
      writeLockRef.current &&
      storyRef.current?.mId === storyId &&
      storyRef.current?.locked === currentUserId &&
      editorValueRef.current
    ) {
      setIsSavingContent(true);
      cancelDebounce();
      await Promise.all([save(editorValueRef.current), onUnlockStory()]);
      setIsSavingContent(false);
    }
  }, []);

  return {
    loading: loading || locking,
    content,
    shouldResetSelection,
    isSavingContent,
    lockedByUser,
    readLock,
    writeLock,
    isCancelled,
    onFocusEditor,
    onChange,
    onSave,
    onCancel,
    checkVersionRestorability,
    onRestoreVersion,
    onForceUnlock,
    unloadFunc,
  } as const;
};

export default useStoryContent;
