import { useEffect } from 'react';
import { ApolloClient } from '@apollo/client';
import { ScopeProvider } from 'jotai-molecules';
import isEqual from 'lodash/isEqual';

import useGetMember from 'api/useGetMember';
import DebouncedLoadingComponent from 'components/debouncedLoadingIndicator/DebouncedLoadingIndicator';
import Download from 'components/download/download';
import InstancePrint from 'features/print/InstancePrint';
import useApolloSubscription from 'hooks/useApolloSubscription';
import { VStack } from 'layouts/box/Box';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import { Instance } from 'types';
import { MemberType } from 'types/graphqlTypes';

import Body from './components/Body';
import DNDProvider from './components/DNDProvider';
import Footer from './components/Footer';
import Header from './components/Header';
import useInstanceAssignees from './hooks/useInstanceAssignees';
import useInstanceCore from './hooks/useInstanceCore';
import useInstancePermissions from './hooks/useInstancePermissions';
import useInstanceViewUtils from './hooks/useInstanceViewUtils';
import { InstanceScope, useInstanceMolecule } from './store/instance';
import { propertiesToMapIntoCache } from './utils';

import { StyledPaper } from './styled';

interface Props {
  instance: Instance;
  hideTemplateOptions?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  autoUpdate?: boolean;
  isSearchItem?: boolean;
}

interface SubscriptionEvent {
  data: {
    notifyMemberUpdateSubscription: MemberType;
  };
}

const fieldsMapper = (item: MemberType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mapper: Record<string, any> = {};
  propertiesToMapIntoCache.forEach((property) => {
    mapper[property] = () => item[property] ?? null;
  });
  return mapper;
};

const InstanceComponent = ({
  instance,
  hideTemplateOptions,
  onOpen,
  onClose,
  autoUpdate,
  isSearchItem,
}: Props) => {
  const {
    instanceRef,
    useSetInstance,
    useInstanceValue,
    useHideTemplateOptions,
    useDurations,
    useDowloadData,
    usePrintDialog,
  } = useInstanceMolecule();
  const { updateRelatedMembersMutation, writeLock, readLock, updatingRelatedMembers, loading } =
    useInstanceCore();
  const { canUpdateInstance } = useInstancePermissions();
  const { assignMemberToInstance } = useInstanceAssignees();
  const { onPaperKeyDown } = useInstanceViewUtils();
  const setInstance = useSetInstance();
  const [, setHideTemplateOptions] = useHideTemplateOptions();
  const instanceValue = useInstanceValue();
  const [downloadData, setDownloadData] = useDowloadData();
  const [showPrintDialog, setShowPrintDialog] = usePrintDialog();
  const [durations] = useDurations();

  const { data } = useGetMember<Instance>({
    mId: instance?.mId,
    mRefId: instance?.mRefId,
    fetchPolicy: 'cache-and-network',
    type: 'instance',
  });

  const [subscribe, unSubscribe] = useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: instance?.mStoryId ?? data?.mStoryId ?? '',
    },
    skip: !autoUpdate,
    onSubscriptionData: ({
      subscriptionData,
      client,
    }: {
      subscriptionData: SubscriptionEvent;
      client: ApolloClient<object>;
    }) => {
      client.cache.modify({
        id: client.cache.identify({
          mId: instance.mId,
          mRefId: instance.mRefId,
          __typename: isSearchItem ? 'SearchItem' : 'MemberType',
        }),
        fields: fieldsMapper(subscriptionData.data.notifyMemberUpdateSubscription ?? {}),
      });
    },
  });

  useEffect(() => {
    subscribe();

    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    instanceRef.current = instance;
    if (!autoUpdate && !isEqual(instanceValue, instance)) {
      setInstance(instance);
    }
  }, [instance, instanceValue, setInstance, instanceRef, autoUpdate]);

  useEffect(() => {
    if (autoUpdate && data && !isEqual(instanceValue, data)) {
      setInstance(data as unknown as Instance);
      instanceRef.current = data as unknown as Instance;
    }
  }, [autoUpdate, data, instanceRef, instanceValue, setInstance]);

  useEffect(() => {
    setHideTemplateOptions(!!hideTemplateOptions);
  }, [hideTemplateOptions, setHideTemplateOptions]);

  return (
    <DNDProvider
      updateRelatedMembersMutation={updateRelatedMembersMutation}
      canUpdateInstance={canUpdateInstance}
      instance={instance}
      handleUserDrop={assignMemberToInstance}
      storyId={instance?.mStoryId}
    >
      <VStack flex="1">
        <StyledPaper $writeLock={writeLock} $readLock={readLock} onKeyDown={onPaperKeyDown}>
          {(loading || updatingRelatedMembers) && <DebouncedLoadingComponent />}
          <Header onOpen={onOpen} onClose={onClose} />
          <Body />
          <Footer />
          {!!downloadData && (
            <Download download={downloadData} onClose={() => setDownloadData(null)} />
          )}
          {showPrintDialog && (
            <InstancePrint
              instance={instance}
              isDialogOpen={showPrintDialog}
              onCloseDialog={() => setShowPrintDialog(false)}
              clipDuration={durations.clip}
              scriptDuration={durations.script}
              totalDuration={durations.total}
            />
          )}
        </StyledPaper>
      </VStack>
    </DNDProvider>
  );
};

function InstanceRoot({
  instance,
  hideTemplateOptions,
  autoUpdate = false,
  isSearchItem = false,
  ...rest
}: Readonly<Props>) {
  return (
    <ScopeProvider scope={InstanceScope} value={`${instance.mId}${autoUpdate && ':preview'}`}>
      <InstanceComponent
        autoUpdate={autoUpdate}
        isSearchItem={isSearchItem}
        instance={instance}
        hideTemplateOptions={hideTemplateOptions}
        {...rest}
      />
    </ScopeProvider>
  );
}

export default InstanceRoot;
