import { flexRender } from '@tanstack/react-table';

import { Resizer, TableHeader, TableRow } from 'components/dataTable/Table';

import { TableInstance } from '../types';

import { SortDirection, SortIcon } from './Icons';

import { SortHandle, StyledTableHead } from './styled';

export default function Header({ tableInstance }: Readonly<TableInstance>) {
  return (
    <TableHeader>
      {tableInstance.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <StyledTableHead key={header.id} headerId={header.id}>
                {header.isPlaceholder ? null : (
                  <SortHandle onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <SortIcon direction={SortDirection.ASC} />,
                      desc: <SortIcon direction={SortDirection.DESC} />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </SortHandle>
                )}
                <Resizer
                  onDoubleClick={() => header.column.resetSize()}
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  className={`${header.column.getIsResizing() ? 'isResizing' : ''}`}
                />
              </StyledTableHead>
            );
          })}
        </TableRow>
      ))}
    </TableHeader>
  );
}
