import { useMemo } from 'react';

import Dialog from 'components/dialog';
import { initialValues } from 'components/editor/constants';
import variants from 'components/editor/constants/types/editorVariants';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import useContentResolver from 'hooks/useContentResolver';
import { Instance } from 'types';

import InstancePrintDoc from './docs/InstancePrintDoc';

import { LoadingWrapper, PrintViewWrapper } from './styled';

interface InstancePrintProps {
  instance: Instance;
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  clipDuration: string;
  scriptDuration: string;
  totalDuration: string;
}

function InstancePrint({
  instance,
  isDialogOpen,
  onCloseDialog,
  clipDuration,
  scriptDuration,
  totalDuration,
}: Readonly<InstancePrintProps>) {
  const defaultData = initialValues(variants.GENERAL);
  const { data, loading } = useContentResolver(instance?.mContentKey);

  const contentData = data ?? defaultData;

  const renderView = useMemo(() => {
    if (loading)
      return (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      );

    if (contentData)
      return (
        <PrintViewWrapper>
          <PdfViewer>
            <InstancePrintDoc
              instance={instance}
              content={contentData}
              clipDuration={clipDuration}
              scriptDuration={scriptDuration}
              totalDuration={totalDuration}
            />
          </PdfViewer>
        </PrintViewWrapper>
      );

    return null;
  }, [loading, data, clipDuration, scriptDuration, totalDuration, instance]);

  return (
    <Dialog container={null} open={isDialogOpen} onClose={onCloseDialog}>
      {renderView}
    </Dialog>
  );
}

export default InstancePrint;
